import { Link } from "react-router-dom";
import Table from "../../reusable/Table/Table";
import TableHead from "../../reusable/Table/TableHead";
import TableCell from "../../reusable/Table/TableCell";
import DropDown from "../../reusable/DropDown";
import Input from "../../reusable/Input";
import DateInput from "../../reusable/DateInput";
import { message, Modal } from "antd";
import { useState } from "react";
import { getWalletDriverDetails } from "../../../services/WalletServices";
import DateUtils from "../../../services/DateUtils";
import StatusCard from "../../reusable/Table/TripStatus";

export default function DebitedWallet({ data, activeKey, search, debitType, completionMode, setCompletionMode, fromDate, toDate, setSearch, setDebitType, setFromDate, setToDate, page, rowsPerPage, handleChangePage, handleChangeRowsPerPage, rowCount }) {

  const [walletDetailsModal, setWalletDetailsModal] = useState(false);
  const [walletDetails, setWalletDetails] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);

  const getAllRideData = async (id) => {
    try {
      const data = await getWalletDriverDetails(id);
      if (data) {
        const actualData = data.data;
        const filteredData = activeKey === "credited" ? actualData.filter(item => item.debitType === null) : actualData.filter(item => item.debitType !== null);
        setWalletDetails(actualData);
      }
    }
    catch (error) {
      message.error(error.response.data.message || error.response.data.error || error.message)
    }
  };

  const debitTypeOptions = [
    {
      option: "Manual",
      value: 1,
    },
    {
      option: "Trip",
      value: 2,
    },
  ];

  const completionModeOptions = [
    {
      option: "Manual Completed",
      value: 1,
    },
    {
      option: "App Completed",
      value: 2,
    }
  ];

  const columnsForDebited = [
    {
      id: "walletId",
      name: <TableHead>Wallet ID</TableHead>,
      cell: (row) =>
        <TableCell>
          {/* <button className="text-primary-blue" onClick={() => { getAllRideData(row.id); setWalletDetailsModal(true); }}> */}
          {row.walletId}
          {/* </button> */}
        </TableCell>,
    },
    {
      id: "debitType",
      name: <TableHead>Debit Type</TableHead>,
      cell: (row) => <TableCell>{row.debitType === 1 ? "Manual" : "Trip"}</TableCell>,
    },
    {
      id: "driverId",
      name: <TableHead>Driver ID</TableHead>,
      cell: (row) => (
        <TableCell>
          <button className="text-primary-blue" onClick={() => { getAllRideData(row.driverId); setWalletDetailsModal(true); setSelectedRow(row); }}>
            {row.driver?.driverID}
          </button>
        </TableCell>
      ),
    },
    {
      id: "mobileNumber",
      name: <TableHead>Mobile Number</TableHead>,
      cell: (row) => <TableCell>{row.driver?.mobileNo}</TableCell>,
    },
    {
      id: "description",
      name: <TableHead>Description</TableHead>,
      cell: (row) => <TableCell>{row.description || "-"}</TableCell>,
    },
    {
      id: "reason",
      name: <TableHead>Reason</TableHead>,
      cell: (row) => <TableCell>{row.ride?.rideId || "-"}</TableCell>,
    },
    {
      id: "date",
      name: <TableHead>Date</TableHead>,
      cell: (row) => <TableCell>{DateUtils.convertDate(row.date)}</TableCell>,
    },
    {
      id: "creditAmount",
      name: <TableHead>{activeKey === "credited" ? "Credited" : "Debited"} Amount</TableHead>,
      cell: (row) => <TableCell>
        <div className={`text-[#FF0000] font-medium`}>
          -₹ {row.amount}
        </div>
      </TableCell>,
    },
    {
      id: "completionMode",
      name: <TableHead>Completion Mode</TableHead>,
      cell: (row) => <TableCell>
        {
          row.endFrom !== null ? (
            <StatusCard
              status={
                row?.endFrom === 1
                  ? 'manualCompleted'
                  : row?.endFrom === 2
                    ? 'appCompleted'
                    : 'NA'
              }
            />
          ) : (
            'NA'
          )
        }
      </TableCell>,
    },
    {
      id: "balance",
      name: <TableHead>Balance</TableHead>,
      cell: (row) => <TableCell>₹ {row.walletBalance}</TableCell>,
    },

  ];

  const columnsForCredited = [
    {
      id: "walletId",
      name: <TableHead>Wallet ID</TableHead>,
      cell: (row) =>
        <TableCell>
          {/* <button className="text-primary-blue" onClick={() => { getAllRideData(row.id); setWalletDetailsModal(true); }}> */}
          {row.walletId}
          {/* </button> */}
        </TableCell>,
    },
    {
      id: "driverId",
      name: <TableHead>Driver ID</TableHead>,
      cell: (row) => (
        <TableCell>
          <button className="text-primary-blue" onClick={() => { getAllRideData(row.driverId); setWalletDetailsModal(true); setSelectedRow(row); }}>
            {row.driver?.driverID}
          </button>
        </TableCell>
      ),
    },
    {
      id: "mobileNumber",
      name: <TableHead>Mobile Number</TableHead>,
      cell: (row) => <TableCell>{row.driver?.mobileNo}</TableCell>,
    },
    {
      id: "description",
      name: <TableHead>Description</TableHead>,
      cell: (row) => <TableCell>{row.description || "-"}</TableCell>,
    },
    {
      id: "reason",
      name: <TableHead>Transaction Mode</TableHead>,
      cell: (row) => <TableCell>{row.paymentMode?.name || "-"}</TableCell>,
    },
    {
      id: "date",
      name: <TableHead>Date</TableHead>,
      cell: (row) => <TableCell>{DateUtils.convertDate(row.date)}</TableCell>,
    },
    {
      id: "creditAmount",
      name: <TableHead>{activeKey === "credited" ? "Credited" : "Debited"} Amount</TableHead>,
      cell: (row) => <TableCell>
        <div className={`text-[#00904D] font-medium`}>
          +₹ {row.amount?.toLocaleString('hi')}
        </div>
      </TableCell>,
    },
    {
      id: "balance",
      name: <TableHead>Balance</TableHead>,
      cell: (row) => <TableCell>₹ {row.walletBalance?.toLocaleString('hi')}</TableCell>,
    },

  ];

  const columnsForWalletDetail = [
    {
      id: "walletId",
      name: <TableHead>Wallet ID</TableHead>,
      cell: (row) =>
        <TableCell>
          {row.walletId}
        </TableCell>,
    },
    {
      id: "debitType",
      name: <TableHead>Debit Type</TableHead>,
      cell: (row) => <TableCell>{row.debitType === null ? "-" : row.debitType === 1 ? "Manual" : "Trip"}</TableCell>,
    },
    {
      id: "description",
      name: <TableHead>Description</TableHead>,
      cell: (row) => <TableCell>{row.description || "-"}</TableCell>,
    },
    {
      id: "reason",
      name: <TableHead>Reason</TableHead>,
      cell: (row) => <TableCell>{row.ride?.rideId || "-"}</TableCell>,
    },
    {
      id: "date",
      name: <TableHead>Date</TableHead>,
      cell: (row) => <TableCell>{DateUtils.convertDate(row.date)}</TableCell>,
    },
    {
      id: "creditAmount",
      name: <TableHead>Amount</TableHead>,
      cell: (row) => <TableCell>
        <div className={`${row?.debitType === null ? "text-[#00904D]" : "text-[#FF0000]"} font-medium`}>
          {row?.debitType === null ? "+" : "-"}₹ {row.amount?.toLocaleString('hi')}
        </div>
      </TableCell>,
    },
    {
      id: "completionMode",
      name: <TableHead>Completion Mode</TableHead>,
      cell: (row) => <TableCell>
        {
          row.endFrom !== null ? (
            <StatusCard
              status={
                row?.endFrom === 1
                  ? 'manualCompleted'
                  : row?.endFrom === 2
                    ? 'appCompleted'
                    : 'NA'
              }
            />
          ) : (
            'NA'
          )
        }
      </TableCell>,
    },
    {
      id: "balance",
      name: <TableHead>Balance</TableHead>,
      cell: (row) => <TableCell>₹ {row.walletBalance?.toLocaleString('hi')}</TableCell>,
    },

  ];

  return (
    <div className="w-full removeScrollBar pb-5">
      <div className="flex  flex-wrap  lg:gap-8 gap-2 w-full justify-center lg:flex-nowrap  lg:pl-2  lg:pr-10  lg:justify-start items-center mb-5  lg:mb-10  mt-2 lg:mt-6">
        <Input
          label={"Search"}
          type={"text"}
          placeholder={"wallet id/driver id/mobile number"}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        {activeKey === "debited" && (
          <>
            <DropDown
              label={"Debit Type"}
              placeholder={"Select debit type"}
              displayValue={"option"}
              data={debitTypeOptions}
              value={debitType}
              setValue={setDebitType}
              selectAll
            />
            <DropDown
              label={"Completion Mode"}
              placeholder={"Select completion mode"}
              displayValue={"option"}
              data={completionModeOptions}
              value={completionMode}
              setValue={setCompletionMode}
              selectAll
            />
          </>
        )}
        <DateInput
          label={"From date"}
          placeholder={"Select from date"}
          value={fromDate}
          setValue={setFromDate}
          isFilter
        />
        <DateInput
          label={"To date"}
          placeholder={"Select to date"}
          value={toDate}
          setValue={setToDate}
          isFilter
        />

      </div>
      <Table columns={activeKey === "credited" ? columnsForCredited : columnsForDebited} rows={data} rowCount={rowCount} rowsPerPage={rowsPerPage} page={page} handleChangePage={handleChangePage} handleChangeRowsPerPage={handleChangeRowsPerPage} />
      <Modal
        title={
          <div className="pr-12">
            <p>Wallet Detail of {selectedRow?.driver?.driverName} / {selectedRow?.driver?.driverID} / {selectedRow?.driver?.mobileNo}</p>
          </div>
        }
        visible={walletDetailsModal}
        width={"1100px"}
        onCancel={() => {
          setWalletDetailsModal(false);
        }}
        centered
        footer={null}
      >
        <div className="md:mt-12 md:mb-6">
          <Table columns={columnsForWalletDetail} rows={walletDetails} noNeedPagination={true} />
        </div>
      </Modal>
    </div>
  );
}
