import React, { createContext, useState, useEffect } from "react";
import CommonServices from "../services/CommonServices";
import { message } from "antd";

const CommonContext = createContext({});

export const CommonContextProvider = ({ children }) => {
  const [tripTypeData, setTripTypeData] = useState(null);
  const [allVehicle, setAllVehicle] = useState([]);
  const [TripMode, setTripMode] = useState(1);
  const [vehicleTypeData, setVehicleTypeData] = useState(null);
  const [allDriver, setAllDriver] = useState([]);
  const [driverOne, setDriverOne] = useState({});
  const [tripManagementActiveKey, setTripManagementActiveKey] = useState('new-booking');

  const tripTypeDataFetch = async () => {
    try {
      const response = await CommonServices.tripType();
      if (response.data) {

        const tripType = response.data;
        setTripTypeData(tripType);
      }
    } catch (error) {
      message.error(error.message || error.response.data.message || error.response.data.error)
    }
  };

  useEffect(() => {
    tripTypeDataFetch();
    getAllVehicleData();
    getAllDriver();
  }, []);

  useEffect(() => {
    vehicleTypeDataFetch(TripMode);
  }, [TripMode]);

  const vehicleTypeDataFetch = async (tripTypeId) => {
    try {
      const response = await CommonServices.vehicleType(tripTypeId);
      if (response.data) {
        setVehicleTypeData(response.data);
      }
    } catch (error) {
      console.error("Login error:", error);
    }
  };


  const getAllVehicleData = async () => {
    try {
      const response = await CommonServices.getVehicleTypeDropdown();

      if (response.data) {
        const allVehicleData = response.data
        setAllVehicle(allVehicleData);
      }

    } catch (error) {
      console.error("Login error:", error);
    }
  }


  const getAllDriver = async () => {
    try {
      const response = await CommonServices.getAllDrivers();

      if (response.data) {
        const allDriver = response.data;
        setAllDriver(allDriver);
      }
    }
    catch (err) {
      message.error(err.response.data.message || err.response.data.error || err.message)
    }
  }

  const getDriverOne = async (driverId) => {
    try {
      const response = await CommonServices.getDriver(driverId);

      if (response.data) {
        return response.data
      }
    }
    catch (err) {
      message.error(err.response.data.message || err.response.data.error || err.message)
    }
  }

  return (
    <CommonContext.Provider
      value={{
        tripTypeData,
        setTripTypeData,
        TripMode,
        setTripMode,
        vehicleTypeData,
        setVehicleTypeData,
        allVehicle,
        tripManagementActiveKey,
        setTripManagementActiveKey,
        allDriver,
        setAllDriver,
        getDriverOne,
        driverOne,
        setDriverOne
      }}
    >
      {children}
    </CommonContext.Provider>
  );
};

export default CommonContext;
