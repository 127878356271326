import { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Modal, message } from "antd";
import { Breadcrumbs } from "@mui/material";
import { Typography } from "antd";
import addTrip from "../trip-management/assets/add-trip.png";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import DropDown from "../../components/reusable/DropDown";
import Button from "../../components/reusable/Button";
import Input from "../../components/reusable/Input";
import PageLayout from "../../components/layouts/PageLayout";
import DriverTable from "../../components/tables/driver-management/DriverTable";
import MobileNumberInput from "../../components/reusable/MobileNumberInput";
import Upload from "../../components/reusable/Upload";
import edit from "../../assets/edit_pencil.svg";
import profile from "../../assets/profile_avatar.svg";
import DateInput from "../../components/reusable/DateInput";
import { LabelForTabs } from "../../components/reusable/TabNavigation";
import PendingDriverTable from "../../components/tables/driver-management/PendingDriverTable";
import RejectedDriverTable from "../../components/tables/driver-management/RejectedDriverTable";
import { useContext } from "react";
import CommonContext from "../../context-storage/CommonServicesHandler";
import {
  getDistricts,
  getStates,
} from "../../services/DriverManagementServices";
import axios from "axios";
import ExpiredDrivers from "../../components/tables/driver-management/ExpiredDrivers";
import AllDrivers from "../../components/tables/driver-management/AllDrivers";
import Leads from "../../components/tables/driver-management/Leads";
import { Tabs } from "antd";
const { TabPane } = Tabs;

export default function DriverManagement() {
  const location = useLocation().pathname;
  const activeTab = location.split("/")[2];
  const navigate = useNavigate();

  const [routeId, setRouteId] = useState("");
  const [routeValidation, setRouteValidation] = useState("");
  const [driverName, setDriverName] = useState("");
  const [driverNameValidation, setDriverNameValidation] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [mobileNumberValidation, setMobileNumberValidation] = useState("");
  const [vehicleName, setVehicleName] = useState("");
  const [vehicleNameValidation, setVehicleNameValidation] = useState("");
  const [vehicleNumber, setVehicleNumber] = useState("");
  const [vehicleNumberValidation, setVehicleNumberValidation] = useState("");
  const [vehicleType, setVehicleType] = useState("");
  const [vehicleTypeValidation, setVehicleTypeValidation] = useState("");
  const [bloodGroup, setBloodGroup] = useState();
  const [bloodGroupValidation, setBloodGroupValidation] = useState();
  const [driverStatus, setDriverStatus] = useState();
  const [driverStatusValidation, setDriverStatusValidation] = useState();
  const [company, setCompany] = useState();
  const [addDriverModal, setAddDriverModal] = useState(false);
  const [addDriverModal2, setAddDriverModal2] = useState(false);
  const [addDriverModal3, setAddDriverModal3] = useState(false);
  const [isPermanentAddressSame, setIsPermanentAddressSame] = useState(false);
  const [profileImage, setProfileImage] = useState(undefined);
  const [shiftTime, setShiftTime] = useState();
  const profileImageRef = useRef();

  const handleProfileImage = async (e) => {
    const formData = new FormData();
    const files = e.target.files;
    formData.append("images", files[0]);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/driver/uploadDocument`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "x-access-token": localStorage.getItem("CC_ADMIN_TOKEN"),
            "email": localStorage.getItem("CC_ADMIN_EMAIL"),
            "usertype": localStorage.getItem("CC_ADMIN_USERTYPE")
          },
        }
      );

      if (response.data.status && response.data.data) {
        setProfileImage(response.data.data[0]);
      }
    } catch (error) {
      if (error.response && error.response.status === 401 || error.response.status === 403) {
        localStorage.clear();
        window.location.href = "/login";
      } else {
        console.error("Error:", error);
      }
      return error.response && error.response.data;
    }
  };

  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState();

  // Additional state variables for validations
  const [tempStreetAddress, setTempStreetAddress] = useState();
  const [tempStreetAddressValidation, setTempStreetAddressValidation] =
    useState();
  const [tempHouseNumber, setTempHouseNumber] = useState();
  const [tempHouseNumberValidation, setTempHouseNumberValidation] = useState();
  const [tempState, setTempState] = useState();
  const [tempStateValidation, setTempStateValidation] = useState();
  const [tempDistrict, setTempDistrict] = useState();
  const [tempDistrictValidation, setTempDistrictValidation] = useState();
  const [tempPincode, setTempPincode] = useState();
  const [tempPincodeValidation, setTempPincodeValidation] = useState();
  const [permStreetAddress, setPermStreetAddress] = useState();
  const [permStreetAddressValidation, setPermStreetAddressValidation] =
    useState();
  const [permHouseNumber, setPermHouseNumber] = useState();
  const [permHouseNumberValidation, setPermHouseNumberValidation] = useState();
  const [permState, setPermState] = useState();
  const [permStateValidation, setPermStateValidation] = useState();
  const [permDistrict, setPermDistrict] = useState();
  const [permDistrictValidation, setPermDistrictValidation] = useState();
  const [permPincode, setPermPincode] = useState();
  const [permPincodeValidation, setPermPincodeValidation] = useState();
  const [upiId, setUpiId] = useState();
  const [upiIdValidation, setUpiIdValidation] = useState();
  const [aadharNumber, setAadharNumber] = useState();
  const [aadharNumberValidation, setAadharNumberValidation] = useState();
  const [drivingLicense, setDrivingLicense] = useState();
  const [drivingLicenseValidation, setDrivingLicenseValidation] = useState();
  const [licenseBatchExp, setLicenseBatchExp] = useState();
  const [permitExpDate, setPermitExpDate] = useState();
  const [insuranceExpDate, setInsuranceExpDate] = useState();
  const [fcExpiryDate, setFcExpiryDate] = useState();
  const [policeVerificationExpDate, setPoliceVerificationExpDate] = useState();
  const [pollutionVerificationExpDate, setPollutionVerificationExpDate] =
    useState();
  const [taxExpDate, setTaxExpDate] = useState();
  const [aadharCardError, setAadharCardError] = useState("");
  const [drivingLicenseError, setDrivingLicenseError] = useState("");
  const [pollutionCertificateError, setPollutionCertificateError] =
    useState("");
  const [covidVaccineCertificateError, setCovidVaccineCertificateError] =
    useState("");
  const [policeCertificateError, setPoliceCertificateError] = useState("");
  const [fcCopyError, setFcCopyError] = useState("");
  const [rcCopyError, setRcCopyError] = useState("");
  const [aadharCardFile, setAadharCardFile] = useState(null);
  const [licenseBatchExpError, setLicenseBatchExpError] = useState("");
  const [permitExpDateError, setPermitExpDateError] = useState("");
  const [insuranceExpDateError, setInsuranceExpDateError] = useState("");
  const [taxExpDateError, setTaxExpDateError] = useState("");
  const [aadharCardFileName, setAadharCardFileName] = useState("");
  const [drivingLicenseFile, setDrivingLicenseFile] = useState(null);
  const [drivingLicenseFileName, setDrivingLicenseFileName] = useState("");
  const [pollutionCertificateFile, setPollutionCertificateFile] =
    useState(null);
  const [pollutionCertificateFileName, setPollutionCertificateFileName] =
    useState("");
  const [covidVaccineCertificateFile, setCovidVaccineCertificateFile] =
    useState(null);
  const [covidVaccineCertificateFileName, setCovidVaccineCertificateFileName] =
    useState("");
  const [policeCertificateFile, setPoliceCertificateFile] = useState(null);
  const [policeCertificateFileName, setPoliceCertificateFileName] =
    useState("");
  const [fcCopyFile, setFcCopyFile] = useState(null);
  const [fcCopyFileName, setFcCopyFileName] = useState("");
  const [rcCopyFile, setRcCopyFile] = useState(null);
  const [rcCopyFileName, setRcCopyFileName] = useState("");
  const [PermitAndTaxFile, setPermitAndTaxFile] = useState(null);
  const [PermitAndTaxFileUrl, setPermitAndTaxFileUrl] = useState(null);
  const [PermitAndTaxFileName, setPermitAndTaxFileName] = useState("");
  const [insuranceFile, setInsuranceFile] = useState(null);
  const [insuranceFileUrl, setInsuranceFileUrl] = useState(null);
  const [insuranceFileName, setInsuranceFileName] = useState("");

  const [drivingLicenseFrontFile, setDrivingLicenseFrontFile] = useState(null);
  const [drivingLicenseBackFile, setDrivingLicenseBackFile] = useState(null);
  const [rcCopyFrontFile, setRcCopyFrontFile] = useState(null);
  const [rcCopyBackFile, setRcCopyBackFile] = useState(null);
  const [aadharCardFrontFile, setAadharCardFrontFile] = useState(null);
  const [aadharCardBackFile, setAadharCardBackFile] = useState(null);

  const [drivingLicenseFrontFileUrls, setDrivingLicenseFrontFileUrls] =
    useState("");
  const [drivingLicenseBackFileUrls, setDrivingLicenseBackFileUrls] =
    useState("");
  const [rcCopyFrontFileUrls, setRcCopyFrontFileUrls] = useState("");
  const [rcCopyBackFileUrls, setRcCopyBackFileUrls] = useState("");
  const [aadharCardFrontFileUrls, setAadharCardFrontFileUrls] = useState("");
  const [aadharCardBackFileUrls, setAadharCardBackFileUrls] = useState("");

  const [drivingLicenseFrontError, setDrivingLicenseFrontError] = useState("");
  const [drivingLicenseBackError, setDrivingLicenseBackError] = useState("");
  const [drivingLicenseFrontFileName, setDrivingLicenseFrontFileName] =
    useState("");
  const [drivingLicenseBackFileName, setDrivingLicenseBackFileName] =
    useState("");
  const [rcCopyFrontError, setRcCopyFrontError] = useState("");
  const [rcCopyBackError, setRcCopyBackError] = useState("");
  const [rcCopyFrontFileName, setRcCopyFrontFileName] = useState("");
  const [rcCopyBackFileName, setRcCopyBackFileName] = useState("");
  const [aadharCardFrontError, setAadharCardFrontError] = useState("");
  const [aadharCardBackError, setAadharCardBackError] = useState("");
  const [aadharCardFrontFileName, setAadharCardFrontFileName] = useState("");
  const [aadharCardBackFileName, setAadharCardBackFileName] = useState("");

  const [aadharCardFileUrls, setAadharCardFileUrls] = useState("");
  const [drivingLicenseFileUrls, setDrivingLicenseFileUrls] = useState("");
  const [pollutionCertificateFileUrls, setPollutionCertificateFileUrls] =
    useState("");
  const [covidVaccineCertificateFileUrls, setCovidVaccineCertificateFileUrls] =
    useState("");
  const [policeCertificateFileUrls, setPoliceCertificateFileUrls] =
    useState("");
  const [fcCopyFileUrls, setFcCopyFileUrls] = useState("");
  const [rcCopyFileUrls, setRcCopyFileUrls] = useState("");

  const handleSubmit = async () => {
    const updateMobileNumberWithoutSpaces = mobileNumber.replace(/\s/g, "");

    const data = {
      driverName: driverName,
      mobileNo: updateMobileNumberWithoutSpaces,
      vehicleName: vehicleName,
      vehicleNo: vehicleNumber ? vehicleNumber.toUpperCase() : "",
      vehicleTypeID: vehicleType?.id,

      tempStreetAddress: tempStreetAddress,
      tempHouseNo: tempHouseNumber,
      selectedTemState: tempState?.stateName,
      selectedTemDistrict: tempDistrict?.districtName,
      tempPincode: tempPincode,
      perStreetAddress: isPermanentAddressSame
        ? tempStreetAddress
        : permStreetAddress,
      perHouseNo: isPermanentAddressSame ? tempHouseNumber : permHouseNumber,
      selectedPerState: isPermanentAddressSame
        ? tempState?.stateName
        : permState?.stateName,
      selectedPerDistrict: isPermanentAddressSame
        ? tempDistrict?.districtName
        : permDistrict?.districtName,
      perPincode: isPermanentAddressSame ? tempPincode : permPincode,
      upiId: upiId,
      aadharNumber: aadharNumber,
      adhaarUrls: aadharCardFileUrls,
      drivingLicenseNumber: drivingLicense,
      pollutionExpiryDate: pollutionVerificationExpDate,

      permitTaxExpiryDate: permitExpDate,
      fcExpiryDate: fcExpiryDate,
      insuranceExpiryDate: insuranceExpDate,
      taxExpDate: taxExpDate,
      policeVerificationExpiryDate: policeVerificationExpDate,
      pollutionCertificate: pollutionCertificateFileUrls,
      cvUrls: covidVaccineCertificateFileUrls,
      policeVerificationCertificate: policeCertificateFileUrls,
      fcCopy: fcCopyFileUrls,
      rcUrls: rcCopyFileUrls,
      selectedImage: profileImage,
      isAddressPermanent: isPermanentAddressSame,
      rcCopyFront: rcCopyFrontFileUrls,
      rcCopyBack: rcCopyBackFileUrls,
      aadharCardFront: aadharCardFrontFileUrls,
      aadharCardBack: aadharCardBackFileUrls,
      permitTaxCertificate: PermitAndTaxFileUrl,
      insuranceCertificate: insuranceFileUrl,
      registrationCertificateBackUrl: rcCopyBackFileUrls,
      registrationCertificateFrontUrl: rcCopyFrontFileUrls,
      drivingLicenseBack: drivingLicenseBackFileUrls,
      drivingLicenseFront: drivingLicenseFrontFileUrls,
      drivingLicenseExpiryDate: licenseBatchExp,
      createdBy: 1,
    };
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/driver/create`,
        data, {
        headers: {
          "x-access-token": localStorage.getItem("CC_ADMIN_TOKEN"),
          "email": localStorage.getItem("CC_ADMIN_EMAIL"),
          "usertype": localStorage.getItem("CC_ADMIN_USERTYPE")
        }
      }
      );
      if (response.data.status) {
        // Handle successful response
        setAddDriverModal(false);
        setRouteId("");
        setRouteValidation("");
        setDriverName("");
        setDriverNameValidation("");
        setMobileNumber("");
        setMobileNumberValidation("");
        setVehicleName("");
        setVehicleNumber("");
        setVehicleType("");
        setAddDriverModal(false);
        setActiveKey("leads");
        message.success(response.data.message);
        setActiveKey("");
        setTimeout(() => {
          setActiveKey("leads");
        }, 1);
      } else {
        // Handle error response
        message.error(response.data.message);
        console.error(response.data.message);
      }
    } catch (error) {
      if (error.response && error.response.status === 401 || error.response.status === 403) {
        localStorage.clear();
        window.location.href = "/login";
      } else {
        console.error("Error:", error);
        message.error(error.response.data.message || error.message)
      }
      return error.response && error.response.data;
    }
  };

  useEffect(() => {
    if (aadharCardFileName) {
      setAadharCardError("");
    }
    if (drivingLicenseFileName) {
      setDrivingLicenseError("");
    }
    if (pollutionCertificateFileName) {
      setPollutionCertificateError("");
    }
    if (covidVaccineCertificateFileName) {
      setCovidVaccineCertificateError("");
    }
    if (policeCertificateFileName) {
      setPoliceCertificateError("");
    }
    if (fcCopyFileName) {
      setFcCopyError("");
    }
    if (rcCopyFileName) {
      setRcCopyError("");
    }
  }, [
    aadharCardFileName,
    drivingLicenseFileName,
    pollutionCertificateFileName,
    covidVaccineCertificateFileName,
    policeCertificateFileName,
    fcCopyFileName,
    rcCopyFileName,
  ]);
  //context
  const { allVehicle } = useContext(CommonContext);

  //other states
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);

  async function getState() {
    try {
      const response = await getStates();
      if (response.data) {
        setStates(response.data);
      }
    } catch (err) {
      message.error(
        err.response.data.message || err.response.data.error || err.message
      );
    }
  }

  async function getAllDistrict(item) {
    try {
      const response = await getDistricts(item);
      if (response.data) {
        setDistricts(response.data);
      }
    } catch (err) {
      message.error(
        err.response.data.message || err.response.data.error || err.message
      );
    }
  }

  useEffect(() => {
    getState();
  }, []);

  useEffect(() => {
    if (tempState) {
      getAllDistrict(tempState.id);
    }
  }, [tempState]);

  const fetchData = () => {
    try {
      const fetchData = async () => {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/driver/getLatestDriver`, {
          headers: {
            "x-access-token": localStorage.getItem("CC_ADMIN_TOKEN"),
            "email": localStorage.getItem("CC_ADMIN_EMAIL"),
            "usertype": localStorage.getItem("CC_ADMIN_USERTYPE")
          }
        }
        );

        if (response.data.status) {
          setRouteId(response.data.data);
        } else {
          console.error("Error fetching data:", response.data.message);
        }
      };
      fetchData();
    } catch (error) {
      if (error.response && error.response.status === 401 || error.response.status === 403) {
        localStorage.clear();
        window.location.href = "/login";
      } else {
        console.error("Error:", error);
      }
      return error.response && error.response.data;
    }
  };

  useEffect(() => {
    fetchData();
  }, [addDriverModal]);

  const AddDriverModalContent = () => {
    return (
      <>
        <div className=" w-full  mt-6 mb-2  relative ">
          <button
            onClick={() => {
              setAddDriverModal(false);
              setRouteId("");
              setRouteValidation("");
              setDriverName("");
              setDriverNameValidation("");
              setMobileNumber("");
              setMobileNumberValidation("");
              setVehicleName("");
              setVehicleNumber("");
              setVehicleType("");
            }}
            className="flex items-center absolute -right-3 -top-16 justify-center w-8 h-8 mb-4 mr-4  bg-gray-100  hover:bg-gray-200 rounded-full"
          >
            <div className="mb-1 text-xl  ml-[1px] text-center text-[#273775] h- w- ">
              x
            </div>
          </button>
          <div className=" w-full text-start text-lg mt-1 font-semibold text-gray-800 ">
            Add Driver
          </div>
          <div className=" w-full flex flex-col  gap-3 lg:gap-3 py-10">
            {/* Profile Image */}
            <div className="flex flex-col items-center justify-center w-full">
              <div className="relative -top-10">
                {profileImage === undefined ? (
                  <img
                    width={100}
                    src={profile}
                    alt="profile"
                    className="w-32 h-32"
                  />
                ) : (
                  <img
                    src={profileImage}
                    alt="profile"
                    className="rounded-full w-32 h-32 object-cover border-2 shadow-lg"
                  />
                )}
                <button
                  className="absolute right-1 top-3"
                  onClick={() => profileImageRef.current.click()}
                >
                  <img src={edit} alt="" />
                  <input
                    type="file"
                    className="hidden"
                    ref={profileImageRef}
                    onChange={handleProfileImage}
                    accept="image/*"
                  />
                </button>
              </div>
            </div>
            <p className="text-base font-semibold -mt-14">Personal Details</p>
            <div className=" w-full flex flex-wrap lg:flex-nowrap justify-center items-center gap-3 lg:gap-10">
              <Input
                label={"Driver ID"}
                placeholder={"Enter route id"}
                value={routeId}
                error={routeValidation !== "" ? routeValidation : ""}
                onChange={(e) => setRouteId(e.target.value)}
                disabled={true}
              />
              <Input
                label={"Enter Driver Name"}
                placeholder={"Enter driver name"}
                value={driverName}
                error={driverNameValidation !== "" ? driverNameValidation : ""}
                onChange={(e) => setDriverName(e.target.value)}
                mandate={true}
              />
              <MobileNumberInput
                label={"Mobile Number"}
                placeholder={"Enter mobile number"}
                value={mobileNumber}
                error={
                  mobileNumberValidation !== "" ? mobileNumberValidation : ""
                }
                setValue={setMobileNumber}
                mandate={true}
              />
            </div>
            <p className="text-base font-semibold pt-3">Vehicle Details</p>

            <div className=" w-full flex flex-wrap lg:flex-nowrap justify-center items-center gap-3 lg:gap-10 ">
              <Input
                label={"Vehicle Name"}
                placeholder={"Enter vehicle name"}
                value={vehicleName}
                error={
                  vehicleNameValidation !== "" ? vehicleNameValidation : ""
                }
                onChange={(e) => setVehicleName(e.target.value)}
                mandate={true}
              />
              <Input
                label={"Vehicle Number"}
                upppercase={true}
                placeholder={"Enter vehicle number"}
                value={vehicleNumber}
                error={
                  vehicleNumberValidation !== "" ? vehicleNumberValidation : ""
                }
                onChange={(e) => setVehicleNumber(e.target.value)}
                mandate={true}
              />
              <DropDown
                label={"Vehicle Type"}
                placeholder={"Select vehicle type"}
                displayValue={"name"}
                data={allVehicle}
                value={vehicleType}
                error={
                  vehicleTypeValidation !== "" ? vehicleTypeValidation : ""
                }
                setValue={setVehicleType}
                mandate={true}
              />
            </div>
          </div>
          <div className=" w-full flex  justify-center items-center">
            <Button
              text={"Submit"}
              width={"170px"}
              onClick={() => {
                // Validate all fields before moving to the next modal
                let isValid = true;
                if (routeId === "") {
                  setRouteValidation("Please enter route id");
                  isValid = false;
                } else {
                  setRouteValidation("");
                }
                if (driverName === "") {
                  setDriverNameValidation("Please enter driver name");
                  isValid = false;
                } else {
                  setDriverNameValidation("");
                }
                if (mobileNumber === "") {
                  setMobileNumberValidation("Please enter mobile number");
                  isValid = false;
                } else {
                  setMobileNumberValidation("");
                }
                if (vehicleName === "") {
                  setVehicleNameValidation("Please enter vehicle name");
                  isValid = false;
                } else {
                  setVehicleNameValidation("");
                }
                if (vehicleNumber === "") {
                  setVehicleNumberValidation("Please enter vehicle number");
                  isValid = false;
                } else {
                  setVehicleNumberValidation("");
                }
                if (vehicleType === "") {
                  setVehicleTypeValidation("Please select vehicle type");
                  isValid = false;
                } else {
                  setVehicleTypeValidation("");
                }
                if (isValid) {
                  handleSubmit();
                }
              }}
            />
          </div>
        </div>
      </>
    );
  };

  const AddDriverModalContent2 = () => {
    return (
      <>
        <div className=" w-full  mt-6 mb-10  ">
          <div className=" w-full text-start text-lg mt-1 font-semibold text-gray-800 ">
            Create Driver
          </div>
          <div className=" w-full flex flex-col  gap-3 lg:gap-6 py-10">
            <p className="text-base font-medium underline">
              Temporary Address{" "}
            </p>
            <div className=" w-full flex flex-wrap lg:flex-nowrap justify-center items-center gap-3 lg:gap-10">
              <Input
                label={"Street Address"}
                placeholder={"Enter street address"}
                value={tempStreetAddress}
                error={
                  tempStreetAddressValidation !== ""
                    ? tempStreetAddressValidation
                    : ""
                }
                onChange={(e) => setTempStreetAddress(e.target.value)}
              />
              <Input
                label={"House Number"}
                placeholder={"Enter house number"}
                value={tempHouseNumber}
                error={
                  tempHouseNumberValidation !== ""
                    ? tempHouseNumberValidation
                    : ""
                }
                onChange={(e) => setTempHouseNumber(e.target.value)}
              />
              <DropDown
                label={"State"}
                placeholder={"Select state"}
                displayValue={"stateName"}
                data={states}
                value={tempState}
                error={tempStateValidation !== "" ? tempStateValidation : ""}
                setValue={setTempState}
              />
            </div>
            <div className=" w-full flex flex-wrap lg:flex-nowrap justify-start items-center gap-3 lg:gap-10 ">
              <DropDown
                label={"District"}
                placeholder={"Select district"}
                displayValue={"districtName"}
                data={districts}
                value={tempDistrict}
                error={
                  tempDistrictValidation !== "" ? tempDistrictValidation : ""
                }
                setValue={setTempDistrict}
              />

              <Input
                label={"Pincode"}
                placeholder={"Enter pincode"}
                value={tempPincode}
                error={
                  tempPincodeValidation !== "" ? tempPincodeValidation : ""
                }
                onChange={(e) => setTempPincode(e.target.value)}
              />
              <div className=" w-full "></div>
            </div>
          </div>
          <div className=" w-full flex flex-col  gap-3 lg:gap-6 pb-10">
            <div>
              <p className="text-base font-medium underline">
                Permanent Address{" "}
              </p>
              <div className="flex items-center pt-4">
                <input
                  type="checkbox"
                  id="permanentAddressSame"
                  checked={isPermanentAddressSame}
                  onChange={(e) => setIsPermanentAddressSame(e.target.checked)}
                />
                <label
                  htmlFor="permanentAddressSame"
                  className="ml-2 font-medium text-base"
                >
                  Is Permanent Address same as Temporary Address?
                </label>
              </div>
            </div>
            {!isPermanentAddressSame && (
              <>
                <div className=" w-full flex flex-wrap lg:flex-nowrap justify-center items-center gap-3 lg:gap-10">
                  <Input
                    label={"Street Address"}
                    placeholder={"Enter street address"}
                    value={permStreetAddress}
                    error={
                      permStreetAddressValidation !== ""
                        ? permStreetAddressValidation
                        : ""
                    }
                    onChange={(e) => setPermStreetAddress(e.target.value)}
                  />
                  <Input
                    label={"House Number"}
                    placeholder={"Enter house number"}
                    value={permHouseNumber}
                    error={
                      permHouseNumberValidation !== ""
                        ? permHouseNumberValidation
                        : ""
                    }
                    onChange={(e) => setPermHouseNumber(e.target.value)}
                  />
                  <DropDown
                    label={"State"}
                    placeholder={"Select state"}
                    displayValue={"stateName"}
                    data={states}
                    value={permState}
                    error={
                      permStateValidation !== "" ? permStateValidation : ""
                    }
                    setValue={setPermState}
                  />
                </div>
                <div className=" w-full flex flex-wrap lg:flex-nowrap justify-start items-center gap-3 lg:gap-10 ">
                  <DropDown
                    label={"District"}
                    placeholder={"Select district"}
                    displayValue={"districtName"}
                    data={districts}
                    value={permDistrict}
                    error={
                      permDistrictValidation !== ""
                        ? permDistrictValidation
                        : ""
                    }
                    setValue={setPermDistrict}
                  />
                  <Input
                    label={"Pincode"}
                    placeholder={"Enter pincode"}
                    value={permPincode}
                    error={
                      permPincodeValidation !== "" ? permPincodeValidation : ""
                    }
                    onChange={(e) => setPermPincode(e.target.value)}
                  />
                  <div className=" w-full "></div>
                </div>
              </>
            )}
          </div>

          <div className=" w-full flex gap-6 justify-center items-center">
            <Button
              text={"Back"}
              width={"140px"}
              onClick={() => {
                setAddDriverModal2(false);
                setAddDriverModal(true);
              }}
            />
            <Button
              text={"Next"}
              width={"140px"}
              onClick={() => {
                // Validate all fields before moving to the next modal
                let isValid = true;

                if (isValid) {
                  setAddDriverModal2(false);
                  setAddDriverModal3(true);
                }
              }}
            />
          </div>
        </div>
      </>
    );
  };

  const AddDriverModalContent3 = () => {
    return (
      <>
        <div className=" w-full  mt-6 mb-10  ">
          <div className=" w-full text-start text-lg mt-1 font-semibold text-gray-800 ">
            Create Driver
          </div>
          <div className=" w-full px-1 flex justify-center items-center ">
            <div className="w-full flex flex-col gap-3 lg:gap-6 rounded-md py-6">
              <p className="text-base font-medium underline">
                Document Details
              </p>
              <div className="w-full flex flex-wrap lg:flex-nowrap justify-between items-center gap-3">
                <Input
                  label="UPI ID"
                  placeholder="Enter UPI ID"
                  value={upiId}
                  error={upiIdValidation !== "" ? upiIdValidation : ""}
                  onChange={(e) => setUpiId(e.target.value)}
                />
                <Input
                  label="Aadhar Number"
                  placeholder="Enter aadhar number"
                  value={aadharNumber}
                  error={
                    aadharNumberValidation !== "" ? aadharNumberValidation : ""
                  }
                  onChange={(e) => setAadharNumber(e.target.value)}
                />
                <Input
                  label="Driving License Number"
                  placeholder="Enter driving license number"
                  value={drivingLicense}
                  error={
                    drivingLicenseValidation !== ""
                      ? drivingLicenseValidation
                      : ""
                  }
                  onChange={(e) => setDrivingLicense(e.target.value)}
                />
              </div>
              <div className="w-full flex flex-wrap lg:flex-nowrap justify-between items-center gap-3">
                <DateInput
                  label="License Batch Expiry date"
                  placeholder="Select license batch exp"
                  value={licenseBatchExp}
                  setValue={setLicenseBatchExp}
                  error={
                    licenseBatchExpError !== "" ? licenseBatchExpError : ""
                  }
                />
                <DateInput
                  label="Insurance Expiry Date"
                  placeholder="Select insurance exp"
                  value={insuranceExpDate}
                  setValue={setInsuranceExpDate}
                  error={
                    insuranceExpDateError !== "" ? insuranceExpDateError : ""
                  }
                />{" "}
                <DateInput
                  label="FC  Expiry Date"
                  placeholder="Select FC exp"
                  value={fcExpiryDate}
                  setValue={setFcExpiryDate}
                  error={
                    insuranceExpDateError !== "" ? insuranceExpDateError : ""
                  }
                />
              </div>
              <div className="w-full flex flex-wrap lg:flex-nowrap justify-between items-center gap-3">
                <DateInput
                  label="Permit & Tax Expiry Date"
                  placeholder="Select permit exp"
                  value={permitExpDate}
                  setValue={setPermitExpDate}
                  error={permitExpDateError !== "" ? permitExpDateError : ""}
                />
                <DateInput
                  label="Police Verification Expiry Date"
                  placeholder="Select police verification exp"
                  value={policeVerificationExpDate}
                  setValue={setPoliceVerificationExpDate}
                />{" "}
                <DateInput
                  label="Pollution Certificate Expiry Date"
                  placeholder="Select pollution exp"
                  value={pollutionVerificationExpDate}
                  setValue={setPollutionVerificationExpDate}
                />
              </div>
              <p className="text-base font-medium underline">Proof Upload</p>
              <div className="w-full flex flex-wrap lg:flex-nowrap justify-between items-center gap-3">
                <div className="w-[300px]">
                  <Upload
                    label="Driving License (Front)"
                    selectedFile={drivingLicenseFrontFile}
                    setSelectedFile={setDrivingLicenseFrontFile}
                    inputValue={drivingLicenseFrontFileName}
                    setInputValue={setDrivingLicenseFrontFileName}
                    setDrivingLicenseFrontFileUrls={
                      setDrivingLicenseFrontFileUrls
                    }
                    isError={drivingLicenseFrontError !== ""}
                    errorMessage={drivingLicenseFrontError}
                  />
                </div>
                <div className="w-[300px]">
                  <Upload
                    label="Driving License (Back)"
                    selectedFile={drivingLicenseBackFile}
                    setSelectedFile={setDrivingLicenseBackFile}
                    inputValue={drivingLicenseBackFileName}
                    setInputValue={setDrivingLicenseBackFileName}
                    setDrivingLicenseBackFileUrls={
                      setDrivingLicenseBackFileUrls
                    }
                    isError={drivingLicenseBackError !== ""}
                    errorMessage={drivingLicenseBackError}
                  />
                </div>
                <div className="w-[300px]">
                  <Upload
                    label="Pollution Certificate"
                    selectedFile={pollutionCertificateFile}
                    setSelectedFile={setPollutionCertificateFile}
                    setPollutionCertificateFileUrls={
                      setPollutionCertificateFileUrls
                    }
                    inputValue={pollutionCertificateFileName}
                    setInputValue={setPollutionCertificateFileName}
                    isError={pollutionCertificateError !== ""}
                    errorMessage={pollutionCertificateError}
                  />
                </div>
              </div>
              <div className="w-full flex flex-wrap lg:flex-nowrap justify-between items-center gap-3">
                <div className="w-[300px]">
                  <Upload
                    label="RC Copy (Front)"
                    selectedFile={rcCopyFrontFile}
                    setSelectedFile={setRcCopyFrontFile}
                    inputValue={rcCopyFrontFileName}
                    setInputValue={setRcCopyFrontFileName}
                    setRcCopyFrontFileUrls={setRcCopyFrontFileUrls}
                    isError={rcCopyFrontError !== ""}
                    errorMessage={rcCopyFrontError}
                  />
                </div>

                <div className="w-[300px]">
                  <Upload
                    label="RC Copy (Back)"
                    selectedFile={rcCopyBackFile}
                    setSelectedFile={setRcCopyBackFile}
                    inputValue={rcCopyBackFileName}
                    setInputValue={setRcCopyBackFileName}
                    setRcCopyBackFileUrls={setRcCopyBackFileUrls}
                    isError={rcCopyBackError !== ""}
                    errorMessage={rcCopyBackError}
                  />
                </div>
                <div className="w-[300px]">
                  <Upload
                    label="Police Certificate"
                    selectedFile={policeCertificateFile}
                    setSelectedFile={setPoliceCertificateFile}
                    inputValue={policeCertificateFileName}
                    setInputValue={setPoliceCertificateFileName}
                    setPoliceCertificateFileUrls={setPoliceCertificateFileUrls}
                    isError={policeCertificateError !== ""}
                    errorMessage={policeCertificateError}
                  />
                </div>
              </div>
              <div className="w-full flex flex-wrap lg:flex-nowrap justify-between items-center gap-3">
                <div className="w-[300px]">
                  <Upload
                    label="Aadhar Card (Front)"
                    selectedFile={aadharCardFrontFile}
                    setSelectedFile={setAadharCardFrontFile}
                    inputValue={aadharCardFrontFileName}
                    setInputValue={setAadharCardFrontFileName}
                    setAadharCardFrontFileUrls={setAadharCardFrontFileUrls}
                    isError={aadharCardFrontError !== ""}
                    errorMessage={aadharCardFrontError}
                  />
                </div>
                <div className="w-[300px]">
                  <Upload
                    label="Aadhar Card (Back)"
                    selectedFile={aadharCardBackFile}
                    setSelectedFile={setAadharCardBackFile}
                    inputValue={aadharCardBackFileName}
                    setInputValue={setAadharCardBackFileName}
                    setAadharCardBackFileUrls={setAadharCardBackFileUrls}
                    isError={aadharCardBackError !== ""}
                    errorMessage={aadharCardBackError}
                  />
                </div>

                <div className="w-[300px]">
                  <Upload
                    label="FC Copy"
                    selectedFile={fcCopyFile}
                    setFcCopyFileUrls={setFcCopyFileUrls}
                    setSelectedFile={setFcCopyFile}
                    inputValue={fcCopyFileName}
                    setInputValue={setFcCopyFileName}
                    isError={fcCopyError !== ""}
                    errorMessage={fcCopyError}
                  />
                </div>
              </div>
              <div className="w-full flex flex-wrap lg:flex-nowrap justify-between items-center gap-3">
                <div className="w-[300px]">
                  <Upload
                    label="Insurance Certificate"
                    selectedFile={insuranceFile}
                    setInsuranceFileUrl={setInsuranceFileUrl}
                    setSelectedFile={setInsuranceFile}
                    inputValue={insuranceFileName}
                    setInputValue={setInsuranceFileName}
                  />
                </div>
                <div className="w-[300px]">
                  <Upload
                    label="Permit & Tax Certificate"
                    selectedFile={PermitAndTaxFile}
                    setPermitAndTaxFileUrl={setPermitAndTaxFileUrl}
                    setSelectedFile={setPermitAndTaxFile}
                    inputValue={PermitAndTaxFileName}
                    setInputValue={setPermitAndTaxFileName}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="w-full flex gap-6 justify-center items-center pt-8">
            <Button
              text="Back"
              width="170px"
              onClick={() => {
                setAddDriverModal3(false);
                setAddDriverModal2(true);
              }}
            />
            <Button
              text={"Submit"}
              width={"170px"}
              onClick={() => {
                // Validate all fields before submitting
                let isValid = true;

                if (isValid) {
                  handleSubmit();
                }
              }}
            />
          </div>
        </div>
      </>
    );
  };
  const [activeKey, setActiveKey] = useState(activeTab);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [driversCounts, setDriversCounts] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/driver/getDriversCounts`, {
          headers: {
            "x-access-token": localStorage.getItem("CC_ADMIN_TOKEN"),
            "email": localStorage.getItem("CC_ADMIN_EMAIL"),
            "usertype": localStorage.getItem("CC_ADMIN_USERTYPE")
          }
        }
        );
        const data = await response.json();

        if (data.data) {
          setDriversCounts(data.data);
        } else {
          setDriversCounts([]);
        }
      } catch (error) {
        if (error.response && error.response.status === 401 || error.response.status === 403) {
          localStorage.clear();
          window.location.href = "/login";
        } else {
          console.error("Error:", error);
        }
        return error.response && error.response.data;
      }
    };

    fetchData();
    setPage(0);
  }, [activeKey]);

  const tabContents = [
    ,
    {
      key: "drivers",
      label: (
        <LabelForTabs isActive={activeKey === "drivers"}>
          Drivers ({driversCounts[1]?.count})
        </LabelForTabs>
      ),
      children: (
        <PendingDriverTable
          activeKey={activeKey}
          rowsPerPage={rowsPerPage}
          rowCount={driversCounts[1]?.count}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          setPage={setPage}
        />
      ),
    },
    {
      key: "pending",
      label: (
        <LabelForTabs isActive={activeKey === "pending"}>
          Pending Request ({driversCounts[0]?.count})
        </LabelForTabs>
      ),
      children: (
        <DriverTable
          activeKey={activeKey}
          rowsPerPage={rowsPerPage}
          rowCount={driversCounts[0]?.count}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          setPage={setPage}
        />
      ),
    },
    {
      key: "rejected",
      label: (
        <LabelForTabs isActive={activeKey === "rejected"}>
          Rejected Request ({driversCounts[2]?.count})
        </LabelForTabs>
      ),
      children: (
        <RejectedDriverTable
          activeKey={activeKey}
          rowsPerPage={rowsPerPage}
          rowCount={driversCounts[2]?.count}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      ),
    },
    {
      key: "expired",
      label: (
        <LabelForTabs isActive={activeKey === "expired"}>
          Expired Drivers ({driversCounts[4]?.count})
        </LabelForTabs>
      ),
      children: (
        <ExpiredDrivers
          activeKey={activeKey}
          rowsPerPage={rowsPerPage}
          rowCount={driversCounts[4]?.count}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      ),
    },
    {
      key: "leads",
      label: (
        <LabelForTabs isActive={activeKey === "leads"}>
          Leads ({driversCounts[3]?.count})
        </LabelForTabs>
      ),
      children: (
        <Leads
          activeKey={activeKey}
          rowsPerPage={rowsPerPage}
          rowCount={driversCounts[3]?.count}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      ),
    },
  ];

  const onChange = (newActiveKey) => {
    setActiveKey(newActiveKey);
    navigate(`/driver-management/${newActiveKey}`);
  };

  return (
    <>
      <PageLayout
        title={"Driver Management"}
        description={"Manage the driver details here"}
        buttonTitle={"Add Driver"}
        buttonWidth={"fit-content"}
        paddingRight={true}
        // BreadcrumbsUrl={BreadcrumbsUrl}
        setAddTripModal={setAddDriverModal}
      >
        <div className=" w-full  flex md:mt-7">

          <Tabs
            style={{
              width: "100%",
              height: "100%",
              overflowY: "scroll",
            }}
            className=""
            activeKey={activeKey}
            defaultActiveKey="drivers"
            onChange={onChange}
          >
            {tabContents.map((tab) => (
              <TabPane
               
                tab={tab.label}
                key={tab.key}
              >
                {tab.children}
              </TabPane>
            ))}
          </Tabs>
        </div>
      </PageLayout>
      {/* first modal */}
      <Modal
        title={
          <>
            <img src={addTrip} width={40} alt="" />
          </>
        }
        visible={addDriverModal}
        width={"1000px"}
        closeIcon={null}
        afterClose={() => {
          setAddDriverModal(false);
          setRouteId("");
          setRouteValidation("");
          setDriverName("");
          setDriverNameValidation("");
          setMobileNumber("");
          setMobileNumberValidation("");
          setVehicleName("");
          setVehicleNumber("");
          setVehicleType("");
          setVehicleNameValidation("");
          setVehicleNumberValidation("");
          setVehicleTypeValidation("");
          setProfileImage(undefined);
        }}
        onCancel={() => {
          setAddDriverModal(false);
          setRouteId("");
          setRouteValidation("");
          setDriverName("");
          setDriverNameValidation("");
          setMobileNumber("");
          setMobileNumberValidation("");
          setVehicleName("");
          setVehicleNumber("");
          setVehicleType("");
          setVehicleNameValidation("");
          setVehicleNumberValidation("");
          setVehicleTypeValidation("");
          setProfileImage(undefined);
        }}
        centered
        footer={null}
      >
        {AddDriverModalContent()}
      </Modal>
      {/* second modal */}
      <Modal
        title={
          <>
            <img src={addTrip} width={40} alt="" />
          </>
        }
        visible={addDriverModal2}
        width={"1000px"}
        onCancel={() => setAddDriverModal2(false)}
        footer={null}
      >
        {AddDriverModalContent2()}
      </Modal>
      {/* third modal */}
      <Modal
        title={
          <>
            <img src={addTrip} width={40} alt="" />
          </>
        }
        visible={addDriverModal3}
        width={"1000px"}
        onCancel={() => setAddDriverModal3(false)}
        footer={null}
      >
        {AddDriverModalContent3()}
      </Modal>
    </>
  );
}
