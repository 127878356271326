import { useState } from "react";
import { Link } from "react-router-dom";
import Table from "../../reusable/Table/Table";
import TableHead from "../../reusable/Table/TableHead";
import TableCell from "../../reusable/Table/TableCell";
import DropDown from "../../reusable/DropDown";
import Input from "../../reusable/Input";
import { Modal, Popover, message } from "antd";
import Button from "../../reusable/Button";
import { updateCompleteRedeemStatus, updateRedeemStatus, updateRejectRedeemStatus } from "../../../services/RedeemManagementServices";
import RedeemModal from "./Modal/RedeemModal";
import DateUtils from "../../../services/DateUtils";

export default function CustomerReferralTable({ totalCount, getAllRedeemData, allRedeemData, rowsPerPage, page, activeKey, handleChangePage, handleChangeRowsPerPage, rowCount }) {

  const [search, setSearch] = useState('')
  const [confirmationModal, setConfirmationModal] = useState({
    state: false,
    action: ""
  })
  const [selectedRow, setSelectedRow] = useState({})
  const [openPopoverId, setOpenPopoverId] = useState(null);
  const [isRedeemModal, setIsRedeemModal] = useState({
    state: false,
    action: ""
  });

  const [modalInputs, setModalInputs] = useState({
    paidDate: '',
    redeemAmount: selectedRow?.amount,
    redemptionType: '',
    transactionId: '',
    reason: ''
  })

  const [modalErrors, setModalErrors] = useState({
    paidDateError: '',
    redemptionTypeError: '',
    transactionIdError: '',
    reasonError: ''
  })

  // Function to get the selected row
  function handlePopoverClick(row) {
    if (openPopoverId === row.redemptionID) {
      setOpenPopoverId(null);
    } else {
      setSelectedRow(row);
      setOpenPopoverId(row.redemptionID);
    }
  }

  async function makeActions(selectedRow, action) {
    if (action === 'revert') {
      try {
        const response = await updateRedeemStatus({ id: selectedRow?.id, redeemStatus: 0 });
        message.success('Redemption Reverted Successfully!')
        getAllRedeemData(activeKey === 'pending-redemption' ? 0 : activeKey === 'completed-redemption' ? 1 : 2, page, rowsPerPage, search)
      }
      catch (err) {
        message.error(err.response.data.message || err.message);
      }
    }
    setIsRedeemModal(true);
  }
  const actionsContent = (
    activeKey === 'pending-redemption' ? (
      <div className=" flex flex-col">
        <div className=" flex items-center hover:bg-gray-200 pl-2 rounded-md cursor-pointer  pr-3 gap-3  py-2 " onClick={() => { setIsRedeemModal({ state: true, action: "accept" }); setOpenPopoverId(null) }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 18 13" fill="none">
            <path d="M17 1L6 12L1 7" stroke="#00904D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          <div style={{ fontSize: "12px" }}>Complete</div>
        </div>

        <div className=" flex items-center hover:bg-gray-200 pl-2 rounded-md cursor-pointer  pr-3 gap-3  py-2 " onClick={() => { setIsRedeemModal({ state: true, action: "reject" }); setOpenPopoverId(null) }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 14 14" fill="#ff0000">
            <path d="M13 1L1 13M1 1L13 13" stroke="#ff0000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          <div style={{ fontSize: "12px" }}>Reject</div>
        </div>
      </div>
    ) : (
      <div className=" flex flex-col" style={{ fontFamily: "Poppins" }} onClick={() => { setConfirmationModal({ state: true, action: "revert" }) }}>
        <div className=" flex items-center hover:bg-gray-200 pl-2 rounded-md cursor-pointer  pr-3 gap-2  py-1 ">
          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
            <path d="M4.75 8.5L1 4.75M1 4.75L4.75 1M1 4.75H10C10.7956 4.75 11.5587 5.06607 12.1213 5.62868C12.6839 6.19129 13 6.95435 13 7.75V13" stroke="#0365CF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          <div style={{ fontSize: "12px" }}>Revert</div>
        </div>
      </div>
    )

  );

  const menuIcon = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="4"
        height="16"
        viewBox="0 0 4 16"
        fill="none"
        className="bg-[#EAECF0] w-7 h-7 p-2 flex items-center justify-center rounded-full"
      >
        <path
          d="M1.83333 8.5C2.29357 8.5 2.66667 8.1269 2.66667 7.66667C2.66667 7.20643 2.29357 6.83333 1.83333 6.83333C1.3731 6.83333 1 7.20643 1 7.66667C1 8.1269 1.3731 8.5 1.83333 8.5Z"
          stroke="#667085"
          stroke-width="1.66667"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M1.83333 2.66667C2.29357 2.66667 2.66667 2.29357 2.66667 1.83333C2.66667 1.3731 2.29357 1 1.83333 1C1.3731 1 1 1.3731 1 1.83333C1 2.29357 1.3731 2.66667 1.83333 2.66667Z"
          stroke="#667085"
          stroke-width="1.66667"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M1.83333 14.3333C2.29357 14.3333 2.66667 13.9602 2.66667 13.5C2.66667 13.0398 2.29357 12.6667 1.83333 12.6667C1.3731 12.6667 1 13.0398 1 13.5C1 13.9602 1.3731 14.3333 1.83333 14.3333Z"
          stroke="#667085"
          stroke-width="1.66667"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    );
  };



  function columns() {
    let columns = [
      {
        id: "redemptionId",
        name: <TableHead>Redemption ID</TableHead>,
        cell: (row) => (
          <TableCell>
            <Link
              className=" text-primary-blue"
              to={`/redemption-management/${activeKey}/${row.redemptionID}`}
              state={row}
            >
              {row.redemptionID}
            </Link>
          </TableCell>
        ),
      },
      {
        id: "customerId",
        name: <TableHead>Customer ID</TableHead>,
        cell: (row) => (
          <TableCell>
            <Link
              className=" text-primary-blue"
              to={`/customer-management/${row.userID}`}
              state={{ mobileNumber: row?.mobileNumber }}
            >
              {row.userID}
            </Link>
          </TableCell>
        ),
      },
      {
        id: "customerName",
        name: <TableHead>Customer Name</TableHead>,
        cell: (row) => <TableCell>{row.userName}</TableCell>,
      },
      {
        id: "balanceEarningPoints",
        name: <TableHead width="180px">Balance Earning Points</TableHead>,
        cell: (row) => <TableCell width="180px">₹ {row.totalAvailableRewardPoints?.toFixed(2) || 0}</TableCell>,
      },

      {
        id: "redeemAmount",
        name: <TableHead width="180px">Redeem Amount</TableHead>,
        cell: (row) => <TableCell width="180px">₹ {row.amount?.toFixed(2) || 0}</TableCell>,
      }
    ];

    if (activeKey === 'pending-redemption') {
      columns.push({
        id: "gpayProfileName",
        name: <TableHead width="180px">Gpay Profile Name</TableHead>,
        cell: (row) => <TableCell width="180px">{row.gPayProfileName}</TableCell>,
      },
        {
          id: "gpayNumber",
          name: <TableHead width="180px">Gpay Number</TableHead>,
          cell: (row) => <TableCell width="180px">{row.gPayNumber}</TableCell>,
        },
        {
          id: "actions",
          name: <TableHead>Actions</TableHead>,
          cell: (row) => (
            <TableCell>
              <Popover
                content={actionsContent}
                placement="leftTop"
                trigger="click"
                className=" cursor-pointer"
                onOpenChange={() => handlePopoverClick(row)}
                style={{ fontFamily: "Poppins" }}
                open={openPopoverId === row.redemptionID}
              >
                {menuIcon()}
              </Popover>
            </TableCell>
          ),
        })
    }

    else if (activeKey === 'rejected-redemption') {
      columns.push(
        {
          id: "reason",
          name: <TableHead>Reason</TableHead>,
          cell: (row) => <TableCell>{row?.rejectReson ? row?.rejectReson : '-'}</TableCell>,
        },
        {
          id: "actions",
          name: <TableHead>Actions</TableHead>,
          cell: (row) => (
            <TableCell>
              <Popover
                content={actionsContent}
                placement="leftTop"
                trigger="click"
                className=" cursor-pointer"
                onClick={() => handlePopoverClick(row)}
                style={{ fontFamily: "Poppins" }}
              >
                {menuIcon()}
              </Popover>
            </TableCell>
          ),
        }
      );
    }

    else {
      columns.push(
        {
          id: "paidDate",
          name: <TableHead>Paid Date</TableHead>,
          cell: (row) => <TableCell>{row?.paidAndRejectDate ? DateUtils.convertDate(row?.paidAndRejectDate) : '-'}</TableCell>,
        },
        {
          id: "paymentType",
          name: <TableHead>Payment Type</TableHead>,
          cell: (row) => <TableCell>{row?.redemptiontType ? row?.redemptiontType : '-'}</TableCell>,
        },
        {
          id: "transactionId",
          name: <TableHead>Transaction ID</TableHead>,
          cell: (row) => <TableCell>{row?.transactionId ? row?.transactionId : '-'}</TableCell>,
        }
      );
    }
    return columns;
  }


  const handleSearch = (val) => {
    getAllRedeemData(activeKey === 'pending-redemption' ? 0 : activeKey === 'completed-redemption' ? 1 : 2, page, rowsPerPage, val)
    setSearch(val)
  }

  const isValid = () => {
    let isAcceptValid = true;
    let isRejectValid = true;

    if (!modalInputs?.paidDate) {
      setModalErrors(prev => ({ ...prev, paidDateError: 'Paid Date is required' }))
      isAcceptValid = false;
    } else {
      setModalErrors(prev => ({ ...prev, paidDateError: '' }))
    }

    // if (!modalInputs?.redeemAmount) {
    //   setModalErrors(prev => ({ ...prev, redeemAmountError: 'Redeem Amount is required' }));
    //   isAcceptValid = false;
    // } else {
    //   setModalErrors(prev => ({ ...prev, redeemAmountError: '' }))
    // }

    if (!modalInputs?.redemptionType) {
      setModalErrors(prev => ({ ...prev, redemptionTypeError: 'Redeem Type is required' }))
      isAcceptValid = false;

    } else {
      setModalErrors(prev => ({ ...prev, redemptionTypeError: '' }))
    }

    if (!modalInputs?.transactionId) {
      setModalErrors(prev => ({ ...prev, transactionIdError: 'Transaction ID is required' }))
      isAcceptValid = false;

    } else {
      setModalErrors(prev => ({ ...prev, transactionIdError: '' }))
    }

    if (!modalInputs?.reason) {
      setModalErrors(prev => ({ ...prev, reasonError: 'Reason is required' }))
      isRejectValid = false;
    } else {
      setModalErrors(prev => ({ ...prev, reasonError: '' }))
    }

    return [isAcceptValid, isRejectValid];
  }


  const handleSubmit = async () => {
    const [isAcceptValid, isRejectValid] = isValid();
    console.log(isAcceptValid)
    console.log(modalErrors)


    if (isRedeemModal.action === 'accept') {
      if (isAcceptValid) {
        try {
          const payload = {
            id: selectedRow?.id,
            redemptiontType: modalInputs?.redemptionType.type,
            paidAndRejectDate: modalInputs?.paidDate,
            amount: modalInputs?.redeemAmount,
            transactionId: modalInputs?.transactionId
          }
          const response = await updateCompleteRedeemStatus(payload);
          message.success(response.message);
          setIsRedeemModal({
            state: false,
            action: ""
          })
          setModalInputs({
            paidDate: '',
            redeemAmount: selectedRow?.amount,
            redemptionType: '',
            transactionId: '',
            reason: ''
          })
          setModalErrors({
            paidDateError: '',
            redemptionTypeError: '', 
            transactionIdError: '',
            reasonError: ''
          })
          getAllRedeemData(activeKey === 'pending-redemption' ? 0 : activeKey === 'completed-redemption' ? 1 : 2, page, rowsPerPage, search)
        }
        catch (err) {
          message.error(err.response.data.message || err.message);
        }
      }
    }
    else {
      if (isRejectValid) {
        try {
          const payload = {
            id: selectedRow?.id,
            rejectReson: modalInputs?.reason
          }
          const response = await updateRejectRedeemStatus(payload);
          message.success(response.message);
          setIsRedeemModal({
            state: false,
            action: ""
          })
          setModalInputs({
            paidDate: '',
            redeemAmount: selectedRow?.amount,
            redemptionType: '',
            transactionId: '',
            reason: ''
          })
          getAllRedeemData(activeKey === 'pending-redemption' ? 0 : activeKey === 'completed-redemption' ? 1 : 2, page, rowsPerPage, search)
        }
        catch (err) {
          message.error(err.response.data.message || err.message);
        }
      }
    }
  }

  return (
    <div className="w-full removeScrollBar pb-5">
      <div className="flex  flex-wrap  lg:gap-8 gap-2 w-full justify-center lg:flex-nowrap  lg:pl-2  lg:pr-10  lg:justify-start items-center mb-5  lg:mb-10  mt-2 lg:mt-6">
        <Input
          label={"Search"}
          placeholder={"Search by ID/Name/Mobile"}
          value={search}
          onChange={(e) => handleSearch(e.target.value)}
          mandate
        />

      </div>
      <Table columns={columns()} rows={allRedeemData} rowsPerPage={rowsPerPage} page={page} rowCount={rowCount} handleChangePage={handleChangePage} handleChangeRowsPerPage={handleChangeRowsPerPage} />
      <Modal
        title={
          <>
            <div className="flex gap-2">
              <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" fill="orange"><path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" /></svg>
              <h1 className="text-lg" style={{ fontSize: '16px', fontWeight: 'normal' }}>Confirmation</h1>
            </div>

          </>
        }
        visible={confirmationModal.state}
        width={"500px"}
        footer={() => (
          <>
            <div className="flex gap-3 justify-end">
              <Button
                text={"Cancel"}
                textColor={"#1849A9"}
                width={"80px"}
                height={"30px"}
                bgColor={"#fff"}
                borderColor={"#1849A9"}
                fontSize={"12px"}
                onClick={() => setConfirmationModal({ state: false, action: "" })}
              />
              <Button
                text={"Ok"}
                textColor={"#fff"}
                width={"80px"}
                height={"30px"}
                fontSize={"12px"}
                onClick={() => {
                  makeActions(selectedRow, confirmationModal.action)
                  setConfirmationModal({ state: false, action: "" })
                }}
              />
            </div>
          </>
        )}
      >
        <div className="pr-5 pt-5 pb-5">
          <h1 className="text-base" style={{ fontSize: '13px', fontWeight: 'normal' }}>Are you sure want to <em><b>{confirmationModal.action}</b></em> the Redeem ?</h1>
        </div>
      </Modal>
      <RedeemModal
        isRedeemModal={isRedeemModal}
        selectedRow={selectedRow}
        setIsRedeemModal={setIsRedeemModal}
        modalInputs={modalInputs}
        setModalInputs={setModalInputs}
        handleSubmit={handleSubmit}
        modalErrors={modalErrors}
        setModalErrors={setModalErrors}
      />
    </div>

  );
}
