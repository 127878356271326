import axios from 'axios';  // Or use fetch if preferred


const tripType = async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/dropDown/getTripType`, {
      headers: {
        "x-access-token": localStorage.getItem("CC_ADMIN_TOKEN"),
        "email": localStorage.getItem("CC_ADMIN_EMAIL"),
        "usertype": localStorage.getItem("CC_ADMIN_USERTYPE")
      }
    });
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 401 || error.response.status === 403) {
      localStorage.clear();
      window.location.href = "/login";
    } else {
      console.error("Error:", error);
    }
    return error.response && error.response.data;
  }
};

const vehicleType = async (tripTypeId) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/dropDown/getVechicleType/?tripMode=${tripTypeId}`, {
      headers: {
        "x-access-token": localStorage.getItem("CC_ADMIN_TOKEN"),
        "email": localStorage.getItem("CC_ADMIN_EMAIL"),
        "usertype": localStorage.getItem("CC_ADMIN_USERTYPE")
      }
    });
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 401 || error.response.status === 403) {
      localStorage.clear();
      window.location.href = "/login";
    } else {
      console.error("Error:", error);
    }
    return error.response && error.response.data;
  }
}


const getVehicleTypeDropdown = async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/dropdown/getVechicleTypeDropDown`, {
      headers: {
        "x-access-token": localStorage.getItem("CC_ADMIN_TOKEN"),
        "email": localStorage.getItem("CC_ADMIN_EMAIL"),
        "usertype": localStorage.getItem("CC_ADMIN_USERTYPE")
      }
    });
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 401 || error.response.status === 403) {
      localStorage.clear();
      window.location.href = "/login";
    } else {
      console.error("Error:", error);
    }
    return error.response && error.response.data;
  }
}

const getAllDrivers = async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/driver/getDriversIdDropDown`, {
      headers: {
        "x-access-token": localStorage.getItem("CC_ADMIN_TOKEN"),
        "email": localStorage.getItem("CC_ADMIN_EMAIL"),
        "usertype": localStorage.getItem("CC_ADMIN_USERTYPE")
      }
    });
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 401 || error.response.status === 403) {
      localStorage.clear();
      window.location.href = "/login";
    } else {
      console.error("Error:", error);
    }
    return error.response && error.response.data;
  }
}

export const getAllDriversForDropdown = async (driverSearch) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/driver/getDriversIdDropDown?searchQuery=${driverSearch}`, {
      headers: {
        "x-access-token": localStorage.getItem("CC_ADMIN_TOKEN"),
        "email": localStorage.getItem("CC_ADMIN_EMAIL"),
        "usertype": localStorage.getItem("CC_ADMIN_USERTYPE")
      }
    });
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 401 || error.response.status === 403) {
      localStorage.clear();
      window.location.href = "/login";
    } else {
      console.error("Error:", error);
    }
    return error.response && error.response.data;
  }
}

const getDriver = async (driverId) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/driver/getDriverOne/?id=${driverId}`, {
      headers: {
        "x-access-token": localStorage.getItem("CC_ADMIN_TOKEN"),
        "email": localStorage.getItem("CC_ADMIN_EMAIL"),
        "usertype": localStorage.getItem("CC_ADMIN_USERTYPE")
      }
    });
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 401 || error.response.status === 403) {
      localStorage.clear();
      window.location.href = "/login";
    } else {
      console.error("Error:", error);
    }
    return error.response && error.response.data;
  }
}

export const getPaymentMode = async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/dropDown/getPaymentModeDropDown`, {
      headers: {
        "x-access-token": localStorage.getItem("CC_ADMIN_TOKEN"),
        "email": localStorage.getItem("CC_ADMIN_EMAIL"),
        "usertype": localStorage.getItem("CC_ADMIN_USERTYPE")
      }
    });
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 401 || error.response.status === 403) {
      localStorage.clear();
      window.location.href = "/login";
    } else {
      console.error("Error:", error);
    }
    return error.response && error.response.data;
  }
}

export default { tripType, vehicleType, getVehicleTypeDropdown, getAllDrivers, getDriver };
