import { isNotEmpty, isNotValidDate, isNotValidTime } from "./validations";


export const validateFields = (data) => {
    console.log(data)
    const errors = {};

    //validate input fields
    if ((data.fromLocation !== undefined && data.fromLocation !== null) && !isNotEmpty(data.fromLocation)) {
        errors.fromLocation = "From location is required";
    }

    if ((data.toLocation !== undefined && data.toLocation !== null) && !isNotEmpty(data.toLocation)) {
        errors.toLocation = "To location is required";
    }

    if ((data.tripTime !== undefined && data.tripTime !== null) && !isNotEmpty(data.tripTime)) {
        errors.tripTime = "Trip Time is required"
    }

    if((data.tripTime !== undefined && data.tripTime !== null) && !isNotValidTime(data.tripTime, data.tripDate)) {
        errors.tripTime = "Trip Time is Invalid"
    }

    if ((data.vehicleType !== undefined && data.vehicleType !== null) && !isNotEmpty(data.vehicleType)) {
        errors.vehicleType = "Vehicle Type is required"
    }

    if ((data.mobileNumber !== undefined && data.mobileNumber !== null) && !isNotEmpty(data.mobileNumber)) {
        errors.mobileNumber = "Mobile Number is required"
    }
    
    if((data.totalKmReading !== undefined && data.totalKmReading !== null) && !isNotEmpty(data.totalKmReading)) {
        errors.totalKmReading = 'Total KM Reading is required';
    }

    if((data.noOfdays !== undefined && data.noOfdays !== null) && !isNotEmpty(data.noOfdays)) {
        errors.noOfdays = 'No of days is required';
    }

    if((data.rentalDuration !== undefined && data.rentalDuration !== null) && !isNotEmpty(data.rentalDuration)) {
        errors.rentalDuration = 'Rental Duration is required';
    }

    if((data.customerUsername !== undefined && data.customerUsername !== null) && !isNotEmpty(data.customerUsername)) {
        errors.customerUsername = 'Username is required';
    }


    if (data?.tripType?.tripName === "One Way" || data?.tripType?.tripName === "Local Drop") {

        if (!isNotEmpty(data.tripDate)) {
            errors.tripDate = "Trip Date is required"
        }
        if(!isNotValidDate(data.tripDate)) {
            errors.tripDate = "Trip Date is Invalid"
        }
    }

    if (data?.tripType?.tripName === "Round Way") {

        if (!isNotEmpty(data.pickupDate)) {
            errors.pickupDate = "Pickup Date is required"
        }

        if(!isNotValidDate(data.pickupDate)) {
            errors.tripDate = "Pickup Date is Invalid"
        }

        if((data.tripTime !== undefined && data.tripTime !== null) && !isNotValidTime(data.tripTime, data.pickupDate)) {
            errors.tripTime = "Trip Time is Invalid"
        }

        if (!isNotEmpty(data.returnDate)) {
            errors.returnDate = "Return Date is required"
        }

    }

    if (data?.tripType?.tripName === "Rental") {
        if (!isNotEmpty(data.pickupDate)) {
            errors.pickupDate = "Pickup Date is required"
        }

        if(!isNotValidDate(data.pickupDate)) {
            errors.tripDate = "Pickup Date is Invalid"
        }

        if(!isNotValidTime(data.tripTime, data.pickupDate)) {
            errors.tripTime = "Trip Time is Invalid"
        }

        
        if (!isNotEmpty(data.distanceAndDurationVal && data.distanceAndDurationVal.distance)) {
            errors.distance = "Distance is required"
        }

        if (!isNotEmpty(data.distanceAndDurationVal && data.distanceAndDurationVal.duration)) {
            errors.duration = "Duration is required"
        }
    }
    return errors;
}