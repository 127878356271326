import { useState } from "react";
import { Link } from "react-router-dom";
import Table from "../../reusable/Table/Table";
import TableHead from "../../reusable/Table/TableHead";
import TableCell from "../../reusable/Table/TableCell";
import StatusCard from "../../reusable/Table/TripStatus";
import DropDown from "../../reusable/DropDown";
import DateInput from "../../reusable/DateInput";
import { Modal, Popover, message } from "antd";
import Input from "../../reusable/Input";
import Button from "../../reusable/Button";
import { updateRide } from "../../../services/TripManagementServices";
import Filters from "./components/Filters";
import ManualCompleteModal from "./components/ManualCompleteModal";
import DateUtils from "../../../pages/utils/DateUtils";
import LiveTrackingPopoverContent from "../../../pages/trip-management/components/LiveTrackingPopoverContent";

export default function AppAllotedTable({
  tripData,
  getRides,
  tripStatus,
  rowsPerPage,
  page,
  handleChangePage,
  handleChangeRowsPerPage,
  handleRideIdSearch,
  handleTripTypeFilter,
  handleCabTypeFilter,
  handleFromDateFilter,
  handleToDateFilter,
  rideId,
  vehicles,
  tripTypeData,
  tripTypeFilter,
  cabType,
  fromDate,
  toDate,
  rowCount,
}) {

  const [selectedRow, setSelectedRow] = useState(null)
  const [confirmationModal, setConfirmationModal] = useState({ state: false, action: "" });
  const [isManualCompleteModal, setIsManualCompleteModal] = useState(false);
  const [openPopoverId, setOpenPopoverId] = useState(null);


  // Function to identify the particular action
  async function makeActions(row, action) {
    let actionDetails = { actionId: 0, message: '' };

    if (action === 'Manual Complete') {
      setIsManualCompleteModal(true);
      return;
    }

    if (action === 'Cancel') {
      actionDetails.actionId = 0;
      actionDetails.message = 'Ride reverted successfully!'

      try {
        const response = await updateRide(row.id, action === 'Cancel' ? { tripStatus: actionDetails.actionId, driverId: null, startKm: null, startRideStatus: 0, startRideDate: null, startRideTime: null } : { tripStatus: actionDetails.actionId })
        message.success(actionDetails.message)
        getRides(tripStatus, rowsPerPage, page + 1)
        actionDetails = { actionId: 0, message: '' }
      }
      catch (error) {
        message.error(error.message || error.response.data.message || error.response.data.error)
        actionDetails = { actionId: 0, message: '' }
      }
    }


  }


  // Function to get the selected row
  function handlePopoverClick(row) {
    if (openPopoverId === row.rideId) {
      setOpenPopoverId(null);
    } else {
      setSelectedRow(row);
      setOpenPopoverId(row.rideId);
    }
  }

  const columns = [
    {
      id: "rideId",
      name: <TableHead>Trip ID</TableHead>,
      cell: (row) => (
        <TableCell>
          <Link
            className=" text-primary-blue"
            to={{ pathname: `/trip-management/${row.rideId}/${row.tripMode.tripName}` }}
            state={row}
          >
            {row.rideId}
          </Link>
        </TableCell>
      ),
    },
    {
      id: "tripDate",
      name: <TableHead>Trip Date</TableHead>,
      cell: (row) => <TableCell>{DateUtils.convertDate(row.dateOfRide)}</TableCell>,
    },
    {
      id: "pickupTime",
      name: <TableHead>Pickup Time</TableHead>,
      cell: (row) => <TableCell>{DateUtils.convertTime(row.pickUpTime)}</TableCell>,
    },
    {
      id: "tripType",
      name: <TableHead>Trip Type</TableHead>,
      cell: (row) => <TableCell>{row.tripMode.tripName}</TableCell>,
    },
    {
      id: "vehicleType",
      name: <TableHead>Vehical Type</TableHead>,
      cell: (row) => <TableCell>{row.carMode.carType}</TableCell>,
    },

    {
      id: "estTotalFare",
      name: <TableHead>Est. Total Fare</TableHead>,
      cell: (row) => <TableCell>₹ {row.totalFare}</TableCell>,
    },
    {
      id: "status",
      name: <TableHead width="200px">Status</TableHead>,
      cell: (row) => (
        <TableCell width="180px">
          <div className=" w-full h-fit py-10 flex justify-center items-center   ">
            <Popover
              content={<LiveTrackingPopoverContent beginDate={row?.startRideDate ? DateUtils.convertDate(row?.startRideDate) : "Start Date: Not started"} beginTime={row?.startRideTime ? DateUtils.convertTime(row?.startRideTime) : "Start Time: Not started"} />}
              placement="top"
              trigger="hover"
            >
              <div className={`w-4 h-4 rounded-full  ${row.startRideStatus === 0 ? "bg-[gray]" : "bg-[#12B76A]"}`}></div>
            </Popover>
            <div className=" h-[2px] w-32 bg-gray-300"></div>
            <Popover
              content={<LiveTrackingPopoverContent beginDate={row?.endRideDate ? DateUtils.convertDate(row?.endRideDate) : "End Date: Not ended"} beginTime={row?.endRideTime ? DateUtils.convertTime(row?.endRideTime) : "End Time: Not ended"} />}
              placement="top"
              trigger="hover"
            >
              <div className={`w-4 h-4 rounded-full  ${row.endRideStatus === 0 ? "bg-[gray]" : "bg-[#12B76A]"}`}></div>
            </Popover>
          </div>
        </TableCell>
      ),
    },
    {
      id: "mode",
      name: <TableHead>Booking Mode</TableHead>,
      cell: (row) => (
        <TableCell>
          <StatusCard status={row.bookingMode.bookingMode} />
        </TableCell>
      ),
    },
    {
      id: "actions",
      name: <TableHead>Actions</TableHead>,
      cell: (row) => (
        <TableCell>
          <Popover
            content={actionsContent}
            placement="leftTop"
            trigger="click"
            className=" cursor-pointer"
            onOpenChange={() => handlePopoverClick(row)}
            style={{ fontFamily: "Poppins" }}
            open={openPopoverId === row.rideId}
          >
            {menuIcon()}
          </Popover>

        </TableCell >
      ),
    },
  ];

  const menuIcon = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="4"
        height="16"
        viewBox="0 0 4 16"
        fill="none"
        className="bg-[#EAECF0] w-7 h-7 p-2 flex items-center justify-center rounded-full"
      >
        <path
          d="M1.83333 8.5C2.29357 8.5 2.66667 8.1269 2.66667 7.66667C2.66667 7.20643 2.29357 6.83333 1.83333 6.83333C1.3731 6.83333 1 7.20643 1 7.66667C1 8.1269 1.3731 8.5 1.83333 8.5Z"
          stroke="#667085"
          stroke-width="1.66667"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M1.83333 2.66667C2.29357 2.66667 2.66667 2.29357 2.66667 1.83333C2.66667 1.3731 2.29357 1 1.83333 1C1.3731 1 1 1.3731 1 1.83333C1 2.29357 1.3731 2.66667 1.83333 2.66667Z"
          stroke="#667085"
          stroke-width="1.66667"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M1.83333 14.3333C2.29357 14.3333 2.66667 13.9602 2.66667 13.5C2.66667 13.0398 2.29357 12.6667 1.83333 12.6667C1.3731 12.6667 1 13.0398 1 13.5C1 13.9602 1.3731 14.3333 1.83333 14.3333Z"
          stroke="#667085"
          stroke-width="1.66667"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    );
  };

  const actionsContent = (
    <div className=" flex flex-col" style={{ fontFamily: "Poppins" }}>
      <div className=" flex items-center hover:bg-gray-200 pl-2 rounded-md cursor-pointer  pr-3 gap-3  py-1 " onClick={() => { makeActions(selectedRow, "Manual Complete"); setOpenPopoverId(null) }}>
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 18 13" fill="none">
          <path d="M17 1L6 12L1 7" stroke="#00904D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        <div style={{ fontSize: "12px" }}>Manual complete</div>
      </div>

      <div className=" flex items-center hover:bg-gray-200 pl-2 rounded-md cursor-pointer  pr-3 gap-3  py-1 " onClick={() => { setConfirmationModal({ state: true, action: "Cancel" }); setOpenPopoverId(null) }}>
        <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 14 14" fill="#ff0000">
          <path d="M13 1L1 13M1 1L13 13" stroke="#ff0000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        <div style={{ fontSize: "12px" }}>Cancel ride</div>
      </div>
    </div>
  )

  return (
    <div className="w-full removeScrollBar pb-5">
      <div className="flex  flex-wrap  lg:gap-8 gap-2 w-full justify-center lg:flex-nowrap  lg:pl-2  lg:pr-10  lg:justify-between items-center mb-5  lg:mb-10  mt-2 lg:mt-6">
        <Filters
          rideId={rideId}
          handleRideIdSearch={handleRideIdSearch}
          tripTypeData={tripTypeData}
          tripTypeFilter={tripTypeFilter}
          handleTripTypeFilter={handleTripTypeFilter}
          vehicles={vehicles}
          cabType={cabType}
          handleCabTypeFilter={handleCabTypeFilter}
          fromDate={fromDate}
          handleFromDateFilter={handleFromDateFilter}
          toDate={toDate}
          handleToDateFilter={handleToDateFilter}
        />
      </div>
      <Table columns={columns} rows={tripData.appAllotedTable.appAllotedTableData} rowCount={rowCount} tripStatus={tripStatus} rowsPerPage={rowsPerPage} page={page} handleChangePage={handleChangePage} handleChangeRowsPerPage={handleChangeRowsPerPage} />

      <Modal
        title={
          <>
            <div className="flex gap-2">
              <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" fill="orange"><path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" /></svg>
              <h1 className="text-lg" style={{ fontSize: '16px', fontWeight: 'normal' }}>Confirmation</h1>
            </div>

          </>
        }
        visible={confirmationModal.state}
        width={"500px"}
        footer={() => (
          <>
            <div className="flex gap-3 justify-end">
              <Button
                text={"Cancel"}
                textColor={"#1849A9"}
                width={"80px"}
                height={"30px"}
                bgColor={"#fff"}
                borderColor={"#1849A9"}
                fontSize={"13px"}
                onClick={() => setConfirmationModal({ state: false, action: "" })}
              />
              <Button
                text={"Ok"}
                textColor={"#fff"}
                width={"80px"}
                height={"30px"}
                fontSize={"13px"}
                onClick={() => {
                  makeActions(selectedRow, confirmationModal.action)
                  setConfirmationModal({ state: false, action: "" })
                }}

              />
            </div>
          </>
        )}
      >
        <div className="pr-5 pt-5 pb-5">
          <h1 className="text-base" style={{ fontSize: '13px', fontWeight: 'normal' }}>Are you sure want to <em><b>{confirmationModal.action}</b></em> the ride ?</h1>
        </div>
      </Modal>


      <ManualCompleteModal
        isManualCompleteModal={isManualCompleteModal}
        setIsManualCompleteModal={setIsManualCompleteModal}
        selectedRow={selectedRow}
        getRides={getRides}
        tripStatus={tripStatus}
        rowsPerPage={rowsPerPage}
        page={page}
      />
    </div>
  );
}
