import Accordion from "../reusable/Accordion";
import React, { useState } from "react";
import { ConfigProvider, Popover } from "antd";
import filter from "../../assets/filter.svg";

const FilterLayout = ({ content, handleClearFilter }) => {
  const numberOfFilterItems = Array.from({ length: 4 }, () => false);
  const [popoverVisible, setPopoverVisible] = useState(false);
  const [isOpenArray, setIsOpenArray] = useState(
    numberOfFilterItems
  );

  const handleVisibleChange = (visible) => {
    setPopoverVisible(visible);
  };

  const removeDefaultMargin =
    `
    .ant-popover .ant-popover-inner {
      padding: 0;
      margin: 0;
    }
  `
  const filterChildren = () => {
    return (
      <div
        id="scrollable"
        className="rounded-md bg-white w-80 max-h-[70vh] overflow-y-auto z-50 text-[#98A2B3]"
      >
        <Accordion
          data={content}
          isOpenArray={isOpenArray}
          setIsOpenArray={setIsOpenArray}
        />
        {(
          <div className={`flex w-full justify-between px-4 py-4`}>
            <button
              className="border border-gray-500 text-gray-500 hover:bg-gray-500 hover:text-white px-4 py-1 w-full rounded-lg font-medium"
              type="button"
              onClick={() => { handleClearFilter(); setPopoverVisible(false); setIsOpenArray(numberOfFilterItems); }}
            >
              {"Clear All Filters"}
            </button>
          </div>
        )}
      </div>
    );
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Popover: {
            colorSplit: "red",
          },
        },
      }}
    >
      {popoverVisible && (
        <div
          className="backdrop"
          onClick={() => setPopoverVisible(false)}
        ></div>
      )}
      <Popover
        content={filterChildren}
        trigger="click"
        visible={popoverVisible}
        onVisibleChange={handleVisibleChange}
        className="filter"
      >
        <style>{removeDefaultMargin}</style>
        <button className="  py-4 px-5  rounded-lg top-0 right-0">
          <img src={filter} alt="filter" />
        </button>
      </Popover>

      <style jsx global>{`
        .backdrop {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.3);
          z-index: 999; // Adjust as needed
        }
      `}</style>
    </ConfigProvider>
  );
};

export default FilterLayout;
