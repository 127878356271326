import { useState } from "react";
import Input from "../components/reusable/Input";
import { message } from "antd";
import Button from "../components/reusable/Button";
import MobileNumberInput from "../components/reusable/MobileNumberInput";
import logo from "../assets/cc_logo.png"
import { accountDeletionRequest } from "../services/CustomerManagementServices";

export default function AccountDeletion() {

    // const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [mobile, setMobile] = useState("");
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({ email: "", mobile: "" });
    const [isRequestSent, setIsRequestSent] = useState(false);

    const validateFields = () => {
        let allErrors;
        let isValid = true;
        // if (!name) {
        //     allErrors = { ...allErrors, name: "Please enter your name" };
        //     isValid = false;
        // }
        if (!email) {
            allErrors = { ...allErrors, email: "Please enter your email" };
            isValid = false;
        }
        if (!mobile) {
            allErrors = { ...allErrors, mobile: "Please enter your mobile number" };
            isValid = false;
        }
        setErrors(allErrors);
        return isValid;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateFields()) {
            return;
        }
        const data = {
            // name: name,
            email: email,
            mobileNumber: parseInt(mobile),
        };
        setLoading(true);
        const response = await accountDeletionRequest(data);
        if (response && response.status === true) {
            setLoading(false);
            setIsRequestSent(true);
            message.success("Request has been sent!");
        } else {
            setLoading(false);
            message.error(response.message);
        }
    }

    return (
        <div className="min-h-screen flex items-center justify-center px-5 md:px-0">
            <div className={`flex flex-col justify-center items-center gap-2 ${isRequestSent ? "w-full" : "w-[360px]"} mb-12`}>
                <img src={logo} alt="logo" className="w-40" />
                <h1 className="text-4xl md:text-5xl font-semibold">Chennai Cabs</h1>
                {isRequestSent === false ? (
                    <>
                        <h2 className="text-xl font-medium">Account Deletion Request</h2>
                        <form onSubmit={handleSubmit} className="space-y-7 flex flex-col items-center justify-center mt-6 shadow-md rounded-md border w-full px-8 py-12">
                            {/* <Input
                        label="Name"
                        name="name"
                        placeholder={"Please enter your name"}
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        error={errors.name}
                        mandate
                    /> */}
                            <Input
                                label="Email"
                                name="email"
                                placeholder={"Please enter your email id"}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                error={errors?.email}
                                mandate
                            />
                            <MobileNumberInput
                                label="Mobile"
                                name="mobile"
                                placeholder={"Please enter your mobile number"}
                                value={mobile}
                                setValue={setMobile}
                                error={errors?.mobile}
                                mandate
                            />
                            <div className="pt-4">
                                <Button
                                    type="submit"
                                    loading={loading}
                                    text="Submit"
                                    color="primary"
                                    width={"170px"}
                                >
                                    Submit
                                </Button>
                            </div>
                        </form>
                    </>
                ) : (
                    <div className="flex flex-col items-center justify-center mt-6">
                        <h2 className="text-xl font-medium text-center">Your account deletion request has been sent successfully!</h2>
                        <p className="text-lg font-medium text-gray-500 mt-4">You can close this window.</p>
                    </div>
                )}
            </div>
        </div>
    )
}
