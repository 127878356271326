import { Card } from "antd";
import React from "react";
import Upload from "../../../reusable/Upload";
import Input from "../../../reusable/Input";
import DateInput from "../../../reusable/DateInput";
import DropDown from "../../../reusable/DropDown";
import MobileNumberInput from "../../../reusable/MobileNumberInput";

const PersonalDetailsComponent = ({
  driverImage,
  profileImage,
  driverId,
  driverName,
  setDriverName,
  mobileNumber,
  setMobileNumber,
  vehicleImage,
  vehicleNumber,
  setVehicleNumber,
  vehicleName,
  setVehicleName,
  vehicleType,
  setVehicleType,
  allVehicle,
  isEditable1,
  setAddDriverModal,
  addDriverModal,
  driverNameValidation,
  mobileNumberValidation,
  vehicleNameValidation,
  vehicleNumberValidation,
  vehicleTypeValidation,
  content,
}) => {
  return (
    <>
      <div className=" w-full px-1 flex justify-center items-center ">
        <div className=" w-full   ">
          <div className=" w-full flex items-center border  mt-6 flex-wrap     shadow-sm rounded-md">
            <div className="  w-full lg:w-1/3  border-r   p-[24px] flex-wrap   flex justify-center items-center">
              <div className=" w-full flex justify-center flex-col gap-3 items-center relative">
                <div className="md:hidden top-0 -right-3 absolute">
                  {!isEditable1 && content(setAddDriverModal, addDriverModal)}
                </div>
                <p className="text-lg font-medium  ">Personal Details</p>
                <div className=" w-full flex-col flex justify-center items-center">
                  {profileImage ?
                    <img src={profileImage} alt="" className="w-24 h-24 rounded-full" />
                    :
                    <img src={driverImage} width={140} alt="" />
                  }
                  <p className="text-lg font-black pt-2  ">{driverId}</p>
                </div>
                <div className=" flex flex-col w-full justify-center items-center gap-4">
                  <Input
                    label={"Enter Driver Name"}
                    placeholder={"Enter driver name"}
                    value={driverName}
                    setValue={setDriverName}
                    onChange={(e) => {
                      setDriverName(e.target.value);
                    }}
                    disabled={!isEditable1}
                    error={driverNameValidation}
                  />
                  <MobileNumberInput
                    label={"Mobile Number"}
                    value={mobileNumber}
                    onChange={(e) => {
                      setMobileNumber(e.target.value);
                    }}
                    setValue={setMobileNumber}
                    disabled={!isEditable1}
                    error={mobileNumberValidation}
                  />
                </div>
              </div>
            </div>
            <div className="  w-full lg:w-2/3   p-[24px]  flex-wrap   flex justify-center items-center">
              <div className="hidden md:flex w-full justify-end items-center">
                {!isEditable1 && content(setAddDriverModal, addDriverModal)}
              </div>
              <div className=" w-full flex justify-center flex-col gap-3 items-center ">
                <p className="text-lg font-medium  ">Vehicle Details</p>
                <div className=" w-full flex-col flex pl-10  justify-center items-center">
                  <img src={vehicleImage} width={140} alt="" />
                  <p className="text-lg font-black pt-2  invisible ">
                    {driverId}
                  </p>
                </div>
                <div className=" flex flex-col w-full justify-center items-center gap-4">
                  <div className=" w-full flex justify-around  flex-wrap  lg:gap-0 gap-3   lg:pl-8  items-center ">
                    <Input
                      label={"Vehicle Number"}
                      placeholder={"Enter vehicle number"}
                      value={vehicleNumber}
                      onChange={(e) => {
                        setVehicleNumber(e.target.value);
                      }}
                      setValue={setVehicleNumber}
                      disabled={!isEditable1}
                      error={vehicleNumberValidation}
                    />
                    <Input
                      label={"Vehicle Name"}
                      placeholder={"Enter vehicle name"}
                      value={vehicleName}
                      onChange={(e) => {
                        setVehicleName(e.target.value);
                      }}
                      setValue={setVehicleName}
                      disabled={!isEditable1}
                      error={vehicleNameValidation}
                    />
                  </div>
                  <div className=" w-full flex justify-around flex-wrap   items-center ">
                    <DropDown
                      label={"Vehicle Type"}
                      placeholder={"Select vehicle type"}
                      displayValue={"name"}
                      data={allVehicle}
                      value={vehicleType}
                      setValue={setVehicleType}
                      disabled={!isEditable1}
                      error={vehicleTypeValidation}
                    />
                    <div className="hidden lg:block lg:invisible">
                      <DropDown
                        label={"Vehicle Type"}
                        placeholder={"Select vehicle type"}
                        displayValue={"name"}
                        data={allVehicle}
                        value={vehicleType}
                        setValue={setVehicleType}
                        disabled={!isEditable1}
                        mandate={true}
                        error={vehicleTypeValidation}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </>
  );
};

export default PersonalDetailsComponent;