import { Link } from "react-router-dom";
import Table from "../../../reusable/Table/Table";
import TableCell from "../../../reusable/Table/TableCell";
import TableHead from "../../../reusable/Table/TableHead";
import CustomerFilters from "../components/CustomerFilters";
import StatusCard from "../../../reusable/Table/TripStatus";
import { updateCustomer } from "../../../../services/CustomerManagementServices";
import { message } from "antd";
import { useEffect, useState } from "react";
import ConfirmModal from "../../../reusable/mdoal/ConfirmModal";

export default function BlockedUserTable({
  customerData,
  filters,
  rowsPerPage,
  page,
  rowCount,
  handleChangePage,
  handleChangeRowsPerPage,
  setFilters,
  getAllCustomer
}

) {

  const [confirmModal, setConfirmModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState("")

  const blockedCustomerTableColumns = [
    {
      id: "customerId",
      name: <TableHead>Customer ID</TableHead>,
      cell: (row) => (
        <TableCell>
          <Link
            className=" text-primary-blue"
            to={{ pathname: `/customer-management/${row.userID}` }}
            state={row}
          >
            {row.userID}
          </Link>
        </TableCell>
      ),
    },
    {
      id: "customerName",
      name: <TableHead>Name</TableHead>,
      cell: (row) => <TableCell>{row.userName ? row.userName : "-"}</TableCell>,
    },
    {
      id: "customerPhoneNumber",
      name: <TableHead>Phone Number</TableHead>,
      cell: (row) => <TableCell>{row.mobileNumber}</TableCell>,
    },
    {
      id: "referralId",
      name: <TableHead >Referral ID</TableHead>,
      cell: (row) => <TableCell>{row.referralCode ? row.referralCode : "-"}</TableCell>,
    },
    {
      id: "totalFriendsReferred",
      name: <TableHead width="180px">Total Friends Referred</TableHead>,
      cell: (row) => <TableCell width="180px">{row.totalCountReferral ? row.totalCountReferral : "-"}</TableCell>,
    },
    {
      id: "totalRewardPoints",
      name: <TableHead width="180px">Total Reward Points</TableHead>,
      cell: (row) => <TableCell width="180px">{row.totalRewardPoint ? row.totalRewardPoint : "-"}</TableCell>,
    },
    {
      id: "totalRedeemedPoints",
      name: <TableHead width="180px">Total Redeemed Points</TableHead>,
      cell: (row) => <TableCell width="180px">{row.totalReedmePoint ? row.totalReedmePoint : "-"}</TableCell>,
    },
    {
      id: "balanceRewardPoints",
      name: <TableHead width="180px">Balance Reward Points</TableHead>,
      cell: (row) => <TableCell width="180px">{row.totalBalancePoint ? row.totalBalancePoint : "-"}</TableCell>,
    },
    {
      id: "comment",
      name: <TableHead>Comment</TableHead>,
      cell: (row) => (
        <TableCell>
          <p>{row.blockComment || "-"}</p>
        </TableCell>
      ),
    },
    {
      id: "actions",
      name: <TableHead>Actions</TableHead>,
      cell: (row) => (
        <TableCell>
          <div className=" flex flex-col" style={{ fontFamily: "Poppins" }} onClick={() => { setConfirmModal(!confirmModal); setSelectedRow(row); }}>
            <div className=" flex items-center hover:bg-gray-200 pl-2 rounded-md cursor-pointer  pr-3 gap-2  py-1 ">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                <path d="M4.75 8.5L1 4.75M1 4.75L4.75 1M1 4.75H10C10.7956 4.75 11.5587 5.06607 12.1213 5.62868C12.6839 6.19129 13 6.95435 13 7.75V13" stroke="#0365CF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              <div style={{ fontSize: "12px" }}>Revert</div>
            </div>
          </div>

        </TableCell>
      ),
    },
  ];


  async function handleRevertCustomer(row) {
    try {
      const response = await updateCustomer({ id: +row.id, userStatus: 1, blockComment: null });
      setConfirmModal(!confirmModal)
      message.success('Customer Reverted!');
      getAllCustomer(row.userStatus, page + 1, rowsPerPage);
    }
    catch (err) {
      message.error(err.resonse.data.message || err.message);
    }
  }



  return (
    <>
      <div className="w-full removeScrollBar pb-5">
        <div className="flex  flex-wrap  lg:gap-8 gap-2 w-full justify-center lg:flex-nowrap  lg:pl-2  lg:pr-10  lg:justify-between items-center mb-5  lg:mb-10  mt-2 lg:mt-6">
          <CustomerFilters
            customerId={filters.customerId}
            customerTypeData={[{ id: 1, customerType: "Application" }, { id: 2, customerType: "Website" }, { id: 3, customerType: "Manual" }]}

            customerType={filters.customerType}
            fromDate={filters.fromDate}
            toDate={filters.toDate}
            setFilters={setFilters}
          />

        </div>
        <Table columns={blockedCustomerTableColumns} rows={customerData.data ? customerData.data : []} rowCount={rowCount} rowsPerPage={rowsPerPage} page={page}
          handleChangePage={handleChangePage} handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
        {/* 
      <Modal
        title={
          <>
            <div className="flex gap-2">
              <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" fill="orange"><path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" /></svg>
              <h1 className="text-lg" style={{ fontSize: '16px', fontWeight: 'normal' }}>Confirmation</h1>
            </div>

          </>
        }
        visible={confirmationModal.state}
        width={"500px"}
        footer={() => (
          <>
            <div className="flex gap-3 justify-end">
              <Button
                text={"Cancel"}
                textColor={"#1849A9"}
                width={"80px"}
                height={"30px"}
                bgColor={"#fff"}
                borderColor={"#1849A9"}
                fontSize={"12px"}
                onClick={() => setConfirmationModal({ state: false, action: "" })}
              />
              <Button
                text={"Ok"}
                textColor={"#fff"}
                width={"80px"}
                height={"30px"}
                fontSize={"12px"}
                onClick={() => {
                  makeActions(selectedRow, confirmationModal.action)
                  setConfirmationModal({ state: false, action: "" })
                }}
              />
            </div>
          </>
        )}
      >
        <div className="pr-5 pt-5 pb-5">
          <h1 className="text-base" style={{ fontSize: '13px', fontWeight: 'normal' }}>Are you sure want to <em><b>{confirmationModal.action}</b></em> the ride ?</h1>
        </div>
      </Modal> */}
        <ConfirmModal
          isOpen={confirmModal}
          setIsOpen={setConfirmModal}
          title={"Revert ?"}
          description={"Do you want to revert this customer?"}
          button1={"No"}
          button2={"Yes"}
          onClickButton1={() => setConfirmModal(!confirmModal)}
          onClickButton2={() => {
            handleRevertCustomer(selectedRow);
          }}
        />
      </div>
    </>
  )
}