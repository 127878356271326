import { useContext, useEffect, useRef, useState } from "react";
import { Divider, Modal } from "antd";

//Components
import PageLayout from "../../components/layouts/PageLayout";
import TabNavigation from "../../components/reusable/TabNavigation";
import { LabelForTabs } from "../../components/reusable/TabNavigation";
import Input from "../../components/reusable/Input";
import DropDown from "../../components/reusable/DropDown";
import DateInput from "../../components/reusable/DateInput";
import TimeInput from "../../components/reusable/TimeInput";
import Button from "../../components/reusable/Button";
import MobileNumberInput from "../../components/reusable/MobileNumberInput";
import { message } from 'antd';

//Tab components
import NewBookingTable from "../../components/tables/trip-management/NewBookingTable";
import AcceptedTable from "../../components/tables/trip-management/accepted-table/AcceptedTable";
import ProceededTable from "../../components/tables/trip-management/ProceededTable";
import AppAllotedTable from "../../components/tables/trip-management/AppAllotedTable";
import ManualAllotedTable from "../../components/tables/trip-management/ManualAllotedTable";
import CompletedTable from "../../components/tables/trip-management/CompletedTable";
import CancelledTable from "../../components/tables/trip-management/CancelledTable";
import FakedTable from "../../components/tables/trip-management/FakedTable";

//Google map 
import { useJsApiLoader, Autocomplete } from "@react-google-maps/api";

//Context
import CommonContext from "../../context-storage/CommonServicesHandler";
import { tripTimeDropDownData } from "../utils/utils";

//Images & svgs
import addTrip from "./assets/add-trip.png";
import { bookRide, getAllRide, getEstimatedRideFare, getRidesCounts } from "../../services/TripManagementServices";

import { validateFields } from "./validations/validate";
import { isNotEmpty } from "./validations/validations";
import { getTripStatus } from "../utils/utils";
import verified from "./assets/verified.svg";
import notVerified from "./assets/notVerified.svg";
import { customerCreateByAdmin, getCustomerByMobileNumber, updateCustomer } from "../../services/CustomerManagementServices";
import ManualNotPaidTable from "../../components/tables/trip-management/ManualNotPaidTable";


const libraries = ["places"]

export default function TripManagement() {

  const [addTripModal1, setAddTripModal1] = useState(false);
  const [addTripModal2, setAddTripModal2] = useState(false);
  const [getRidesCountResponse, setGetRidesCountResponse] = useState([])
  const [customer, setCustomer] = useState({
    isVerifiedMessage: false,
    isVerified: false,
    customerId: "",
    userName: "",
    email: ""
  })

  //Common states

  const [tripTypeDropDown, setTripTypeDropDown] = useState([]);

  const [tripType, setTripType] = useState({ id: 1, tripName: "One Way" });

  const [fromLocation, setFromLocation] = useState("");
  const [toLocation, setToLocation] = useState("");

  const [tripTime, setTripTime] = useState({ label: "", value: "" });

  const [vehicleTypeDropDown, setVehicleTypeDropDown] = useState([]);
  const [vehicleType, setVehicleType] = useState({ id: 1, carType: 'Sedan' });

  const [mobileNumber, setMobileNumber] = useState("");

  //States for one way trip
  const [tripDate, setTripDate] = useState("");

  //States for round way trip
  const [returnDate, setReturnDate] = useState("");

  //States for rental trip
  const [pickupDate, setPickupDate] = useState(""); // common for round way trip
  const [distanceAndDurationVal, setDistanceAndDurationVal] = useState({ distance: "", duration: "" });
  // const [duration, setDuration] = useState("");

  //trip Data
  const [tripData, setTripData] = useState({
    newBookingTable: { newBookingTableData: [], noOfRecords: 0 },
    acceptedTable: { acceptedTableData: [], noOfRecords: 0 },
    proceededTable: { proceededTableData: [], noOfRecords: 0 },
    appAllotedTable: { appAllotedTableData: [], noOfRecords: 0 },
    manualAllotedTable: { manualAllotedTableData: [], noOfRecords: 0 },
    completedTable: { completedTableData: [], noOfRecords: 0 },
    cancelledTable: { cancelledTableData: [], noOfRecords: 0 },
    fakedTable: { fakedTableData: [], noOfRecords: 0 }
  })

  const [distanceAndDuration, setDistanceAndDuration] = useState([
    { "distance": 20, "duration": 2 },
    { "distance": 30, "duration": 3 },
    { "distance": 40, "duration": 4 },
    { "distance": 50, "duration": 5 },
    { "distance": 60, "duration": 6 },
    { "distance": 70, "duration": 7 },
    { "distance": 80, "duration": 8 },
    { "distance": 90, "duration": 9 },
    { "distance": 100, "duration": 10 },
    { "distance": 110, "duration": 11 },
    { "distance": 120, "duration": 12 },
    { "distance": 150, "duration": 15 }])

  const [estimatedFare, setEstimatedFare] = useState({ state: true, data: {} });

  //Filters states
  const [rideId, setRideId] = useState("");
  const [tripTypeFilter, setTripTypeFilter] = useState("");
  const [cabType, setCabType] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");


  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState({})

  //Pagination details
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const autocompletePickupRef = useRef(null);
  const autocompleteDropRef = useRef(null);

  const [pickupLocationMapUrl, setPickupLocationMapUrl] = useState("");
  const [dropLocationMapUrl, setDropLocationMapUrl] = useState("");

  //google places api
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyBFGIMsW3amuqc-k8nhYQtLmnPHy7opdLg",
    libraries: libraries,
  });

  const AutoComplete_southIndiaBounds = {
    south: 7.0, // southern of Tamil Nadu
    west: 76.5, // western of Karnataka
    north: 19.5, // northern of Telangana
    east: 83.5, // eastern of Andhra Pradesh
  };
  const AutoComplete_restrictions = {
    country: 'in'
  };
  const AutoComplete_options = {
    strictBounds: true,
  };

  const { tripTypeData, setTripMode, vehicleTypeData, allVehicle, tripManagementActiveKey, setTripManagementActiveKey } = useContext(CommonContext);

  useEffect(() => {
    setTripTypeDropDown(tripTypeData)
  }, [tripTypeData]);

  useEffect(() => {
    setTripMode(tripType.id);
  }, [tripType]);

  useEffect(() => {
    setVehicleTypeDropDown(vehicleTypeData);
  }, [vehicleTypeData]);

  const getRides = async (tripStatus = 0, pageSize = 25, currentPage = 1, rideId, tripType, carType, fromDate, toDate) => {
    try {

      if (tripStatus === 0) {
        const rides = await getAllRide(tripStatus, pageSize, currentPage, rideId, tripType, carType, fromDate, toDate);
        setTripData((prevData) => ({
          ...prevData,
          newBookingTable: { newBookingTableData: [...rides.data], noOfRecords: rides.rideCount },
        }));
      }

      if (tripStatus === 1) {
        const rides = await getAllRide(tripStatus, pageSize, currentPage, rideId, tripType, carType, fromDate, toDate);
        setTripData((prevData) => ({
          ...prevData,
          acceptedTable: { acceptedTableData: [...rides.data], noOfRecords: rides.rideCount },
        }));
      }

      if (tripStatus === 2) {
        const rides = await getAllRide(tripStatus, pageSize, currentPage, rideId, tripType, carType, fromDate, toDate);
        setTripData((prevData) => ({
          ...prevData,
          proceededTable: { proceededTableData: [...rides.data], noOfRecords: rides.rideCount },
        }));
      }

      if (tripStatus === 3) {
        const rides = await getAllRide(tripStatus, pageSize, currentPage, rideId, tripType, carType, fromDate, toDate);
        setTripData((prevData) => ({
          ...prevData,
          appAllotedTable: { appAllotedTableData: [...rides.data], noOfRecords: rides.rideCount },
        }));
      }

      if (tripStatus === 4) {
        const rides = await getAllRide(tripStatus, pageSize, currentPage, rideId, tripType, carType, fromDate, toDate);
        setTripData((prevData) => ({
          ...prevData,
          manualAllotedTable: { manualAllotedTableData: [...rides.data], noOfRecords: rides.rideCount },
        }));
      }

      if (tripStatus === 5) {
        const rides = await getAllRide(tripStatus, pageSize, currentPage, rideId, tripType, carType, fromDate, toDate);
        setTripData((prevData) => ({
          ...prevData,
          completedTable: { completedTableData: [...rides.data], noOfRecords: rides.rideCount },
        }));
      }

      if (tripStatus === 6) {
        const rides = await getAllRide(tripStatus, pageSize, currentPage, rideId, tripType, carType, fromDate, toDate);
        setTripData((prevData) => ({
          ...prevData,
          cancelledTable: { cancelledTableData: [...rides.data], noOfRecords: rides.rideCount },
        }));
      }

      if (tripStatus === 7) {
        const rides = await getAllRide(tripStatus, pageSize, currentPage, rideId, tripType, carType, fromDate, toDate);
        setTripData((prevData) => ({
          ...prevData,
          fakedTable: { fakedTableData: [...rides.data], noOfRecords: rides.rideCount },
        }));
      }
    }
    catch (error) {
      message.error(error.message || error.response.data.message)
    }
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {

    getRides(getTripStatus(tripManagementActiveKey), rowsPerPage, page + 1, rideId, tripTypeFilter.id, cabType.id, fromDate, toDate)

  }, [rowsPerPage, page])


  function handleChangeTripTypeDropdown(item) {
    setTripType(item || { id: 1, tripName: "One Way" })
  }

  useEffect(() => {
    if (vehicleTypeDropDown) {
      setVehicleType(vehicleTypeDropDown.find(i => i.carType === "Sedan"));
    }
  }, [vehicleTypeDropDown])

  async function handleVerify() {
    let inputField = {
      mobileNumber,
      tripType
    }
    const errors = validateFields(inputField)
    setErrors(errors);

    if (Object.keys(errors).length === 0) {
      setLoading(true)
      try {
        const response = await getCustomerByMobileNumber(mobileNumber.split(' ').join(''));
        if (!response.status) {
          setCustomer((prev) => ({ ...prev, isVerified: response.status, isVerifiedMessage: true }));
          setLoading(false)
        }
        else {
          setCustomer((prev) => ({ ...prev, customerId: +response?.data?.customer?.id, isVerified: response.status, isVerifiedMessage: true }));
          setLoading(false)
        }
      }
      catch (error) {
        message.error(error.response.data.message || error.message || error.response.data.error);

        if (error.response.status === 404 && error.response.data.status === false) {
          setCustomer((prev) => ({ ...prev, isVerified: error.response.data.status, isVerifiedMessage: true }));
          setLoading(false)
        }
      }

    }
  }

  async function handleNext() {
    if (customer.isVerified && customer.isVerifiedMessage) {
      setAddTripModal1(false);
      setAddTripModal2(true);
      return;
    }
    let inputField = {
      customerUsername: customer.userName
    }
    const errors = validateFields(inputField);
    setErrors(errors);
    if (Object.keys(errors).length === 0) {
      if (!customer.isVerified) {
        setLoading(true)
        const customerPayload = {
          mobileNumber: mobileNumber.split(' ').join(''),
          userName: customer.userName,
          email: customer.email,
          userCreatedBy: 3 //Through admin dashboard
        }
        try {
          const response = await customerCreateByAdmin(customerPayload);
          if (response) {
            setLoading(false)
            message.success(response.message);
            setCustomer({
              isVerified: false,
              customerId: +response.data.id,
              userName: "",
              email: ""
            });
            setMobileNumber("");

            setAddTripModal1(false);
            setAddTripModal2(true);
          }

        }
        catch (error) {
          message.error(error.message || error.response.data.message || error.response.data.error);
        }
      }
    }
  }

  const addTripPopup1Content = () => {
    return (
      <>
        <div className=" w-full  mt-6 mb-10  ">

          <div className="w-full flex justify-center items-center gap-5">
            <MobileNumberInput
              label={"Customer Mobile Number"}
              value={mobileNumber}
              setValue={(item) => {
                setMobileNumber(item);
                // setCustomer((prev) => ({ ...prev, isVerified: false, isVerifiedMessage: true }));
                setCustomer({
                  isVerifiedMessage: false,
                  isVerified: false,
                  customerId: "",
                  userName: "",
                  email: ""
                })
              }}
              mandate={true}
              error={errors.mobileNumber}
            />
            <Button
              text={"Verify"}
              bgColor={"#fff"}
              textColor={"#1849A9"}
              borderColor={"#1849A9"}
              width={"80px"}
              height={"40px"}
              className={"mt-4 rounded-lg"}
              onClick={handleVerify}
            />
          </div>

          {
            customer.isVerifiedMessage && <div className="flex justify-center items-center mt-5 gap-1">
              <img src={customer.isVerified ? verified : notVerified} alt="" style={{ color: "green" }} />
              <p style={{ color: customer.isVerified ? "green" : "red", fontSize: '12px' }}>{customer.isVerified ? "Customer Verified, Please go ahead!" : "Customer Not Verified, Please update the below details"}</p>
            </div>
          }

          {
            !customer.isVerified && customer.isVerifiedMessage && <div className="flex justify-center gap-3 mt-4 flex-wrap">
              <Input
                label={"User Name"}
                placeholder={"Enter username"}
                value={customer.userName}
                onChange={(e) => setCustomer((prev) => ({ ...prev, userName: e.target.value }))}
                mandate={true}
                error={errors.customerUsername}
              />
              <Input
                label={"Email"}
                placeholder={"Enter email id"}
                value={customer.email}
                onChange={(e) => setCustomer(prev => ({ ...prev, email: e.target.value }))}
              // mandate={true}
              // error={errors.email}
              />

            </div>

          }

          {
            ((customer.isVerified) || (customer.isVerifiedMessage)) && <div className="flex justify-center gap-3 mt-4">
              <Button
                text={"Cancel"}
                bgColor={"#fff"}
                textColor={"#1849A9"}
                borderColor={"#1849A9"}
                width={"80px"}
                height={"40px"}
                className={"mt-4 rounded-lg"}
                onClick={() => {
                  setAddTripModal1(false); setCustomer({
                    isVerifiedMessage: false,
                    isVerified: false,
                    customerId: "",
                    userName: "",
                    email: ""
                  }); setMobileNumber("")
                }}
              />
              <Button
                text={"Next"}
                bgColor={"#1849A9"}
                textColor={"#fff"}
                width={"80px"}
                height={"40px"}
                className={"mt-4 rounded-lg"}
                onClick={handleNext}
              />
            </div>
          }
        </div>

      </>
    )
  }

  const addTripPopup2Content = () => {
    return (
      <>
        <div className=" w-full  mt-6 mb-10  ">
          <div className=" w-full text-start text-base mt-1 font-semibold text-gray-800 ">
            Create Trip
          </div>
          <div className=" w-full flex  flex-wrap  justify-center  lg:justify-normal gap-3 lg:gap-6 py-10">
            <DropDown
              label={"Trip type"}
              placeholder={"Select trip type"}
              displayValue={"tripName"}
              data={tripTypeDropDown}
              value={tripType}
              setValue={handleChangeTripTypeDropdown}
              mandate={true}
            />
            {
              tripType.tripName === "Rental" ? (<>
                <Autocomplete className="flex flex-col min-w-[200px]  max-w-[300px] w-full" onLoad={onLoadForPickup} onPlaceChanged={handlePickupLocationChange} restrictions={AutoComplete_restrictions} options={AutoComplete_options} bounds={AutoComplete_southIndiaBounds}>
                  <Input
                    label={"Pickup Location"}
                    placeholder={"Enter pickup location"}
                    value={fromLocation}
                    onChange={(e) => setFromLocation(e.target.value)}
                    onBlur={(e) => setFromLocation(e.target.value)}
                    mandate={true}
                    error={errors.fromLocation}
                  />
                </Autocomplete>
                <DateInput
                  label={"Pickup date"}
                  placeholder={"Select pickup date"}
                  value={tripDate}
                  setValue={setTripDate}
                  mandate={true}
                  error={errors.tripDate}
                />
                <DropDown
                  label={"Trip Time"}
                  placeholder={"Select trip time"}
                  displayValue={"label"}
                  data={tripTimeDropDownData}
                  value={tripTime}
                  setValue={setTripTime}
                  mandate={true}
                  error={errors.tripTime}
                />
                {/* <TimeInput
                  label={"Trip time"}
                  placeholder={"Select trip time"}
                  value={tripTime}
                  setValue={setTripTime}
                  mandate={true}
                  error={errors.tripTime}
                /> */}
                {/* <Input
                  label={"Distance"}
                  placeholder={"Enter distance"}
                  value={distance}
                  onChange={(e) => setDistance(e.target.value)}
                  mandate={true}
                  error={errors.distance}
                /> */}
                <DropDown
                  label={"Distance (in Km)"}
                  placeholder={"Select distance"}
                  displayValue={"distance"}
                  data={distanceAndDuration}
                  value={distanceAndDurationVal.distance}
                  setValue={(val) => setDistanceAndDurationVal({ ...distanceAndDurationVal, distance: val })}
                  mandate={true}
                  error={errors.distance}
                />
                <DropDown
                  label={"Duration (in Hrs)"}
                  placeholder={"Select duration"}
                  displayValue={"duration"}
                  data={distanceAndDuration}
                  value={distanceAndDurationVal.duration}
                  setValue={(val) => setDistanceAndDurationVal({ ...distanceAndDurationVal, duration: val })}
                  mandate={true}
                  error={errors.duration}
                />


              </>)
                : (<>
                  <Autocomplete className="flex flex-col min-w-[200px]  max-w-[300px] w-full" onLoad={onLoadForPickup} onPlaceChanged={handlePickupLocationChange} restrictions={AutoComplete_restrictions} options={AutoComplete_options} bounds={AutoComplete_southIndiaBounds} >
                    <Input
                      label={"From Location"}
                      placeholder={"Enter from location"}
                      value={fromLocation}
                      onChange={(e) => setFromLocation(e.target.value)}
                      onBlur={(e) => setFromLocation(e.target.value)}
                      mandate={true}
                      error={errors.fromLocation}
                    />

                  </Autocomplete>
                  <Autocomplete className="flex flex-col min-w-[200px]  max-w-[300px] w-full" onLoad={onLoadForDestination} onPlaceChanged={handleDropLocationChange} restrictions={AutoComplete_restrictions} options={AutoComplete_options} bounds={AutoComplete_southIndiaBounds}>
                    <Input
                      label={"To Location"}
                      placeholder={"Enter to location"}
                      value={toLocation}
                      onChange={(e) => setToLocation(e.target.value)}
                      onBlur={(e) => setToLocation(e.target.value)}
                      mandate={true}
                      error={errors.toLocation}
                    />
                  </Autocomplete>

                </>)
            }



            {((tripType.tripName === "One Way") || (tripType.tripName === "Local Drop")) && (
              <>
                <DateInput
                  label={"Trip date"}
                  placeholder={"Select trip date"}
                  value={tripDate}
                  setValue={setTripDate}
                  mandate={true}
                  error={errors.tripDate}
                />
                <DropDown
                  label={"Trip Time"}
                  placeholder={"Select trip time"}
                  displayValue={"label"}
                  data={tripTimeDropDownData}
                  value={tripTime}
                  setValue={setTripTime}
                  mandate={true}
                  error={errors.tripTime}
                />
                {/* <TimeInput
                  label={"Trip time"}
                  placeholder={"Select trip time"}
                  value={tripTime}
                  setValue={setTripTime}
                  mandate={true}
                  error={errors.tripTime}
                /> */}
              </>
            )}
            {tripType.tripName === "Round Way" && (
              <>
                <DateInput
                  label={"Pickup date"}
                  placeholder={"Select pickup date"}
                  value={tripDate}
                  setValue={setTripDate}
                  mandate={true}
                  error={errors.tripDate}
                />
                <DateInput
                  label={"Return date"}
                  placeholder={"Select return date"}
                  value={returnDate}
                  setValue={setReturnDate}
                  mandate={true}
                  error={errors.returnDate}
                />
                <DropDown
                  label={"Trip Time"}
                  placeholder={"Select trip time"}
                  displayValue={"label"}
                  data={tripTimeDropDownData}
                  value={tripTime}
                  setValue={setTripTime}
                  mandate={true}
                  error={errors.tripTime}
                />
                {/* <TimeInput
                  label={"Trip time"}
                  placeholder={"Select trip time"}
                  value={tripTime}
                  setValue={setTripTime}
                  mandate={true}
                  error={errors.tripTime}
                /> */}
              </>
            )}

            <DropDown
              label={"Vehicle type"}
              placeholder={"Select vehicle type"}
              displayValue={"carType"}
              data={vehicleTypeDropDown}
              value={vehicleType}
              setValue={setVehicleType}
              mandate={true}
              error={errors.vehicleType}
            />

            {/* <Input
              label={"Estimated Fare"}
              disabled={true}
              value={distance}
              placeholder={"Estimated fare"}
              mandate={true}
            /> */}
          </div>
          {
            +tripType.id === 3 ? !estimatedFare.state && Object.keys(estimatedFare.data).length !== 0 &&
              <div className="w-full flex  flex-wrap  gap-10 justify-center mb-12">
                <h1 className="text-gray-500">Distance <br /> <span className=" font-bold text-gray-700">{distanceAndDurationVal.distance.distance} KM</span></h1>
                <Divider type="vertical" style={{ height: "50px" }} />
                <h1 className=" text-gray-500">Duration <br /> <span className="font-bold text-gray-700">{distanceAndDurationVal.duration.duration} Hours</span></h1>
                <Divider type="vertical" style={{ height: "50px" }} />
                <h1 className="text-gray-500">Estimated Fare <br /> <span className=" font-bold text-green-500">₹ {estimatedFare.data.estimatedRateWithDriverFee}</span> </h1>
              </div> :

              (+tripType.id === 1) ?
                !estimatedFare.state && Object.keys(estimatedFare.data).length !== 0 &&
                <div className="w-full flex  flex-wrap  gap-10 justify-center mb-12">
                  <h1 className="text-gray-500">Distance <br /> <span className="font-bold text-gray-700">{estimatedFare.data.distanceInKm} KM</span></h1>
                  <Divider type="vertical" style={{ height: "50px" }} />
                  <h1 className=" text-gray-500">Estimated Fare <br /> <span className="font-bold text-green-500">₹ {estimatedFare.data.estimatedRateWithDriverFee}</span> </h1>
                  <h1 className=" text-gray-500">Base Fare <br /> <span className="font-bold">₹ {estimatedFare.data.estimatedRate}</span> </h1>
                  <h1 className=" text-gray-500">Driver Fee <br /> <span className="font-bold">₹ {estimatedFare.data.totalDriverFee}</span> </h1>
                </div> : (+tripType.id === 4) ?
                  !estimatedFare.state && Object.keys(estimatedFare.data).length !== 0 &&
                  <div className="w-full flex  flex-wrap  gap-10 justify-center mb-12">
                    <h1 className="text-gray-500">Distance <br /> <span className="font-bold text-gray-700">{estimatedFare.data.distanceInKm} KM</span></h1>
                    <Divider type="vertical" style={{ height: "50px" }} />
                    <h1 className=" text-gray-500">Estimated Fare <br /> <span className="font-bold text-green-500">₹ {estimatedFare.data.estimatedRateWithDriverFee}</span> </h1>
                  </div> :

                  !estimatedFare.state && Object.keys(estimatedFare.data).length !== 0 &&
                  <div className="w-full flex  flex-wrap  gap-10 justify-center mb-12">
                    <h1 className="text-gray-500">Distance <br /> <span className="font-bold text-gray-700">{estimatedFare.data.distanceInKm} KM</span></h1>
                    <Divider type="vertical" style={{ height: "50px" }} />
                    <h1 className=" text-gray-500">Estimated Fare <br /> <span className="font-bold text-green-500">₹ {estimatedFare.data.estimatedRateWithDriverFee}</span> </h1>
                    <h1 className=" text-gray-500">No of Days <br /> <span>{estimatedFare.data.days}</span> </h1>
                    <h1 className=" text-gray-500">Base Fare <br /> <span >₹ {estimatedFare.data.estimatedRate}</span> </h1>
                    <h1 className=" text-gray-500">Driver Fee <br /> <span>₹ {estimatedFare.data.totalDriverFee}</span> </h1>
                  </div>

          }
          <div className=" w-full flex  justify-center items-center">
            <Button
              text={estimatedFare.state && Object.keys(estimatedFare.data).length === 0 ? "Get Estimate" : "Book ride"}
              width={"auto"}
              loading={loading}
              onClick={estimatedFare.state && Object.keys(estimatedFare.data).length === 0 ? handleFareEstimate : handleTripSubmit}
            />
          </div>
        </div>
      </>
    );
  };

  useEffect(() => {
    const getRidesCount = async () => {
      try {
        const response = await getRidesCounts();
        setGetRidesCountResponse(response.data);
      }
      catch (error) {
        message.error(error.message || error.response.data.message)
      }
    }
    getRidesCount();
  }, [
    tripData
  ])

  const tabContents = [

    {
      key: "new-booking",
      label: (
        <LabelForTabs isActive={tripManagementActiveKey === "new-booking"}>
          New Booking ({getRidesCountResponse[0] ? getRidesCountResponse[0].rideStatus0 : 0})
        </LabelForTabs>
      ),
      children: <NewBookingTable tripTypeFilter={tripTypeFilter} setTripTypeFilter={setTripTypeFilter} cabType={cabType} setCabType={setCabType} fromDate={fromDate} setFromDate={setFromDate} toDate={toDate} setToDate={setToDate} tripTypeData={tripTypeDropDown} vehicles={allVehicle} tripData={tripData} getRides={getRides} tripStatus={0} rowsPerPage={rowsPerPage} page={page} handleChangePage={handleChangePage} handleChangeRowsPerPage={handleChangeRowsPerPage} handleRideIdSearch={handleRideIdSearch} rideId={rideId} handleTripTypeFilter={handleTripTypeFilter} handleCabTypeFilter={handleCabTypeFilter} handleFromDateFilter={handleFromDateFilter} handleToDateFilter={handleToDateFilter} rowCount={getRidesCountResponse[0]?.rideStatus0 || 0} />,
    },
    {
      key: "accepted",
      label: (
        <LabelForTabs isActive={tripManagementActiveKey === "accepted"}>
          Accepted ({getRidesCountResponse[1] ? getRidesCountResponse[1].rideStatus1 : 0})
        </LabelForTabs>
      ),
      children: <AcceptedTable tripTypeFilter={tripTypeFilter} setTripTypeFilter={setTripTypeFilter} cabType={cabType} setCabType={setCabType} fromDate={fromDate} setFromDate={setFromDate} toDate={toDate} setToDate={setToDate} tripTypeData={tripTypeDropDown} vehicles={allVehicle} tripData={tripData} getRides={getRides} tripStatus={1} rowsPerPage={rowsPerPage} page={page} handleChangePage={handleChangePage} handleChangeRowsPerPage={handleChangeRowsPerPage} handleRideIdSearch={handleRideIdSearch} rideId={rideId} handleTripTypeFilter={handleTripTypeFilter} handleCabTypeFilter={handleCabTypeFilter} handleFromDateFilter={handleFromDateFilter} handleToDateFilter={handleToDateFilter} rowCount={getRidesCountResponse[1]?.rideStatus1 || 0} />,
    },
    {
      key: "proceeded",
      label: (
        <LabelForTabs isActive={tripManagementActiveKey === "proceeded"}>
          Proceeded ({getRidesCountResponse[2] ? getRidesCountResponse[2].rideStatus2 : 0})
        </LabelForTabs>
      ),
      children: <ProceededTable tripTypeFilter={tripTypeFilter} setTripTypeFilter={setTripTypeFilter} cabType={cabType} setCabType={setCabType} fromDate={fromDate} setFromDate={setFromDate} toDate={toDate} setToDate={setToDate} tripTypeData={tripTypeDropDown} vehicles={allVehicle} tripData={tripData} getRides={getRides} tripStatus={2} rowsPerPage={rowsPerPage} page={page} handleChangePage={handleChangePage} handleChangeRowsPerPage={handleChangeRowsPerPage} handleRideIdSearch={handleRideIdSearch} rideId={rideId} handleTripTypeFilter={handleTripTypeFilter} handleCabTypeFilter={handleCabTypeFilter} handleFromDateFilter={handleFromDateFilter} handleToDateFilter={handleToDateFilter} rowCount={getRidesCountResponse[2]?.rideStatus2 || 0} />,
    },
    {
      key: "app-alloted",
      label: (
        <LabelForTabs isActive={tripManagementActiveKey === "app-alloted"}>
          App Alloted ({getRidesCountResponse[3] ? getRidesCountResponse[3].rideStatus3 : 0})
        </LabelForTabs>
      ),
      children: <AppAllotedTable tripTypeFilter={tripTypeFilter} setTripTypeFilter={setTripTypeFilter} cabType={cabType} setCabType={setCabType} fromDate={fromDate} setFromDate={setFromDate} toDate={toDate} setToDate={setToDate} tripTypeData={tripTypeDropDown} vehicles={allVehicle} tripData={tripData} getRides={getRides} tripStatus={3} rowsPerPage={rowsPerPage} page={page} handleChangePage={handleChangePage} handleChangeRowsPerPage={handleChangeRowsPerPage} handleRideIdSearch={handleRideIdSearch} rideId={rideId} handleTripTypeFilter={handleTripTypeFilter} handleCabTypeFilter={handleCabTypeFilter} handleFromDateFilter={handleFromDateFilter} handleToDateFilter={handleToDateFilter} rowCount={getRidesCountResponse[3]?.rideStatus3 || 0} />,
    },
    {
      key: "manual-alloted",
      label: (
        <LabelForTabs isActive={tripManagementActiveKey === "manual-alloted"}>
          Manual Alloted ({getRidesCountResponse[4] ? getRidesCountResponse[4].rideStatus4 : 0})
        </LabelForTabs>
      ),
      children: <ManualAllotedTable tripTypeFilter={tripTypeFilter} setTripTypeFilter={setTripTypeFilter} cabType={cabType} setCabType={setCabType} fromDate={fromDate} setFromDate={setFromDate} toDate={toDate} setToDate={setToDate} tripTypeData={tripTypeDropDown} vehicles={allVehicle} tripData={tripData} getRides={getRides} tripStatus={4} rowsPerPage={rowsPerPage} page={page} handleChangePage={handleChangePage} handleChangeRowsPerPage={handleChangeRowsPerPage} handleRideIdSearch={handleRideIdSearch} rideId={rideId} handleTripTypeFilter={handleTripTypeFilter} handleCabTypeFilter={handleCabTypeFilter} handleFromDateFilter={handleFromDateFilter} handleToDateFilter={handleToDateFilter} rowCount={getRidesCountResponse[4]?.rideStatus4 || 0} />,
    },
    // {
    //   key: "manual-not-paid",
    //   label: (
    //     <LabelForTabs isActive={tripManagementActiveKey === "manual-not-paid"}>
    //       M. Not Paid ({getRidesCountResponse[4] ? getRidesCountResponse[4].rideStatus4 : 0})
    //     </LabelForTabs>
    //   ),
    //   children: <ManualNotPaidTable tripTypeFilter={tripTypeFilter} setTripTypeFilter={setTripTypeFilter} cabType={cabType} setCabType={setCabType} fromDate={fromDate} setFromDate={setFromDate} toDate={toDate} setToDate={setToDate} tripTypeData={tripTypeDropDown} vehicles={allVehicle} tripData={tripData} getRides={getRides} tripStatus={4} rowsPerPage={rowsPerPage} page={page} handleChangePage={handleChangePage} handleChangeRowsPerPage={handleChangeRowsPerPage} handleRideIdSearch={handleRideIdSearch} rideId={rideId} handleTripTypeFilter={handleTripTypeFilter} handleCabTypeFilter={handleCabTypeFilter} handleFromDateFilter={handleFromDateFilter} handleToDateFilter={handleToDateFilter} rowCount={getRidesCountResponse[4]?.rideStatus4 || 0} />,
    // },
    {
      key: "completed",
      label: (
        <LabelForTabs isActive={tripManagementActiveKey === "completed"}>
          Completed ({getRidesCountResponse[5] ? getRidesCountResponse[5].rideStatus5 : 0})
        </LabelForTabs>
      ),
      children: <CompletedTable tripTypeFilter={tripTypeFilter} setTripTypeFilter={setTripTypeFilter} cabType={cabType} setCabType={setCabType} fromDate={fromDate} setFromDate={setFromDate} toDate={toDate} setToDate={setToDate} tripTypeData={tripTypeDropDown} vehicles={allVehicle} tripData={tripData} getRides={getRides} tripStatus={5} rowsPerPage={rowsPerPage} page={page} handleChangePage={handleChangePage} handleChangeRowsPerPage={handleChangeRowsPerPage} handleRideIdSearch={handleRideIdSearch} rideId={rideId} handleTripTypeFilter={handleTripTypeFilter} handleCabTypeFilter={handleCabTypeFilter} handleFromDateFilter={handleFromDateFilter} handleToDateFilter={handleToDateFilter} rowCount={getRidesCountResponse[5]?.rideStatus5 || 0} />,
    },
    {
      key: "cancelled",
      label: (
        <LabelForTabs isActive={tripManagementActiveKey === "cancelled"}>
          Cancelled ({getRidesCountResponse[6] ? getRidesCountResponse[6].rideStatus6 : 0})
        </LabelForTabs>
      ),
      children: <CancelledTable tripTypeFilter={tripTypeFilter} setTripTypeFilter={setTripTypeFilter} cabType={cabType} setCabType={setCabType} fromDate={fromDate} setFromDate={setFromDate} toDate={toDate} setToDate={setToDate} tripTypeData={tripTypeDropDown} vehicles={allVehicle} tripData={tripData} getRides={getRides} tripStatus={6} rowsPerPage={rowsPerPage} page={page} handleChangePage={handleChangePage} handleChangeRowsPerPage={handleChangeRowsPerPage} handleRideIdSearch={handleRideIdSearch} rideId={rideId} handleTripTypeFilter={handleTripTypeFilter} handleCabTypeFilter={handleCabTypeFilter} handleFromDateFilter={handleFromDateFilter} handleToDateFilter={handleToDateFilter} rowCount={getRidesCountResponse[6]?.rideStatus6 || 0} />,
    },
    {
      key: "faked",
      label: (
        <LabelForTabs isActive={tripManagementActiveKey === "faked"}>
          Faked ({getRidesCountResponse[7] ? getRidesCountResponse[7].rideStatus7 : 0})
        </LabelForTabs>
      ),
      children: <FakedTable tripTypeFilter={tripTypeFilter} setTripTypeFilter={setTripTypeFilter} cabType={cabType} setCabType={setCabType} fromDate={fromDate} setFromDate={setFromDate} toDate={toDate} setToDate={setToDate} tripTypeData={tripTypeDropDown} vehicles={allVehicle} tripData={tripData} getRides={getRides} tripStatus={7} rowsPerPage={rowsPerPage} page={page} handleChangePage={handleChangePage} handleChangeRowsPerPage={handleChangeRowsPerPage} handleRideIdSearch={handleRideIdSearch} rideId={rideId} handleTripTypeFilter={handleTripTypeFilter} handleCabTypeFilter={handleCabTypeFilter} handleFromDateFilter={handleFromDateFilter} handleToDateFilter={handleToDateFilter} rowCount={getRidesCountResponse[7]?.rideStatus7 || 0} />,
    },
  ];

  function resetForm(item) {
    setFromLocation("")
    setToLocation("")
    setTripDate("")
    setPickupDate("")
    setReturnDate("")
    setTripTime({ label: "", value: "" })
    setVehicleType({ id: 1, carType: 'Sedan' })
    setMobileNumber("")
    setDistanceAndDurationVal({
      distance: "", duration: ""
    })
    setErrors({})
    setTripType(item || { id: 1, tripName: "One Way" })
    setEstimatedFare({ state: true, data: {} })
    setFromDate("")
    setToDate("")
    setCustomer({
      isVerifiedMessage: false,
      isVerified: false,
      customerId: "",
      userName: "",
      email: ""
    })
  }


  async function handleFareEstimate() {

    let inputFields;
    if (+tripType.id === 1 || +tripType.id === 2) {
      inputFields = {
        fromLocation,
        toLocation,
        tripDate,
        tripTime: tripTime,
        vehicleType,
        returnDate,
        tripType
      }
    }

    else if (+tripType.id === 3) {
      inputFields = {
        fromLocation,
        tripTime: tripTime,
        distanceAndDurationVal,
        vehicleType,
        tripType,
        tripDate
      }

    }

    else {
      inputFields = {
        tripType,
        fromLocation,
        toLocation,
        tripDate,
        tripTime: tripTime,
        vehicleType,
      }
    }

    const errors = validateFields(inputFields);
    setErrors(errors);

    if (Object.keys(errors).length === 0) {

      // setEstimatedFare({ ...estimatedFare, state: false })
      // Valid form, proceed with submission
      setLoading(true)

      let estimatedFarePayload;

      if (+tripType.id === 1 || +tripType.id === 2) {
        estimatedFarePayload = {
          destinationLocation: toLocation || "",
          pickupLocation: fromLocation || "",
          // carType: parseInt(vehicleType.id) || "",
          tripMode: parseInt(tripType.id) || "",
          date: tripDate || "",
          rDate: returnDate || "",
          time: tripTime.value || "",
          rewardPoints: 0
        };
      }
      else if (+tripType.id === 3) {
        estimatedFarePayload = {
          pickupLocation: fromLocation || "",
          // carType: parseInt(vehicleType.id) || "",
          tripMode: parseInt(tripType.id) || "",
          date: tripDate || "",
          time: tripTime.value || "",
          rewardPoints: 0,
          rentalDistance: distanceAndDurationVal.distance.distance,
          rentalDuration: distanceAndDurationVal.duration.duration
        };
      }
      else {
        estimatedFarePayload = {
          destinationLocation: toLocation || "",
          pickupLocation: fromLocation || "",

          // carType: parseInt(vehicleType.id) || "",
          tripMode: parseInt(tripType.id) || "",
          date: tripDate,
          time: tripTime.value || "",
          rewardPoints: 0,
        }
      }


      try {
        //Getting the estimated fare details
        const response = await getEstimatedRideFare(estimatedFarePayload)
        const estimatedFareData = response.data;
        const filterByVehicleType = estimatedFareData.find(d => +d.carModeId === +vehicleType.id);
        setEstimatedFare({ state: false, data: { ...filterByVehicleType } })
        setLoading(false)
      }
      catch (error) {
        setLoading(false)
        console.log(error)
        message.error(error?.response?.data?.message || error?.response?.data?.error || error.message)
        setEstimatedFare({ state: true, data: {} })
      }

    }

  }

  async function handleTripSubmit() {
    setLoading(true)
    try {
      let createRidePayload;
      if (+tripType.id === 3) {
        createRidePayload = {
          "pickUpLocation": fromLocation,
          "dateOfRide": tripDate,
          "pickUpTime": tripTime.value,
          "carType": +vehicleType.id,
          "distanceInKm": distanceAndDurationVal.distance.distance,
          "rentalDuration": distanceAndDurationVal.duration.duration,
          "totalFare": estimatedFare.data.estimatedRateWithDriverFee,
          "tripStatus": 1,
          "tripMode": 3,
          "customerId": +customer.customerId,
          "bookingModeId": 3,
          farePerKm: estimatedFare.data.farePerKm,
          pickupLocationUrl: pickupLocationMapUrl,
          dropLocationUrl: dropLocationMapUrl,
        }
      }
      else if (+tripType.id === 4) {
        createRidePayload = {
          dropLocation: toLocation || "",
          pickUpLocation: fromLocation || "",
          carType: parseInt(vehicleType.id) || "",
          tripMode: parseInt(tripType.id) || "",
          dateOfRide: tripDate || "",
          pickUpTime: tripTime.value || "",
          distanceInKm: estimatedFare.data.distanceInKm,
          totalFare: estimatedFare.data.estimatedRateWithDriverFee,
          baseFare: estimatedFare.data.estimatedRate,
          driverFee: estimatedFare.data.totalDriverFee,
          customerId: +customer.customerId,
          bookingModeId: 3,
          farePerKm: estimatedFare.data.farePerKm,
          pickupLocationUrl: pickupLocationMapUrl,
          dropLocationUrl: dropLocationMapUrl,
        }
      }
      else {
        createRidePayload = {
          dropLocation: toLocation || "",
          pickUpLocation: fromLocation || "",
          carType: parseInt(vehicleType.id) || "",
          tripMode: parseInt(tripType.id) || "",
          dateOfRide: tripDate || "",
          rDateOfRide: returnDate || "0",
          pickUpTime: tripTime.value || "",
          distanceInKm: tripType.tripName === "One Way" ? estimatedFare.data.distanceInKm : estimatedFare.data.distance,
          totalFare: estimatedFare.data.estimatedRateWithDriverFee,
          baseFare: estimatedFare.data.estimatedRate,
          driverFee: estimatedFare.data.totalDriverFee,
          tripStatus: 1,
          customerId: +customer.customerId,
          bookingModeId: 3,
          farePerKm: estimatedFare.data.farePerKm,
          pickupLocationUrl: pickupLocationMapUrl,
          dropLocationUrl: dropLocationMapUrl,
        }
      }
      //Booking the new ride
      const createdRide = await bookRide(createRidePayload)
      getRides()
      setLoading(false)
      message.success("Ride created successfully!")
      setAddTripModal1(false)
      setAddTripModal2(false)
      resetForm()
    }
    catch (error) {
      console.log(error)
      setLoading(false)
      message.error(error.response.data.error || error.response.data.message || error.message)
    }
  }

  function handleRideIdSearch(rideId) {
    setRideId(rideId)
    getRides(getTripStatus(tripManagementActiveKey), rowsPerPage, page + 1, rideId, tripTypeFilter.id, cabType.id, fromDate, toDate)
  }

  function handleTripTypeFilter(value) {
    setTripTypeFilter(value)
  }

  function handleCabTypeFilter(value) {
    setCabType(value)
  }

  function handleFromDateFilter(value) {
    setFromDate(value)
  }

  function handleToDateFilter(value) {
    setToDate(value)
  }

  useEffect(() => {
    getRides(getTripStatus(tripManagementActiveKey), rowsPerPage, page + 1, rideId, tripTypeFilter.id, cabType.id, fromDate, toDate)
    setPage(0);
  }, [tripTypeFilter, cabType, fromDate, toDate, tripManagementActiveKey])

  useEffect(() => {
    setTripManagementActiveKey('new-booking')
  }, [])

  //reset estimate fare onchange of any input fields
  useEffect(() => {
    setEstimatedFare({ state: true, data: {} })
  }, [tripType, fromLocation, toLocation, tripDate, tripTime, vehicleType, returnDate, distanceAndDurationVal])

  function onLoadForPickup(autocomplete) {
    autocompletePickupRef.current = autocomplete;
  }
  const handlePickupLocationChange = () => {
    const autocomplete = autocompletePickupRef.current;
    if (autocomplete) {
      const place = autocomplete.getPlace();
      if (place) {
        const { place_id, url } = place;
        // const mapUrl = `https://www.google.com/maps/place/?q=place_id:${place_id}`;
        setPickupLocationMapUrl(url);
      }
    }
  };
  function onLoadForDestination(autocomplete) {
    autocompleteDropRef.current = autocomplete;
  }
  const handleDropLocationChange = () => {
    const autocomplete = autocompletePickupRef.current;
    if (autocomplete) {
      const place = autocomplete.getPlace();
      if (place) {
        const { place_id, url } = place;
        // const mapUrl = `https://www.google.com/maps/place/?q=place_id:${place_id}`;
        setDropLocationMapUrl(url);
      }
    }
  };

  if (!isLoaded) {
    return <></>
  }
  return (
    <>
      <PageLayout
        title={"Trip Management"}
        description={"Manage the trip details here"}
        buttonTitle={"Add Trip"}
        buttonWidth={"fit-content"}
        paddingRight={true}
        // BreadcrumbsUrl={BreadcrumbsUrl}
        setAddTripModal={setAddTripModal1}
      >
        <div className=" w-full flex md:mt-7">
          <TabNavigation
            tabContents={tabContents}
            activeKey={tripManagementActiveKey}
            setActiveKey={setTripManagementActiveKey}
            getRides={getRides}
            setRowsPerPage={setRowsPerPage}
            setPage={setPage}
          />
        </div>
      </PageLayout>
      <Modal
        title={
          <>
            <img src={addTrip} width={40} alt="" />
          </>
        }
        visible={addTripModal1}
        width={"1000px"}
        onCancel={() => {
          resetForm();
          setAddTripModal1(false); setCustomer({
            isVerifiedMessage: false,
            isVerified: false,
            customerId: "",
            userName: "",
            email: ""
          }); setMobileNumber("")
        }}
        footer={null}
      >
        {addTripPopup1Content()}
      </Modal>
      <Modal
        title={
          <>
            <img src={addTrip} width={40} alt="" />
          </>
        }
        visible={addTripModal2}
        width={"1000px"}
        onCancel={() => {
          setAddTripModal2(false); resetForm(); setCustomer({
            isVerifiedMessage: false,
            isVerified: false,
            customerId: "",
            userName: "",
            email: ""
          }); setMobileNumber("")
        }}
        footer={null}
      >
        {addTripPopup2Content()}
      </Modal>
    </>
  );
}
