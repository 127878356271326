import { BarChart } from '@mui/x-charts/BarChart';
import FilterLayout from '../../../components/layouts/FilterLayout';
import MultiSelectFilter from '../../../components/reusable/MultiSelectFilterForCheckbox';
import FilterDates from '../../../components/reusable/FilterDates';
import { message } from 'antd';
import { useState } from 'react';

export default function VehicleTypeSummary({
    data,
    filterDate,
    setFilterDate
}) {
    const [isFilterOpen, setIsFilterOpen] = useState(false);

    // const updateFilter = (filterName, value) => {
    //     setFilters((prevFilters) => ({
    //         ...prevFilters,
    //         [filterName]: value,
    //     }));
    // };

    const handleClearFilter = () => {
        message.success("cleared filters of Trip Summary Total");
        setFilterDate({
            firstDate: "",
            secondDate: "",
        })
    };

    const filterContent = [
        {
            title: "Date",
            body: (
                <div>
                    {" "}
                    <FilterDates
                        firstDateLabel={"Start Date"}
                        secondDateLabel={"End Date"}
                        date={filterDate}
                        setDate={setFilterDate}
                        isToDate
                        mandate
                    />{" "}
                </div>
            ),
        },
    ];

    const xLabels = [
        'SEDAN',
        'SUV',
        'SUV+',
        'MUV',
        'TEMPO',
    ];

    return (
        <>
            <div className='w-1/4 shadow-sm pr-4 py-6 pt-6  hidden lg:flex flex-col flex-auto 2xl:max-w-[55%] h-fit border rounded-md relative'>
                <div className="absolute m-4 border border-border-gray rounded-lg top-0 right-0">
                    <FilterLayout
                        isFilterOpen={isFilterOpen}
                        setIsFilterOpen={setIsFilterOpen}
                        content={filterContent}
                        handleClearFilter={handleClearFilter}
                    />
                </div>
                <p className="text-lg font-semibold w-fit pl-4">Vehicle Type Summary</p>
                <BarChart
                    // width={450}
                    height={300}
                    series={[
                        { data: data, label: 'Vechicle Type', id: 'totalData', color: '#52D3D8' },
                    ]}
                    xAxis={[{ data: xLabels, scaleType: 'band' }]}
                    slotProps={{
                        legend: { hidden: true },
                    }}
                />
                {/* <div className="flex gap-4 justify-center items-center">
                <div className="flex gap-2 justify-center items-center">
                    <div className="w-3 h-3 rounded-full bg-[#52D3D8]" />
                    <p>Total</p>
                </div>
                <div className="flex gap-2 justify-center items-center">
                    <div className="w-3 h-3 rounded-full bg-[#009EFF]" />
                    <p>Alotted</p>
                </div>
            </div> */}
            </div>
            <div className='w-1/4 shadow-lg pr-4 py-4  pt-6  flex lg:hidden flex-col flex-auto 2xl:max-w-[55%] h-fit border rounded-md relative'>
                <div className="absolute m-4 border border-border-gray rounded-lg top-0 right-0">
                    <FilterLayout
                        isFilterOpen={isFilterOpen}
                        setIsFilterOpen={setIsFilterOpen}
                        content={filterContent}
                        handleClearFilter={handleClearFilter}
                    />
                </div>
                <p className="text-lg font-semibold w-fit">Total Trip Summary</p>
                <BarChart
                    // width={450}
                    height={300}
                    series={[
                        { data: data, label: 'Total Trip', id: 'totalData', color: '#52D3D8' },
                    ]}
                    xAxis={[{ data: xLabels, scaleType: 'band' }]}
                    slotProps={{
                        legend: { hidden: true },
                    }}
                />
                {/* <div className="flex gap-4 justify-center items-center">
                <div className="flex gap-2 justify-center items-center">
                    <div className="w-3 h-3 rounded-full bg-[#52D3D8]" />
                    <p>Total</p>
                </div>
                <div className="flex gap-2 justify-center items-center">
                    <div className="w-3 h-3 rounded-full bg-[#009EFF]" />
                    <p>Alotted</p>
                </div>
            </div> */}
            </div>
        </>
    );
}
