import wallpaper from "../../../assets/login_wallpaper.svg"

export default function LeftSide() {
    return (
        <div className="flex flex-col gap-14 h-full justify-center items-center pb-12">
            <div className="flex justify-center w-full px-[6vw]">
                <img src={wallpaper} alt="wallpaper" className="w-full" />
            </div>
            <div className="text-white text-end font-semibold">
                <h1 className="text-5xl xl:text-6xl">SISYPHUS</h1>
                <p className="text-lg pt-1">Powered by TechHive</p>
            </div>
        </div>
    )
}
