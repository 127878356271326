import React, { useEffect, useState } from "react";
import { Typography, message } from "antd";
import edit from "../assets/edit_icon.svg";
import Input from "../../../components/reusable/Input";
import DateInput from "../../../components/reusable/DateInput";
import Button from "../../../components/reusable/Button";
import { updateEarnings, updateOccasionalFare } from "../../../services/FareManagementServices";
import DateUtils from "../../utils/DateUtils";


export default function EarningsComponent({
    id,
    earningType,
    vehicalImg,
    isEditActive,
    setIsEditActive,
    defaultPercentage,
    occasionalPercentage,
    fromDateFromApi,
    toDateFromApi,
    getEarningsDetails,
    earningsData
}) {

    const [isEditDefault, setIsEditDefault] = useState(false);
    const [isEditOccasional, setIsEditOccasional] = useState(false);
    const [percentage, setPercentage] = useState({
        default: { value: defaultPercentage, error: '' },
        occational: { value: occasionalPercentage, error: '' },
    });
    const [fromDate, setFromDate] = useState({
        value: fromDateFromApi,
        error: ''
    })
    const [toDate, setToDate] = useState({
        value: toDateFromApi,
        error: ''
    })
    const [btnLoading, setBtnLoading] = useState(false)



    function handleEditDefault() {
        if (isEditActive) return;
        setIsEditActive(true)
        console.log(earningType.toLowerCase().replace(/\s+/g, ''))
        const updatedIsEdit = {
            tripcashback: earningType.toLowerCase().replace(/\s+/g, '') == "tripcashback",
            signupreferral: earningType.toLowerCase().replace(/\s+/g, '') == "signupreferral",
            tripreferral: earningType.toLowerCase().replace(/\s+/g, '') == "tripreferral"

        };
        setIsEditDefault(updatedIsEdit);
    }
    function handleEditOccasional() {
        if (isEditActive) return;
        setIsEditActive(true)
        const updatedIsEdit = {
            tripcashback: earningType.toLowerCase().replace(/\s+/g, '') === "tripcashback",
            signupreferral: earningType.toLowerCase().replace(/\s+/g, '') === "signupreferral",
            tripreferral: earningType.toLowerCase().replace(/\s+/g, '') === "tripreferral"
        };
        setIsEditOccasional(updatedIsEdit);
    }

    function handleCancel() {
        setPercentage(prevPercentage => ({
            ...prevPercentage,
            default: {
                value: defaultPercentage,
                error: ''
            }
        }));
        setPercentage(prevPercentage => ({
            ...prevPercentage,
            occational: {
                value: occasionalPercentage,
                error: ''
            }
        }));
        setFromDate({
            value: fromDateFromApi,
            error: ''
        })
        setToDate({
            value: toDateFromApi,
            error: ''
        })
        const updatedIsEdit = {
            tripcashback: false,
            signupreferral: false,
            tripreferral: false
        };
        setIsEditDefault(updatedIsEdit);
        setIsEditOccasional(updatedIsEdit);
        setIsEditActive(false)
    }

    const Valid = (section) => {
        let isValid = true;
        if (section === "default") {
            if (!percentage.default.value || percentage.default.value < 0) {
                setPercentage(prevPercentage => ({
                    ...prevPercentage,
                    default: {
                        ...prevPercentage.default,
                        error: 'This field is required!'
                    }
                }));
                isValid = false;
            } else {
                setPercentage(prevPercentage => ({
                    ...prevPercentage,
                    default: {
                        ...prevPercentage.default,
                        error: ''
                    }
                }));
            }
        }
        if (section === "occasional") {
            if (!percentage.occational.value || percentage.occational.value < 0) {
                setPercentage(prevPercentage => ({
                    ...prevPercentage,
                    occational: {
                        ...prevPercentage.occational,
                        error: 'This field is required!'
                    }
                }));
                isValid = false;
            } else {
                setPercentage(prevPercentage => ({
                    ...prevPercentage,
                    occational: {
                        ...prevPercentage.occational,
                        error: ''
                    }
                }));
            }
            if (!fromDate.value && section === "occasional") {
                setFromDate(prev => ({ ...prev, error: 'From date is required!' }));
                isValid = false;
            } else {
                setFromDate(prev => ({ ...prev, error: '' }));
            }
            if (!toDate.value && section === "occasional") {
                setToDate(prev => ({ ...prev, error: 'To date is required!' }));
                isValid = false;
            } else {
                setToDate(prev => ({ ...prev, error: '' }));
            }
            if (!DateUtils.compareFromAndToDate(fromDate.value, toDate.value)) {
                message.error('From Date should be less than To Date!')
                isValid = false;
            } else {
                isValid = true;
            }
        }
        return isValid;
    };


    async function handleUpdate(section) {
        if (Valid(section)) {
            setBtnLoading(true)
            const updatedData = {
                fromDate: fromDate.value,
                toDate: toDate.value,
                occasionalFare: percentage.occational.value,
                defaultFare: percentage.default.value
            }
            try {
                const response = await updateEarnings(id, updatedData);
                if (response) {
                    message.success('Earnings updated succesfully');
                    getEarningsDetails();
                    setIsEditDefault({
                        tripcashback: false,
                        signupreferral: false,
                        tripreferral: false
                    })
                    setIsEditOccasional({
                        tripcashback: false,
                        signupreferral: false,
                        tripreferral: false
                    })
                    setBtnLoading(false)
                    setIsEditActive(false)
                }
            } catch (error) {
                setBtnLoading(false)
                message.error(error.response.data.message || error.response.data.error || error.message)
            }
        }

    }

    const handlePercentageChange = (e, type) => {
        const value = e.target.value.trim(); // Trim whitespace
        console.log(/^\d*\.?\d*$/.test(value))

        // Regex to allow non-negative numbers with optional decimal
        if (/^\d*\.?\d*$/.test(value)) {
            console.log(type)
            if (type === 'default') {
                console.log('default')
                setPercentage(prevPercentage => ({
                    ...prevPercentage,
                    default: {
                        value: value,
                        error: ''
                    }
                }));
            }
            else {
                setPercentage(prevPercentage => ({
                    ...prevPercentage,
                    occational: {
                        value: value,
                        error: ''
                    }
                }));
            }
        }
    };



    return (
        <>
            <div className="flex md:flex-row flex-col justify-between w-full border rounded-md border-gray-200 md:p-10">
                {/* Car Card Section */}
                <section className="w-full flex flex-col items-center justify-between gap-5  p-3 border-b md:border-b-0">
                    <h1 className="text-center font-semibold" style={{ marginBottom: "0px", fontSize: "24px" }}>
                        {earningType}
                    </h1>
                    <img src={vehicalImg} alt={earningType} className="w-fit max-h-[200px]" style={{ margin: "auto" }} />
                </section>
                {/* Default fare section */}
                <section className="w-full flex flex-col items-center gap-5 p-3 border-l border-b md:border-b-0">
                    <div className="flex justify-between w-full px-4">
                        <h3 className="text-center font-semibold text-lg" >Default Percentage</h3>
                        <button className={`${isEditActive ? "cursor-not-allowed" : ""}`}>
                            <img src={edit} alt="edit" onClick={handleEditDefault} />
                        </button>
                    </div>
                    <div className="flex flex-col gap-4 mt-4 w-full justify-center items-center">
                        
                        <Input
                            label={`${earningType} ${earningType !== "Signup Referral" ? "%" : ""}`}
                            type={"text"}
                            mandate
                            value={percentage.default.value}
                            onChange={(e) => handlePercentageChange(e, 'default')}
                            error={percentage.default.error}
                            disabled={!isEditDefault[earningType.toLowerCase().replace(/\s+/g, '')]}
                            amount={earningType === "Signup Referral"}
                            
                        />
                        {
                            isEditDefault[earningType.toLowerCase().replace(/\s+/g, '')] && (<div className="w-full pt-4">
                                <div className="flex flex-wrap p-6 pt-0 gap-3 items-center justify-center">
                                    <Button text={"Cancel"} bgColor={"#C4320A"} textColor={"#FFFFFF"} width={"150px"} onClick={handleCancel} />
                                    <Button loading={btnLoading} text={"Update"} bgColor={"#1849A9"} textColor={"#FFFFFF"} width={"150px"} onClick={() => handleUpdate("default")} />
                                </div>
                            </div>)
                        }

                    </div>
                </section>
                {/* Occasional fare section */}
                <section className="w-full flex flex-col items-center gap-9 p-3 border-l">
                    <div className="flex justify-between w-full px-4">
                        <h3 className="text-center font-semibold text-lg">Occasional Percentage</h3>
                        <button
                            className={`${isEditActive ? "cursor-not-allowed" : ""}`}
                            onClick={handleEditOccasional}
                        >
                            <img src={edit} alt="edit" />
                        </button>
                    </div>
                    <div className="flex flex-col gap-4 w-full justify-center items-center">

                        <Input
                            label={`Occasional ${earningType} ${earningType !== "Signup Referral" ? "%" : ""}`}
                            type={"text"}
                            mandate
                            value={percentage.occational.value}
                            onChange={(e) => handlePercentageChange(e, 'occasional')}
                            error={percentage.occational.error}
                            disabled={!isEditOccasional[earningType.toLowerCase().replace(/\s+/g, '')]}
                            width={'100%'}
                            amount={earningType === "Signup Referral"}
                        />

                        <div className="flex flex-col lg:flex-row pt-0 gap-1 md:gap-3 mt-1 items-center justify-center w-full md:w-[150px]">
                            <DateInput
                                label={"From date"}
                                mandate
                                disabled={!isEditOccasional[earningType.toLowerCase().replace(/\s+/g, '')]}
                                value={fromDate.value}
                                setValue={(item) => setFromDate(prev => ({ ...prev, value: item }))}
                                error={fromDate.error}
                                disableMinWidth
                                placeholder={'Select From date'}
                            />
                            <DateInput
                                label={"To date"}
                                mandate
                                disabled={!isEditOccasional[earningType.toLowerCase().replace(/\s+/g, '')]}
                                value={toDate.value}
                                setValue={(item) => setToDate(prev => ({ ...prev, value: item }))}
                                error={toDate.error}
                                disableMinWidth
                                placeholder={'Select To date'}
                            />
                        </div>
                        {
                            isEditOccasional[earningType.toLowerCase().replace(/\s+/g, '')] && (
                                <div className="w-full pt-4">
                                    <div className="flex flex-wrap p-6 pt-0 gap-3 items-center justify-center">
                                        <Button text={"Cancel"} bgColor={"#C4320A"} textColor={"#FFFFFF"} width={"150px"} onClick={handleCancel} />
                                        <Button loading={btnLoading} text={"Update"} bgColor={"#1849A9"} textColor={"#FFFFFF"} width={"150px"} onClick={() => handleUpdate("occasional")} />
                                    </div>
                                </div>
                            )
                        }

                    </div>
                </section>
            </div>
        </>
    );
}
