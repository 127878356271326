import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Modal, Radio, message } from "antd";
import { Breadcrumbs } from "@mui/material";
import { Typography } from "antd";
import addTrip from "../trip-management/assets/add-trip.png";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import DropDown from "../../components/reusable/DropDown";
import Button from "../../components/reusable/Button";
import Input from "../../components/reusable/Input";
import PageLayout from "../../components/layouts/PageLayout";
import MobileNumberInput from "../../components/reusable/MobileNumberInput";
import DateInput from "../../components/reusable/DateInput";
import TabNavigation, { LabelForTabs } from "../../components/reusable/TabNavigation";
// import CreditedWallet from "../../components/tables/wallet-management/CreditedWallet";
import DebitedWallet from "../../components/tables/wallet-management/DebitedWallet";
import { createCreditsApi, createDebitsApi, getWalletCount, getWalletDeductionAmountByTripId, getWalletDetailsApi } from "../../services/WalletServices";
import CommonContext from "../../context-storage/CommonServicesHandler";
import { getRidesIdDropDown, getTripById } from "../../services/TripManagementServices";
import { getPaymentMode, getAllDriversForDropdown } from "../../services/CommonServices";
import DropDownSelect from "../../components/reusable/DropDownSelect";
import Table from "../../components/reusable/Table/Table";
import TableHead from "../../components/reusable/Table/TableHead";
import TableCell from "../../components/reusable/Table/TableCell";
import Select from "react-select";
import SummaryWallet from "../../components/tables/wallet-management/SummaryWallet";
import Mandate from "../../components/reusable/Mandate";
export default function WalletManagement() {

  const [userType, setUserType] = useState("");

  useEffect(() => {
    setUserType(localStorage.getItem("CC_ADMIN_USERTYPE"));
  }, []);

  // filters
  const [search, setSearch] = useState('');
  const [debitType, setDebitType] = useState(2);
  const [completionMode, setCompletionMode] = useState(0);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  // modals
  const [debitModal, setDebitModal] = useState(false);
  const [creditModal, setCreditModal] = useState(false);
  // modal inputs
  const [walletId, setWalletId] = useState('');
  const [driverId, setDriverId] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [creditAmount, setCreditAmount] = useState('');
  const [transactionMode, setTransactionMode] = useState('');
  const [description, setDescription] = useState('');
  const [tripType, setTripType] = useState('');
  const [tripId, setTripId] = useState('');
  const [tripDate, setTripDate] = useState('');
  const [fromLocation, setFromLocation] = useState('');
  const [toLocation, setToLocation] = useState('');
  const [walletIdError, setWalletIdError] = useState('');
  const [tripTypeError, setTripTypeError] = useState('');
  const [tripIdError, setTripIdError] = useState('');
  const [tripDateError, setTripDateError] = useState('');
  const [fromLocationError, setFromLocationError] = useState('');
  const [toLocationError, setToLocationError] = useState('');
  const [driverIdError, setDriverIdError] = useState('');
  const [creditAmountError, setCreditAmountError] = useState('');
  const [transactionModeError, setTransactionModeError] = useState('');
  const [isDriverTable, setIsDriverTable] = useState(false);
  // common
  const [activeKey, setActiveKey] = useState("debited");
  const [walletData, setWalletData] = useState([]);
  const [tripIdData, setTripIdData] = useState([]);
  const [driverDetails, setDriverDetails] = useState([]);
  const [tripDetails, setTripDetails] = useState([]);
  const [transactionModeDetails, setTransactionModeDetails] = useState([]);

  //Pagination details
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const { allDriver, getDriverOne } = useContext(CommonContext);
  // need to refetch drivers list, so not using context values
  const [driversListForDropDown, setDriversListForDropDown] = useState([]);
  const [driverSearch, setDriverSearch] = useState("");
  const [tripSearch, setTripSearch] = useState("");

  const [searchFilter, setSearchFilter] = useState("trip")
  const [driverDebit, setDriverDebit] = useState("");

  const [count, setCount] = useState({
    debitCount: 0,
    creditCount: 0
  })

  function handleFilterChange(e) {
    setDriverDetails([]);
    setTripDetails([]);
    setWalletId("");
    setDriverDebit("");
    setTripId("");
    setFromLocation("");
    setToLocation("");
    setTripDate("");
    setIsDriverTable(false);
    setDriverId("");
    setSearchFilter(e.target.value)
  }

  const fetchData = async () => {
    const params = {
      id: activeKey === "credited" ? 1 : 2,
      searchQuery: search,
      debitType: activeKey === "credited" ? "" : debitType?.value,
      endFrom: activeKey === "credited" ? "" : completionMode?.value,
      fromDate: fromDate,
      toDate: toDate,
    }
    try {
      const responseData = await getWalletDetailsApi(params);
      setWalletData(responseData.data);
    }
    catch (error) {
      message.error(error.response.data.message || error.response.data.error || error.message)
    }
  };

  useEffect(() => {
    fetchData();
    setPage(0);
  }, [activeKey, search, debitType, completionMode, fromDate, toDate]);

  const getDriverDetails = async () => {
    try {
      if (driverId?.value) {
        const driver = await getDriverOne(parseInt(driverId?.value));
        setDriverDetails([driver]);
        setWalletId(driver.walletID);
      }
    }
    catch (error) {
      message.error(error.response.data.message || error.response.data.error || error.message)
    }
  }

  useEffect(() => {
    getDriverDetails();
  }, [driverId]);

  useEffect(() => {
    const getDriverDetails = async () => {
      if (tripId?.value) {
        try {
          const response = await getTripById(parseInt(tripId?.value));
          const trip = response.data;
          setTripDetails([trip]);
          setWalletId(trip?.driver?.walletID);
          setFromLocation(trip?.pickUpLocation);
          setToLocation(trip?.dropLocation);
          setTripDate(trip?.dateOfRide);
          if (trip) {
            const amount = await getWalletDeductionAmountByTripId(parseInt(tripId?.value));
            setCreditAmount(amount.amount);
            setDescription("Amount Debit for Trip ID: " + tripId?.label)
          }
        } catch (err) {
          message.error(err.response.data.message || err.response.data.error || err.message)
        }
      }
    }
    const getDriverDetails2 = async () => {
      if (driverDebit?.value) {
        const response = await getDriverOne(parseInt(driverDebit?.value));
        setWalletId(response?.walletID);
      }
    }
    getDriverDetails();
    getDriverDetails2();
  }, [tripId, driverDebit]);

  useEffect(() => {
    const getAllRideIds = async () => {
      const data = await getRidesIdDropDown();
      const formattedData = data.data.map(item => {
        return {
          label: item.rideId,
          value: item.id
        }
      })
      setTripIdData(formattedData);
    }
    getAllRideIds();
    // }, [tripSearch]);
  }, []);

  useEffect(() => {
    async function fetchData() {
      const drivers = await getAllDriversForDropdown(driverSearch);
      setDriversListForDropDown(drivers.data);
    }
    fetchData();
    // }, [driverSearch]);
  }, []);

  useEffect(() => {
    const transactionMode = async () => {
      const data = await getPaymentMode();
      setTransactionModeDetails(data.data);
    }
    transactionMode();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const columnsForDriverTable = [
    {
      id: "driverId",
      name: <TableHead>Driver ID</TableHead>,
      cell: (row) => (
        <TableCell>
          {row?.driverID}
        </TableCell>
      ),
    },
    {
      id: "walletBalance",
      name: <TableHead>Wallet Balance</TableHead>,
      cell: (row) => (
        <TableCell>
          ₹ {row?.walletAvailableBalace?.toLocaleString('hi')}
        </TableCell>
      ),
    },
    {
      id: "driverName",
      name: <TableHead>Driver Name</TableHead>,
      cell: (row) => (
        <TableCell>
          {row?.driverName}
        </TableCell>
      ),
    },
    {
      id: "mobileNumber",
      name: <TableHead>Mobile Number</TableHead>,
      cell: (row) => <TableCell>{row?.mobileNo}</TableCell>,
    },
    {
      id: "vehicleType",
      name: <TableHead>Vehicle Type</TableHead>,
      cell: (row) => <TableCell>{row?.vehicleType.name}</TableCell>,
    },
    {
      id: "vehicleName",
      name: <TableHead>Vehicle Name</TableHead>,
      cell: (row) => <TableCell>{row?.vehicleName}</TableCell>,
    },
    {
      id: "vehicleNumber",
      name: <TableHead>Vehicle Number</TableHead>,
      cell: (row) => <TableCell>{row?.vehicleNo}</TableCell>,
    },
  ];

  function handleClick(event) {
    event.preventDefault();
    console.info("You clicked a breadcrumb.");
  };

  const BreadcrumbsUrl = () => (
    <>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        <Link color="inherit" href="/" onClick={handleClick}>
          Trip Management
        </Link>
        <Link
          color="inherit"
          href="/getting-started/installation/"
          onClick={handleClick}
        >
          Not Allotted
        </Link>
        <Typography color="text.primary">LC007</Typography>
      </Breadcrumbs>
    </>
  );

  function handleSearchOnClear() {
    setDriverDetails([]);
    setDriverId({ id: "", driverID: "" });
    setWalletId("");
    setTripDetails([]);
    setFromLocation("");
    setToLocation("");
    setTripDate("");
  };

  const AddCreditModalContent = () => {
    return (
      <>
        <form className=" w-full  mt-6 mb-10  ">
          <div className=" w-full text-start text-lg mt-1 font-semibold text-gray-800 ">
            Add Credit
          </div>
          {/* for desktop */}
          <div className="hidden w-full md:flex flex-col  gap-3 lg:gap-6 py-5 md:py-10">
            <div className=" w-full flex flex-wrap lg:flex-nowrap justify-between items-center gap-3 lg:gap-0">
              {/* <DropDownSelect
                label={"Driver ID"}
                mandate
                placeholder={"Select Driver ID"}
                displayValue={"driverID"}
                data={driversListForDropDown}
                value={driverId.driverID}
                setValue={setDriverId}
                onClear={handleSearchOnClear}
                width={"250px"}
              /> */}
              <div className="flex flex-col">
                <p className="text-[#344054] text-[12px] pl-1 font-medium">Select Driver <span className="text-[#D92D20]">*</span></p>
                <Select
                  options={driversListForDropDown}
                  onChange={(e) => setDriverId(e)}
                  value={driverId}
                  placeholder={"Select Driver ID"}
                  className="min-w-[200px] w-[250px]"
                  onInputChange={(e) => setDriverSearch(e)}
                />
              </div>
              <Input
                label={"Wallet ID"}
                placeholder={"-"}
                value={walletId}
                // onChange={(e) => setWalletId(e.target.value)}
                mandate={true}
                error={walletIdError}
                width={"200px"}
                disabled
              />
              <DropDown
                label={"Transaction Mode"}
                placeholder={"Select transaction mode"}
                displayValue={"name"}
                data={transactionModeDetails}
                value={transactionMode}
                setValue={setTransactionMode}
                error={tripIdError}
                width={"200px"}
                mandate
              />
              <Input
                label={"Credit Amount"}
                placeholder={"Enter credit amount"}
                type={"number"}
                value={creditAmount}
                onChange={(e) => setCreditAmount(e.target.value)}
                mandate={true}
                error={creditAmountError}
                width={"200px"}
                amount
              />
            </div>
            <div className=" w-full flex flex-wrap lg:flex-nowrap justify-b items-center gap-3 lg:gap-10 ">
            </div>
            {driverDetails?.length > 0 && (
              <Table
                columns={columnsForDriverTable}
                rows={driverDetails}
                noNeedPagination={true}
              />
            )}
            <div className="w-full flex-col flex flex-wrap lg:flex-nowrap items-start  ">
              <div className="h-fit flex ">
                <label
                  className="text-[#344054] text-[13px] pl-1 pb-1 font-medium"
                  htmlFor="description"
                >
                  Description
                </label>
              </div>
              <textarea
                className={`w-full h-20 p-2 border border-gray-300 outline-none focus:border-primary-blue rounded-md`}
                placeholder="Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              ></textarea>
            </div>
          </div>
          {/* for mobile */}
          <div className=" w-full flex md:hidden flex-col  gap-3 lg:gap-6 py-5 md:py-10">
            <div className=" w-full flex flex-wrap lg:flex-nowrap justify-between items-center gap-3 lg:gap-0">
              {/* <DropDownSelect
                label={"Driver ID"}
                mandate
                placeholder={"Select Driver ID"}
                displayValue={"driverID"}
                data={driversListForDropDown}
                value={driverId.driverID}
                setValue={setDriverId}
                onClear={handleSearchOnClear}
                width={"250px"}
              /> */}
              <div className="flex flex-col">
                <p className="text-[#344054] text-[12px] pl-1 font-medium">Select Driver <span className="text-[#D92D20]">*</span></p>
                <Select
                  options={driversListForDropDown}
                  onChange={(e) => setDriverId(e)}
                  value={driverId}
                  placeholder={"Select Driver ID"}
                  className="min-w-[200px] w-[300px]"
                  onInputChange={(e) => setDriverSearch(e)}
                />
              </div>
              <Input
                label={"Wallet ID"}
                placeholder={"-"}
                value={walletId}
                // onChange={(e) => setWalletId(e.target.value)}
                mandate={true}
                error={walletIdError}
                width={"300px"}
                disabled
              />
              <DropDown
                label={"Transaction Mode"}
                placeholder={"Select transaction mode"}
                displayValue={"name"}
                data={transactionModeDetails}
                value={transactionMode}
                setValue={setTransactionMode}
                error={tripIdError}
                width={"300px"}
                mandate
              />
              <Input
                label={"Credit Amount"}
                placeholder={"Enter credit amount"}
                type={"number"}
                value={creditAmount}
                onChange={(e) => setCreditAmount(e.target.value)}
                mandate={true}
                error={creditAmountError}
                width={"300px"}
                amount
              />
            </div>
            <div className=" w-full flex flex-wrap lg:flex-nowrap justify-b items-center gap-3 lg:gap-10 ">
            </div>
            {driverDetails?.length > 0 && (
              <Table
                columns={columnsForDriverTable}
                rows={driverDetails}
                noNeedPagination={true}
              />
            )}
            <div className="w-full flex-col flex flex-wrap lg:flex-nowrap items-start  ">
              <div className="h-fit flex ">
                <label
                  className="text-[#344054] text-[13px] pl-1 pb-1 font-medium"
                  htmlFor="description"
                >
                  Description
                </label>
              </div>
              <textarea
                className={`w-full h-20 p-2 border border-gray-300 outline-none focus:border-primary-blue rounded-md`}
                placeholder="Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              ></textarea>
            </div>
          </div>
          <div className=" w-full flex  justify-center items-center">
            <Button
              text={"Credit Money"}
              width={"170px"}
              onClick={() => {
                createCredits();
              }}
            />
          </div>
        </form>
      </>
    );
  };

  const AddDebitModalContent = () => {
    return (
      <>
        <form className=" w-full  mt-6 mb-4">
          <Radio.Group className="pr-4 justify-end flex" value={searchFilter} size="medium" onChange={handleFilterChange}>
            <Radio.Button value="trip" style={{ fontSize: '12px' }}>Trip Debit</Radio.Button>
            <Radio.Button value="manual" style={{ fontSize: '12px' }}>Manual Debit</Radio.Button>
            {/* <Radio.Button value="mobileNumber" style={{ fontSize: '12px' }}>Search by Mobile Number</Radio.Button> */}
          </Radio.Group>
          <div className=" w-full text-start text-lg mt-1 font-semibold text-gray-800 ">
            Debit Money
          </div>
          <div className=" w-full flex flex-col  gap-3 lg:gap-6 md:py-10">
            <div className=" w-full flex flex-col  gap-3 lg:gap-6 pt-5 pb-2">
              <div className=" w-full flex flex-wrap lg:flex-nowrap justify-start items-center gap-3 lg:gap-10">
                {/* <DropDownSelect
                  label={"Trip ID"}
                  placeholder={"Select Trip ID"}
                  displayValue={"rideId"}
                  data={tripIdData}
                  value={tripId?.rideId}
                  setValue={setTripId}
                  onClear={handleSearchOnClear}
                  error={tripIdError}
                  mandate
                /> */}
                {searchFilter === "trip" ?
                  <div className="flex flex-col">
                    <p className="text-[#344054] text-[12px] pl-1 font-medium">Trip ID <span className="text-[#D92D20]">*</span></p>
                    <Select
                      options={tripIdData}
                      onChange={(e) => setTripId(e)}
                      value={tripId}
                      placeholder={"Select Trip ID"}
                      className="min-w-[200px] w-[250px]"
                      onInputChange={(e) => setTripSearch(e)}
                    />
                  </div>
                  :
                  <div className="flex flex-col">
                    <p className="text-[#344054] text-[12px] pl-1 font-medium">Driver<span className="text-[#D92D20]">*</span></p>
                    <Select
                      options={allDriver}
                      onChange={(e) => { setDriverId(e); setIsDriverTable(true) }}
                      value={driverId}
                      placeholder={"Select Driver"}
                      className="min-w-[200px] w-[250px]"
                    // onInputChange={(e) => setDriverDebit(e)}
                    />
                  </div>
                }
                <Input
                  label={"Wallet ID"}
                  placeholder={"-"}
                  value={walletId}
                  // onChange={(e) => setWalletId(e.target.value)}
                  mandate={true}
                  error={walletIdError}
                  width={"250px"}
                  disabled
                />
                <Input
                  label={"Debit Amount"}
                  placeholder={"Enter debit amount"}
                  type={"number"}
                  value={creditAmount}
                  onChange={(e) => setCreditAmount(e.target.value)}
                  mandate={true}
                  error={creditAmountError}
                  width={"250px"}
                />
              </div>
              {searchFilter === "trip" &&
                <div className=" w-full flex flex-wrap lg:flex-nowrap justify-start items-center gap-3 lg:gap-10 ">
                  <DateInput
                    label={"Trip Date"}
                    placeholder={"-"}
                    value={tripDate}
                    // setValue={setTripDate}
                    mandate={true}
                    error={tripDateError}
                    width={"250px"}
                    disabled
                  />
                  <Input
                    label={"From Location"}
                    placeholder={"-"}
                    value={fromLocation}
                    // onChange={(e) => setFromLocation(e.target.value)}
                    mandate={true}
                    error={fromLocationError}
                    width={"250px"}
                    disabled
                  />
                  <Input
                    label={"To Location"}
                    placeholder={"-"}
                    value={toLocation}
                    // onChange={(e) => setToLocation(e.target.value)}
                    mandate={true}
                    error={toLocationError}
                    width={"250px"}
                    disabled
                  />
                </div>
              }
              {tripDetails?.length > 0 && (
                <Table
                  columns={columnsForDriverTable}
                  rows={[tripDetails[0]?.driver]}
                  noNeedPagination={true}
                />
              )}

              {
                isDriverTable && (
                  <Table
                    columns={columnsForDriverTable}
                    rows={driverDetails}
                    noNeedPagination={true}
                  />
                )
              }

              <div className="w-full flex-col flex flex-wrap lg:flex-nowrap items-start  ">
                <div className="h-fit flex ">
                  <label
                    className="text-[#344054] text-[13px] pl-1 pb-1 font-medium"
                    htmlFor="description"
                  >
                    Description <Mandate mandate={searchFilter === "manual"} />
                  </label>
                </div>
                <textarea
                  className={`md:w-full w-[250px] h-20 p-2 border border-gray-300 outline-none focus:border-primary-blue rounded-md`}
                  placeholder="Description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                ></textarea>
              </div>
            </div>
          </div>
          <div className=" w-full flex gap-6 justify-center items-center mt-3 md:mt-0">
            <Button
              text={"Debit Money"}
              width={"140px"}
              onClick={() => {
                createDebits();
              }}
            />
          </div>
        </form>
      </>
    );
  };

  const handleCount = async () => {
    try {
      const response = await getWalletCount();
      if (response.data) {
        setCount(response.data)
      }
    }
    catch (err) {
      message.error(err.response.data.message || err.response.data.error || err.message)
    }
  }

  useEffect(() => {
    handleCount()
  }, [walletData])

  const tabContents = [
    // Debited
    // Credited
    {
      key: "debited",
      label: (
        <LabelForTabs isActive={activeKey === "debited"}>Debited ({count.debitCount})</LabelForTabs>
      ),
      children: <DebitedWallet
        data={walletData}
        activeKey={activeKey}
        search={search}
        debitType={debitType}
        completionMode={completionMode}
        fromDate={fromDate}
        toDate={toDate}
        setSearch={setSearch}
        setDebitType={setDebitType}
        setCompletionMode={setCompletionMode}
        setFromDate={setFromDate}
        setToDate={setToDate}
        page={page}
        rowsPerPage={rowsPerPage}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        rowCount={count.debitCount}
      />,
    },
    {
      key: "credited",
      label: (
        <LabelForTabs isActive={activeKey === "credited"}>
          Credited ({count.creditCount})
        </LabelForTabs>
      ),
      children: <DebitedWallet
        data={walletData}
        activeKey={activeKey}
        search={search}
        fromDate={fromDate}
        toDate={toDate}
        setSearch={setSearch}
        setDebitType={setDebitType}
        setFromDate={setFromDate}
        setToDate={setToDate}
        page={page}
        rowsPerPage={rowsPerPage}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        rowCount={count.creditCount}
      />,
    },
    (userType === "superAdminPlus" || userType === "superAdmin") &&
    {
      key: "summary",
      label: (
        <LabelForTabs isActive={activeKey === "summary"}>
          Summary
        </LabelForTabs>
      ),
      children: <SummaryWallet
        data={walletData}
        activeKey={activeKey}
        search={search}
        fromDate={fromDate}
        toDate={toDate}
        setSearch={setSearch}
        setDebitType={setDebitType}
        setFromDate={setFromDate}
        setToDate={setToDate}
        page={page}
        rowsPerPage={rowsPerPage}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />,
    },
  ];

  const isCreateCreditsValid = () => {
    let isValid = true;

    if (!walletId) {
      setWalletIdError("Wallet ID is required");
      isValid = false;
    } else {
      setWalletIdError("");
    }
    if (!driverId) {
      setDriverIdError("Driver ID is required");
      isValid = false;
    } else {
      setDriverIdError("");
    }
    if (!creditAmount) {
      setCreditAmountError("Credit Amount is required");
      isValid = false;
    } else {
      setCreditAmountError("");
    }
    if (!transactionMode) {
      setTransactionModeError("Transaction Mode is required")
      isValid = false;
    } else {
      setTransactionModeError("");
    }
    return isValid;
  };

  const createCredits = async () => {
    if (!isCreateCreditsValid()) return;
    const data = {
      walletId: walletId,
      driverId: driverId?.value || "",
      amount: parseInt(creditAmount),
      description: description,
      transactionMode: parseInt(transactionMode?.id)
    }
    try {
      const response = await createCreditsApi(data);
      if (response.message?.includes("successfully")) {
        message.success("Credits created successfully");
        setCreditModal(false);
        ResetValues();
        fetchData();
      }
    } catch (e) {
      console.log("Error occured while creating debits: ", e);
    }
  };

  const isCreateDebitsValid = () => {
    let isValid = true;

    if (!walletId) {
      setWalletIdError("Wallet ID is required");
      isValid = false;
    } else {
      setWalletIdError("");
    }
    if (tripType?.value === 2) {
      if (!tripId) {
        setTripIdError("Trip ID is required");
        isValid = false;
      } else {
        setTripIdError("");
      }
      if (!tripDate) {
        setTripDateError("Trip Date is required");
        isValid = false;
      } else {
        setTripDateError("");
      }
      if (!fromLocation) {
        setFromLocationError("From Location is required");
        isValid = false;
      } else {
        setFromLocationError("");
      }
      if (!toLocation) {
        setToLocationError("To Location is required");
        isValid = false;
      } else {
        setToLocationError("");
      }
    }
    if (!creditAmount) {
      setCreditAmountError("Credit Amount is required");
      isValid = false;
    } else {
      setCreditAmountError("");
    }
    return isValid;
  };

  const createDebits = async () => {
    if (!isCreateDebitsValid()) return;
    if (searchFilter === "manual") {
      if (!description) {
        message.error("Description is required for manual debit");
        return;
      }
    }
    const dataForTrip = {
      walletId: walletId,
      endFrom: tripId ? tripDetails[0]?.endFrom : null, // Indicates other debits
      debitType: 1,
      tripId: tripId ? tripId.id : null,
      // tripDate: tripDate,
      // fromLocation: fromLocation,
      // toLocation: toLocation,
      driverId: parseInt(tripDetails[0]?.driverId) || driverId?.value,
      amount: parseInt(creditAmount),
      description: description,
      // tripId: parseInt(tripDetails[0]?.id)
    };
    try {
      const response = await createDebitsApi(dataForTrip);
      if (response.message?.includes("successfully")) {
        message.success("Debits created successfully");
        setDebitModal(false);
        ResetValues();
        fetchData();
      }
    } catch (e) {
      message.error(e.response.data.message || e.response.data.error || e.message);
    }
  };

  const ResetValues = () => {
    setWalletId("");
    setTripType("");
    setTripId("");
    setTripDate("");
    setFromLocation("");
    setToLocation("");
    setDriverId("");
    setMobileNumber("");
    setCreditAmount("");
    setDescription("");
    setDriverDetails([]);

    setWalletIdError("");
    setTripTypeError("");
    setTripIdError("");
    setTripDateError("");
    setFromLocationError("");
    setToLocationError("");
    setDriverIdError("");
    setCreditAmountError("");
    setTransactionMode("");
  };

  return (
    <>
      <PageLayout
        title={"Wallet Management"}
        description={"Manage the wallet details here"}
        debiteCreditButton={true}
        buttonWidth={"fit-content"}
        paddingRight={true}
        BreadcrumbsUrl={BreadcrumbsUrl}
        setAddTripModal1={setDebitModal}
        setAddTripModal2={setCreditModal}
      >
        <div className=" w-full flex md:mt-7">
          <TabNavigation
            tab={"walletManagement"}
            tabContents={tabContents}
            activeKey={activeKey}
            setActiveKey={setActiveKey}
            data={walletData}
          />
        </div>
      </PageLayout>
      {/* first modal */}
      <Modal
        title={
          <>
            <img src={addTrip} width={40} alt="" />
          </>
        }
        visible={debitModal}
        width={"900px"}
        centered
        onCancel={() => {
          setDebitModal(false);
          setTripDetails([]);
          ResetValues();
        }}
        footer={null}
      >
        {AddDebitModalContent()}
      </Modal>
      {/* second modal */}
      <Modal
        title={
          <>
            <img src={addTrip} width={40} alt="" />
          </>
        }
        visible={creditModal}
        width={"1000px"}
        centered
        onCancel={() => {
          setCreditModal(false);
          ResetValues();
        }}
        footer={null}
      >
        {AddCreditModalContent()}
      </Modal>
    </>
  );
}
