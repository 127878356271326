import { Card, Modal } from 'antd'
import React from 'react'
import Button from '../../../components/reusable/Button';
import addTrip from '../asserts/add-trip.png';
import TextArea from 'antd/es/input/TextArea';
import Input from '../../../components/reusable/Input';
import DateInput from '../../../components/reusable/DateInput';
import DropDown from '../../../components/reusable/DropDown';

export default function RejectAcceptModal({
    title,
    showModal,
    setShowModal
}) {
    return (
        <>
            <Modal
                title={
                    <>
                        <div className="flex flex-col gap-2">
                            <img src={addTrip} width={40} alt="" />
                            <h1 className="text-lg" style={{ fontSize: '16px', fontWeight: 'bold' }}>{title}</h1>
                        </div>
                    </>
                }
                visible={showModal.show}
                width={"1000px"}
                footer={null}
                onCancel={() => setShowModal(prev => ({ ...prev, show: false }))}
            >


                {
                    showModal.type === 'reject' ? (
                        <div className="my-10 flex flex-col gap-1 ">
                            <p>Reason</p>
                            <TextArea
                                name="rejectRequest"
                                autoSize={{ minRows: 8, maxRows: 10 }}
                                className="w-full border-border-gray border rounded-md p-2 focus:outline-none focus:border-primary-blue"
                                placeholder="Enter the reason"
                                // value={blockComment}
                                // onChange={(e) => setBlockComment(e.target.value)}
                                maxLength={200}
                            />
                        </div>
                    ) : (
                        <div className='fkex flex-col gap-2 my-10'>
                            <div className=' w-full gap-5  h-fit py-5 flex flex-wrap justify-between lg:grid lg:grid-cols-3'>
                                <Input
                                    label={"Redemption ID"}
                                    placeholder={"Enter Redemption ID"}
                                // value={customer?.customerName ? customer?.customerName : null}
                                // onChange={(e) => setCustomer(prev => ({ ...prev, customerName: e.target.value }))}
                                // disabled={!isEdit ? true : false}
                                // mandate={isEdit ? true : false}
                                // error={errors.customerName}
                                />
                                <DateInput
                                    label={"Paid Date"}
                                    placeholder={"Select Payment Date"}
                                // placeholder={"Enter Redemption ID"}
                                // value={customer?.customerName ? customer?.customerName : null}
                                // onChange={(e) => setCustomer(prev => ({ ...prev, customerName: e.target.value }))}
                                // disabled={!isEdit ? true : false}
                                // mandate={isEdit ? true : false}
                                // error={errors.customerName}
                                />
                            </div>

                            <div className=" w-full gap-5  h-fit py-5 flex flex-wrap justify-between lg:grid lg:grid-cols-3">

                                <Input
                                    label={"Redeem Amount"}
                                    placeholder={"Enter Redeem Amount"}
                                // value={customer?.customerName ? customer?.customerName : null}
                                // onChange={(e) => setCustomer(prev => ({ ...prev, customerName: e.target.value }))}
                                // disabled={!isEdit ? true : false}
                                // mandate={isEdit ? true : false}
                                // error={errors.customerName}
                                />
                                <DropDown
                                    label={"Payment Type"}
                                    placeholder={"Enter Payment Type"}
                                // value={customer?.customerPhoneNumber}
                                // setValue={(item) => setCustomer(prev => ({ ...prev, customerPhoneNumber: item }))}
                                // disabled
                                />
                                <Input
                                    label={"Transaction ID"}
                                    placeholder={"Enter Transaction ID"}
                                // placeholder={"Enter Email"}
                                // value={customer?.customerEmail ? customer?.customerEmail : null}
                                // onChange={(e) => setCustomer(prev => ({ ...prev, customerEmail: e.target.value }))}
                                // disabled={!isEdit ? true : false}
                                // mandate={isEdit ? true : false}
                                // error={errors.customerEmail}
                                />

                                {/* <div className="w-[300px]"></div> */}
                            </div>
                        </div>

                    )
                }

                <div className="flex justify-center mb-10">
                    <Button
                        text={"Save"}
                        textColor={"#fff"}
                        width={"100px"}
                        height={"32px"}
                        fontSize={"12px"}
                    // onClick={() => {
                    //     handleBlockCustomer(selectedRow)
                    //     setBlockModal(false)
                    // }}
                    />
                </div>
            </Modal>
        </>
    )
}
