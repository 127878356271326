import { useState } from "react";
import { Link } from "react-router-dom";
import Table from "../../reusable/Table/Table";
import TableHead from "../../reusable/Table/TableHead";
import TableCell from "../../reusable/Table/TableCell";
import DateUtils from "../../../services/DateUtils";


export default function EarningsRedemptionTable({ rows, rowsPerPage, page }) {

    const columns = [
        {
            id: "redemptionId",
            name: <TableHead>Redemption ID</TableHead>,
            cell: (row) => (
                <TableCell>
                    <Link
                        className=" text-primary-blue"
                        to={`/redemption-management/completed-redemption/${row.redemptionID}`}
                        state={{ id: row.id }}
                    >
                        {row.redemptionID}
                    </Link>
                </TableCell>
            ),
        },
        {
            id: "redemptionType",
            name: <TableHead>Redemption Type</TableHead>,
            cell: (row) => <TableCell>{row.redemptiontType}</TableCell>,
        },
        {
            id: "redemptionDate",
            name: <TableHead width="180px">Redemption Date</TableHead>,
            cell: (row) => <TableCell width="180px">{DateUtils.convertDate(row?.date)}</TableCell>,
        },

        {
            id: "balanceRewardPoints",
            name: <TableHead width="180px">Balance Reward Points</TableHead>,
            cell: (row) => <TableCell width="180px">₹ {row.balanceRewardPoints?.toFixed(2)?.toLocaleString('hi') || 0}</TableCell>,
        },
        {
            id: "pointsRedeemed",
            name: <TableHead>Points Redeemed</TableHead>,
            cell: (row) => <TableCell>₹ {row.pointsRedeemed?.toLocaleString('hi')}</TableCell>,
        },
    ];

    return (
        <div className="w-full removeScrollBar pb-5">
            <Table columns={columns} rows={rows} rowsPerPage={rowsPerPage} page={page} rowCount={10} isRowsPerPage={true} />
        </div>
    );
}
