// api/auth.js
import axios from 'axios';  // Or use fetch if preferred

const login = async (email, password) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/common/signin`, {
      email,
      password
    });
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 401 || error.response.status === 403) {
      localStorage.clear();
      window.location.href = "/login";
    } else {
      console.error("Error:", error);
    }
    return error.response && error.response.data;
  }
};

export default { login };
