import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import MobileNumberInput from "../../components/reusable/MobileNumberInput";
import Input from "../../components/reusable/Input";
import DropDown from "../../components/reusable/DropDown";
import Card from "antd/lib/card";
import Button from "../../components/reusable/Button";
import { updateCustomer } from "../../services/CustomerManagementServices";
import driverImage from "../../assets/profileImage.png";
import Edit from '../../assets/edit_pencil.svg';

const CustomerDetailsCard = ({
    customerDetails,
    isEdit,
    setIsEdit,
    customer,
    setCustomer,
    errors,
    states,
    districts,
    getAllDistrict,
    profileImage,
    handleProfileImage,
    updateCustomerDetails,
    setErrors,
    loading,
    getCustomer,
    disableEdit
}) => {
    const navigate = useNavigate();
    const profileImageRef = useRef(null);

    return (
        <div className="w-full px-1 flex my-4 justify-center items-center">
            <Card className="w-full flex flex-col gap-6 shadow-sm rounded-md">
                <div className="flex w-full justify-between">
                    <div className="w-fit flex gap-2 items-center text-start text-gray-800 " style={{ fontSize: '16px' }}>
                        Personal Details
                    </div>
                    {!disableEdit &&
                        <div className="w-fit flex justify-end items-center">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="15"
                                height="14"
                                viewBox="0 0 15 14"
                                fill="none"
                                onClick={() => { setIsEdit(!isEdit); isEdit && getCustomer(customerDetails?.customer?.mobileNumber) }}
                                className="cursor-pointer"
                            >
                                <path
                                    d="M6.87014 2.33314H2.69147C2.37482 2.33314 2.07115 2.45606 1.84725 2.67485C1.62334 2.89364 1.49756 3.19039 1.49756 3.49981V11.6665C1.49756 11.9759 1.62334 12.2726 1.84725 12.4914C2.07115 12.7102 2.37482 12.8331 2.69147 12.8331H11.0488C11.3655 12.8331 11.6691 12.7102 11.893 12.4914C12.1169 12.2726 12.2427 11.9759 12.2427 11.6665V7.58314M11.3473 1.45814C11.5848 1.22608 11.9069 1.0957 12.2427 1.0957C12.5786 1.0957 12.9007 1.22608 13.1382 1.45814C13.3756 1.6902 13.5091 2.00495 13.5091 2.33314C13.5091 2.66133 13.3756 2.97608 13.1382 3.20814L7.4671 8.74981L5.07928 9.33314L5.67624 6.99981L11.3473 1.45814Z"
                                    stroke="#344054"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </div>
                    }
                </div>
                <div className="flex w-full flex-col md:flex-row items-center gap-6 md:gap-12">
                    <div className="flex w-full md:w-1/2 justify-center items-center">
                        <div className="flex flex-col gap-5 w-full justify-center items-center md:flex-row">
                            <div className="md:w-1/2">
                                <div className="flex flex-col items-center justify-center w-full mt-4 md:mt-0">
                                    <div className="relative">
                                        {profileImage ? (
                                            <img
                                                src={profileImage}
                                                alt="profile"
                                                className="rounded-full w-32 h-32 object-cover border-2 shadow-lg"
                                            />
                                        ) : (
                                            <img
                                                src={driverImage}
                                                alt="profile"
                                                className="w-40 h-40 object-contain rounded-full"
                                            />
                                        )}
                                        {isEdit && (
                                            <button
                                                className={`absolute ${profileImage ? "right-2 top-3" : "right-6 top-7"}`}
                                                onClick={() => profileImageRef.current.click()}
                                            >
                                                <img src={Edit} alt="" />
                                                <input
                                                    type="file"
                                                    className="hidden"
                                                    ref={profileImageRef}
                                                    onChange={handleProfileImage}
                                                    accept="image/*"
                                                />
                                            </button>
                                        )}
                                    </div>
                                    <p className="text-lg font-black">{customerDetails?.userID}</p>
                                    <p className="text-sm"><span className="text-primary-blue">Referral ID: {customerDetails?.referralCode}</span></p>
                                </div>
                            </div>
                            <div className="md:w-1/2 items-center gap-2 flex flex-col justify-between">
                                <Input
                                    label={"User Name"}
                                    placeholder={"Enter username"}
                                    value={customer?.customerName ? customer?.customerName : null}
                                    onChange={(e) => setCustomer(prev => ({ ...prev, customerName: e.target.value }))}
                                    disabled={!isEdit ? true : false}
                                    mandate={isEdit ? true : false}
                                    error={errors.customerName}
                                />
                                <Input
                                    label={"Email"}
                                    placeholder={"Enter Email"}
                                    value={customer?.customerEmail ? customer?.customerEmail : null}
                                    onChange={(e) => setCustomer(prev => ({ ...prev, customerEmail: e.target.value }))}
                                    disabled={!isEdit ? true : false}
                                    mandate={isEdit ? true : false}
                                    error={errors.customerEmail}
                                />
                                <MobileNumberInput
                                    label={"Phone Number"}
                                    value={customer?.customerPhoneNumber}
                                    setValue={(item) => setCustomer(prev => ({ ...prev, customerPhoneNumber: item }))}
                                    disabled
                                />
                                {/* <div className="w-[300px]"></div> */}
                            </div>
                        </div>
                    </div>
                    <div className="hidden md:block h-[200px] w-[1px] bg-slate-300" />
                    <hr className="md:hidden w-full" />
                    <div className="flex flex-col w-1/2 h-full">
                        <p className="font-medium whitespace-nowrap text-start w-full text-gray-800 " style={{ fontSize: '16px' }}>
                            Address Details
                        </p>
                        <div className="w-full items-center gap-3 md:gap-5 py-5 flex flex-wrap justify-center lg:grid lg:grid-cols-2">
                            <DropDown
                                label={"State"}
                                placeholder={"Select state"}
                                data={states}
                                displayValue={'stateName'}
                                value={customer?.customerState}
                                setValue={(item) => {
                                    setCustomer(prev => ({ ...prev, customerState: item, customerDistrict: {} }));
                                    getAllDistrict(item.id);
                                }}
                                disabled={!isEdit ? true : false}
                            />
                            <DropDown
                                label={"District"}
                                placeholder={"Select district"}
                                data={districts}
                                displayValue={'districtName'}
                                value={customer.customerDistrict}
                                setValue={(item) => setCustomer(prev => ({ ...prev, customerDistrict: item }))}
                                disabled={!isEdit ? true : false}
                            />
                            <Input
                                label={"Street"}
                                placeholder={"Enter street name"}
                                value={customer?.customerStreet ? customer?.customerStreet : null}
                                onChange={(e) => setCustomer(prev => ({ ...prev, customerStreet: e.target.value }))}
                                disabled={!isEdit ? true : false}
                            />
                            <Input
                                label={"House Number"}
                                placeholder={"Enter house number"}
                                value={customer?.customerHouseNumber ? customer?.customerHouseNumber : null}
                                onChange={(e) => setCustomer(prev => ({ ...prev, customerHouseNumber: e.target.value }))}
                                disabled={!isEdit ? true : false}
                            />
                        </div>
                    </div>
                </div>
                {/* Update and Cancel buttons */}
                {
                    isEdit &&
                    <div className="flex justify-center items-center gap-3 mt-5">
                        <Button
                            text={"Cancel"}
                            width={"100px"}
                            bgColor={"#fff"}
                            height={"35px"}
                            onClick={() => {
                                setIsEdit(false);
                                setErrors({});
                                getCustomer(customerDetails?.customer?.mobileNumber);
                            }}
                            textColor={"#1849A9"}
                            borderColor={"#1849A9"}
                            fontSize={"12px"}
                        />
                        <Button
                            text={"Update"}
                            width={"100px"}
                            bgColor={"#1849A9"}
                            height={"35px"}
                            onClick={updateCustomerDetails}
                            textColor={"#fff"}
                            fontSize={"12px"}
                            loading={loading}
                        />
                    </div>
                }
            </Card>
        </div>
    );
};

export default CustomerDetailsCard;
