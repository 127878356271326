import { useEffect, useState } from "react";
import earningsImg from "../assets/earnings.svg"
import EarningsComponent from "../component/EarningsComponent";


export default function Earnings({
    getEarningsDetails,
    earningsData
}) {

    const [isEditActive, setIsEditActive] = useState(false)

    return (
        <>
            <div className="flex flex-col gap-8 w-full justify-center md:p-4 md:px-9 md:pb-0 md:mb-5">
                {
                    earningsData?.map(earnings => (
                        <EarningsComponent
                            key={earnings.id}
                            id={earnings.id}
                            referralId={earnings.referralType}
                            vehicalImg={earningsImg}
                            earningType={earnings.referralType == 1 ? "Trip Referral" : earnings.referralType == 2 ? "Signup Referral" : "Trip Cashback"}
                            isEditActive={isEditActive}
                            setIsEditActive={setIsEditActive}
                            defaultPercentage={earnings?.defaultFare}
                            occasionalPercentage={earnings?.occasionalFare}
                            fromDateFromApi={earnings.fromDate}
                            toDateFromApi={earnings.toDate}
                            getEarningsDetails={getEarningsDetails}
                            earningsData={earningsData}
                        />
                    ))
                }
            </div>

        </>
    )
}