import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Table from "../../reusable/Table/Table";
import TableHead from "../../reusable/Table/TableHead";
import TableCell from "../../reusable/Table/TableCell";
import DropDown from "../../reusable/DropDown";
import Input from "../../reusable/Input";
import CommonContext from "../../../context-storage/CommonServicesHandler";
import { Modal, message } from "antd";
import Button from "../../reusable/Button";
import DateUtils from "../../../services/DateUtils";

export default function PendingDriverTable({ activeKey,
  page,
  handleChangePage,
  handleChangeRowsPerPage,
  rowsPerPage,
  rowCount,
}) {
  const [search, setSearch] = useState("");
  const [vehicleType, setVehicleType] = useState("");
  const [driverType, setDriverType] = useState("");
  const [driverStatus, setDriverStatus] = useState("");
  const [employees, setEmployees] = useState([]);
  const [vehicleTypeDropDown, setVehicleTypeDropDown] = useState([]);
  const { tripTypeData, setTripMode, vehicleTypeData, allVehicle } = useContext(CommonContext);
  const driveStatusData =
    [
      {
        "id": "Part Time",
        "status": "Part Time"
      },
      {
        "id": "Dedicated",
        "status": "Dedicated"
      }
    ]
  useEffect(() => {
    setVehicleTypeDropDown(allVehicle);
  }, [vehicleTypeData]);

  useEffect(() => {

    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/driver/getDrivers?pageSize=${rowsPerPage}&currentPage=${page + 1}&driverVerify=1&${search ? `&searchQuery=${search}` : ""}${vehicleType ? `&vehicleTypeID=${vehicleType.id}` : ""}
          ${driverStatus ? `&driverType=${driverStatus.id}` : ""}`, {
          headers: {
            "x-access-token": localStorage.getItem("CC_ADMIN_TOKEN"),
            "email": localStorage.getItem("CC_ADMIN_EMAIL"),
            "usertype": localStorage.getItem("CC_ADMIN_USERTYPE")
          }
        }
        );
        const data = await response.json();
        if (data.data) {

          setEmployees(data.data);
        }
        else {
          setEmployees([]);
        }
      } catch (error) {
        if (error.response && error.response.status === 401 || error.response.status === 403) {
          localStorage.clear();
          window.location.href = "/login";
        } else {
          console.error("Error:", error);
        }
        return error.response && error.response.data;
      }
    };
    if (activeKey === 'drivers') {
      fetchData();
    }

  }, [activeKey, vehicleType, search, driverStatus, page, rowsPerPage]);
  const [isBlockModalOpen, setIsBlockModalOpen] = useState(false);
  const [blockReason, setBlockReason] = useState("");
  useEffect(() => {
  }, [blockReason]);
  const [selectedRowForBlock, setSelectedRowForBlock] = useState(null);
  const blockDriver = async (row, reason) => {
    if (reason === "") {
      message.error("Please enter block reason");
      return;
    }
    try {
      await fetch(`${process.env.REACT_APP_API_BASE_URL}/driver/updateVerifyDriver/${row.id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("CC_ADMIN_TOKEN"),
          "email": localStorage.getItem("CC_ADMIN_EMAIL"),
          "usertype": localStorage.getItem("CC_ADMIN_USERTYPE")
        },
        body: JSON.stringify({
          driverVerify: 2,
          rejectReason: reason || "Driver is blocked",
        }),
      });
      setIsBlockModalOpen(false);
      setBlockReason("");
    } catch (error) {
      if (error.response && error.response.status === 401 || error.response.status === 403) {
        localStorage.clear();
        window.location.href = "/login";
      } else {
        console.error("Error:", error);
      }
      return error.response && error.response.data;
    }
  };
  const openBlockModal = (row) => {
    setSelectedRowForBlock(row);
    setIsBlockModalOpen(true);
  };
  const columns = [
    {
      id: "driverId",
      name: <TableHead width="120px">Driver ID</TableHead>,
      cell: (row) => (
        <TableCell width="120px">
          <Link
            className=" text-primary-blue"
            to={`/driver-management/${activeKey}/${row.driverID}?id=${row.id}`}
          >
            {row.driverID}
          </Link>
        </TableCell>
      ),
    },
    {
      id: "driverName",
      name: <TableHead>Driver Name</TableHead>,
      cell: (row) => <TableCell>{row.driverName}</TableCell>,
    },
    {
      id: "vehicleNumber",
      name: <TableHead>Vehicle Number</TableHead>,
      cell: (row) => <TableCell>{row.vehicleNo}</TableCell>,
    },
    {
      id: "vehicleName",
      name: <TableHead>Vehicle Name</TableHead>,
      cell: (row) => <TableCell>{row.vehicleName}</TableCell>,
    },

    {
      id: "vehicleType",
      name: <TableHead width="100px">Vehicle Type</TableHead>,
      cell: (row) => <TableCell width="100px">{row.vehicleType.name}</TableCell>,
    },
    {
      id: "mobileNumber",
      name: <TableHead>Mobile Number</TableHead>,
      cell: (row) => <TableCell>{row.mobileNo}</TableCell>,
    },
    {
      id: "expDoc",
      name: <TableHead>
        Expiry Document
      </TableHead>,
      cell: (row) => <TableCell>{row.manualExpDoc}</TableCell>,
    },
    {
      id: "expDate",
      name: <TableHead>Expiry Date</TableHead>,
      cell: (row) => <TableCell>{row.manualExpDate ? DateUtils.convertDate(row.manualExpDate) : "-"}</TableCell>

    },
    {
      id: "driverType",
      name: <TableHead>Wallet</TableHead>,
      cell: (row) => <TableCell>₹ {row.walletAvailableBalace.toLocaleString('hi')} /-</TableCell>,
    },

    //       {
    //         id :"action",
    //         name: <TableHead>Action</TableHead>,
    //         cell : (row) => (
    //           <TableCell>
    //             <button
    //             onClick={() => openBlockModal(row)}
    //               className="text-primary-blue flex items-center gap-2"
    //             >
    //               <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 300.000000 300.000000" preserveAspectRatio="xMidYMid "  class="svg">
    // <g transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)" fill="#F70809" stroke="none">
    // <path class="node" id="node1" d="M1390 2848 c-299 -18 -604 -163 -847 -402 -62 -61 -113 -116 -113
    // -121 0 -6 -8 -20 -18 -31 -36 -39 -110 -174 -153 -279 -18 -44 -36 -84 -41
    // -89 -4 -6 -8 -22 -8 -37 0 -14 -6 -45 -14 -67 -39 -110 -45 -438 -10 -582 8
    // -36 18 -81 20 -100 3 -19 13 -50 21 -69 8 -19 28 -64 43 -100 15 -36 39 -86
    // 54 -111 14 -25 33 -58 42 -75 9 -16 27 -43 40 -58 13 -16 24 -37 24 -47 0 -10
    // 5 -20 10 -22 16 -5 200 -190 200 -200 0 -5 7 -11 15 -14 8 -3 51 -31 97 -61
    // 98 -64 258 -143 343 -168 33 -9 80 -23 105 -30 123 -36 372 -49 495 -27 106
    // 20 209 44 220 52 6 4 23 10 39 14 56 14 215 95 313 159 508 336 726 974 531
    // 1552 -143 423 -480 747 -903 868 -156 45 -303 58 -505 45z m355 -328 c130 -31
    // 331 -119 343 -151 2 -5 12 -9 22 -9 10 0 22 -9 25 -20 4 -11 11 -18 16 -14 6
    // 3 47 -31 92 -75 68 -67 79 -83 67 -92 -12 -8 -10 -9 8 -4 16 5 24 1 29 -12 3
    // -10 14 -27 23 -38 34 -39 108 -192 135 -281 15 -49 33 -100 39 -113 15 -32 15
    // -393 0 -412 -6 -8 -19 -45 -28 -84 -22 -88 -115 -277 -133 -273 -20 4 -1443
    // 1430 -1437 1440 8 12 120 69 184 93 196 73 423 90 615 45z m-306 -1092 c397
    // -397 721 -725 721 -729 0 -31 -169 -139 -269 -172 -31 -10 -65 -22 -76 -26
    // -85 -37 -308 -50 -460 -27 -235 35 -435 141 -601 319 -118 126 -181 235 -233
    // 397 -75 236 -63 513 30 717 10 24 19 45 19 47 0 3 16 33 36 68 35 59 91 127
    // 106 128 4 0 331 -325 727 -722z"></path>
    // </g>
    // <g transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)" fill="#BEAAA5" stroke="none">



    // </g>
    // </svg>
    //             Block  
    //             </button>
    //           </TableCell>
    //         )
    //       }
  ];


  return (
    <div className="w-full removeScrollBar pb-5">
      <div className="flex   flex-wrap  lg:gap-8 gap-2 w-full justify-center lg:flex-nowrap  lg:pl-2  lg:pr-10  lg:justify-start items-center mb-5  lg:mb-10  mt-2 lg:mt-6">
        <Input
          label={"Search"}
          type={"text"}
          placeholder={"name/contact number/vehicle number"}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <DropDown
          label={"Vehicle Type"}
          placeholder={"Select vehicle type"}
          displayValue={"name"}
          data={vehicleTypeDropDown}
          value={vehicleType}
          setValue={setVehicleType}
          selectAll
        />
        {/* <DropDown
          label={"Driver Status"}
          placeholder={"Select driver status"}
          displayValue={"id"}
          data={driveStatusData}
          value={driverStatus}
          setValue={setDriverStatus}
          mandate
        /> */}
      </div>

      <Table columns={columns} rows={employees} rowCount={rowCount || 0}
        page={page} handleChangePage={handleChangePage} handleChangeRowsPerPage={handleChangeRowsPerPage} rowsPerPage={rowsPerPage}

      />

      <Modal
        title={
          <>
            <div className="flex gap-2">
              <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" fill="orange">
                <path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" />
              </svg>
              <h1 className="text-lg" style={{ fontSize: '16px', fontWeight: 'normal' }}>Confirmation</h1>
            </div>
          </>
        }
        open={isBlockModalOpen}

        okText="Block"
        cancelText="Cancel"
        footer={() => (
          <>
            <div className="flex gap-3 justify-end">
              <Button
                text={"Cancel"}
                textColor={"#1849A9"}
                width={"80px"}
                height={"30px"}
                bgColor={"#fff"}
                borderColor={"#1849A9"}
                fontSize={"12px"}
                onClick={() => {
                  setIsBlockModalOpen(false);
                  setBlockReason("");
                }}
              />
              <Button
                text={"Ok"}
                textColor={"#fff"}
                width={"80px"}
                height={"30px"}
                fontSize={"12px"}
                onClick={async () => {
                  await blockDriver(selectedRowForBlock, blockReason);
                }}
              />
            </div>
          </>
        )}
      >
        <div className="pr-5 pt-5">
          <h1 className="text-base" style={{ fontSize: '13px', fontWeight: 'normal' }}>
            Are you sure you want to <em>
              <b>Block</b>
            </em> Driver
            ?
          </h1>
        </div>
        <div className=" w-full flex  flex-col py-3">
          <Input
            placeholder="Enter block reason"
            label={"Block Reason"}
            value={blockReason}
            onChange={(e) => setBlockReason(e.target.value)}

          />
        </div>
      </Modal>
    </div>
  );
}
