import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/reusable/Button";
import LoginLayout from "../../components/layouts/login/LoginLayout";
import ErrorMessage from "../../components/reusable/ErrorMessage";

export default function OtpSubmissionPage() {

    const [emailOtp, setEmailOtp] = useState(new Array(6).fill(""));
    const [phoneOtp, setPhoneOtp] = useState(new Array(6).fill(""));
    const [activeEmailOtpIndex, setActiveEmailOtpIndex] = useState(0);
    // eslint-disable-next-line
    const [activePhoneOtpIndex, setActivePhoneOtpIndex] = useState(0);
    const [isEmailOtpValid, setIsEmailOtpValid] = useState(true);
    const [isPhoneOtpValid, setIsPhoneOtpValid] = useState(true);
    const emailInputRef = useRef(null);
    const phoneInputRef = useRef(null);
    const [emailValidation, setEmailValidation] = useState("");
    // eslint-disable-next-line
    const [phoneValidation, setPhoneValidation] = useState("");

    const handleOnChange = (event, index, otp, setOtp, setActiveIndex) => {
        const { value } = event.target;
        const newOTP = [...otp];
        newOTP[index] = value.substring(value.length - 1, value.length);

        if (index > 0 && !value) {
            setActiveIndex(index - 1);
        } else if (index < otp.length - 1) {
            setActiveIndex(index + 1);
        }
        setOtp(newOTP);
    };

    const handleOnKeyDown = (
        event,
        index,
        otp,
        setOtp,
        setActiveIndex,
        setIsValid,
        otpType
    ) => {
        const { key } = event;
        const newOTP = [...otp];
        if (key === "Backspace") {
            event.preventDefault();
            newOTP[index] = "";
            setOtp(newOTP);
            setActiveIndex(Math.max(0, index - 1));
        } else if (key === "Delete") {
            event.preventDefault();
            for (let i = index; i < otp.length; i++) {
                newOTP[i] = "";
            }
            setOtp(newOTP);
        } else if (key === "ArrowUp") {
            event.preventDefault();
            return;
        } else if (key === "ArrowDown") {
            event.preventDefault();
            return;
        } else if (key === "ArrowRight") {
            event.preventDefault();
            setActiveIndex(Math.min(otp.length - 1, index + 1));
        } else if (key === "ArrowLeft") {
            event.preventDefault();
            setActiveIndex(Math.max(0, index - 1));
        } else if (key === "Enter") {
            event.preventDefault();
            const otpString = otp.join("");
            if (otp.every((otpValue) => otpValue)) {
                if (otpString === "000000") {
                    setIsValid(false);
                    if (otpType === "phone") {
                        setPhoneValidation("Please enter a valid OTP");
                    } else {
                        setEmailValidation("Please enter a valid OTP");
                    }
                } else {
                    alert(`Your OTP is: ${otpString}`);
                    setIsValid(true);
                    setIsEmailOtpValid(true);
                    setIsPhoneOtpValid(true);

                }
            } else {
                setIsValid(false);
                if (otpType === "phone") {
                    setPhoneValidation("Please enter the six digit OTP");
                } else {
                    setEmailValidation("Please enter the six digit OTP");
                }
            }
        }
    };

    const navigate = useNavigate();

    useEffect(() => {
        if (isEmailOtpValid && isPhoneOtpValid && emailOtp.join("").length > 0) {
            navigate("/reset-password");
        }// eslint-disable-next-line
    }, [isEmailOtpValid, isPhoneOtpValid]);

    useEffect(() => {
        phoneInputRef.current && phoneInputRef.current.focus();
    }, [activePhoneOtpIndex]);

    useEffect(() => {
        emailInputRef.current && emailInputRef.current.focus();
    }, [activeEmailOtpIndex]);


    const [showResentButton, setShowResentButton] = useState(false);

    const [timerMinutes, setTimerMinutes] = useState(0);
    const [timerSeconds, setTimerSeconds] = useState(59);

    useEffect(() => {
        let myInterval = setInterval(() => {
            if (timerSeconds > 0) {
                setTimerSeconds(timerSeconds - 1);
            }
            if (timerSeconds === 0) {
                if (timerMinutes === 0) {
                    clearInterval(myInterval);
                } else {
                    setTimerMinutes((prev) => prev - 1);
                    setTimerSeconds(59);
                }
            }
        }, 1000);

        return () => {
            clearInterval(myInterval);
        };
    }, [timerMinutes, timerSeconds]);


    useEffect(() => {
        if (timerMinutes === 0 && timerSeconds === 0) {
            setShowResentButton(true);
        }
    }, [timerMinutes, timerSeconds]);
    const handleResendClick = () => {
        setTimerMinutes(0);
        setTimerSeconds(59);
        setShowResentButton(false);
    };

    const handleButtonClick = (otp, setOtp, setIsValid, event, otpType) => {
        event.preventDefault();
        const syntheticEvent = {
            preventDefault: () => { },
            key: "Enter",
        };
        handleOnKeyDown(
            syntheticEvent,
            otp.length - 1,
            otp,
            setOtp,
            () => { },
            setIsValid,
            otpType
        );
    };

    return (
        <LoginLayout
            title="Hello Again !"
            text="OTP Verification"
            description="Please the OTP to verify your account."
        >
            <form className="" onSubmit={handleOnKeyDown}>
                <div className=" flex flex-col mb-[20px] mt-8 gap-1 ">
                    <div className="h-fit">
                        <label
                            className=" text-sm lg:text-lg  text-[#344054] font-medium text-left"
                            htmlFor="email_otp"
                        >
                            Email OTP
                        </label>
                    </div>
                    <div className="flex gap-[14px]  justify-center lg:gap-[23px]">
                        {emailOtp.map((value, index) => (
                            <React.Fragment key={index}>
                                <input
                                    ref={index === activeEmailOtpIndex ? emailInputRef : null}
                                    type="number"
                                    className={` lg:w-12 w-10  h-10 lg:h-12 text-sm lg:text-lg  bg-white border rounded-lg bg-transparent outline-none text-center font-semibold   transition spin-button-none 
                                                ${!isEmailOtpValid
                                            ? "border-red-500  text-gray-900"
                                            : "border-[#D9D9D9] focus:border-[#1849A9] text-gray-700"
                                        } text-gray-400`}
                                    onChange={(e) =>
                                        handleOnChange(
                                            e,
                                            index,
                                            emailOtp,
                                            setEmailOtp,
                                            setActiveEmailOtpIndex
                                        )
                                    }
                                    onKeyDown={(e) =>
                                        handleOnKeyDown(
                                            e,
                                            index,
                                            emailOtp,
                                            setEmailOtp,
                                            setActiveEmailOtpIndex,
                                            setIsEmailOtpValid,
                                            "email"
                                        )
                                    }
                                    value={value}
                                />
                            </React.Fragment>
                        ))}
                    </div>
                    <div>
                        {!isEmailOtpValid && (
                            <ErrorMessage>
                                {emailValidation}
                            </ErrorMessage>
                        )}
                    </div>
                </div>
                {/* <div className="flex flex-col mt-[35px] gap-1 mb-1">
                    <div className="h-fit">
                        <label
                            className=" text-sm lg:text-lg  text-[#344054] font-medium text-left"
                            htmlFor="mobile_otp"
                        >
                            Mobile OTP
                        </label>
                    </div>
                    <div className="flex  gap-[14px] justify-center lg:gap-[23px]">
                        {phoneOtp.map((value, index) => (
                            <React.Fragment key={index}>
                                <input
                                    ref={index === activePhoneOtpIndex ? phoneInputRef : null}
                                    type="number"
                                    className={
                                        ` lg:w-12 w-10  h-10 lg:h-12 text-sm lg:text-lg  border bg-white rounded-lg bg-transparent outline-none text-center font-semibold   transition spin-button-none 
                                        ${!isPhoneOtpValid ? "border-red-500  text-gray-900" : "border-[#D9D9D9] focus:border-[#1849A9] text-gray-700"} text-gray-400`
                                    }
                                    value={value}
                                    onChange={(e) =>
                                        handleOnChange(
                                            e,
                                            index,
                                            phoneOtp,
                                            setPhoneOtp,
                                            setActivePhoneOtpIndex
                                        )
                                    }
                                    onKeyDown={(e) =>
                                        handleOnKeyDown(
                                            e,
                                            index,
                                            phoneOtp,
                                            setPhoneOtp,
                                            setActivePhoneOtpIndex,
                                            setIsPhoneOtpValid,
                                            "phone"
                                        )
                                    }
                                />
                            </React.Fragment>
                        ))}
                    </div>
                    <div>
                        {!isPhoneOtpValid && (
                            <ErrorMessage>
                                {phoneValidation}
                            </ErrorMessage>
                        )}
                    </div>
                    <style>
                        {
                            `.spin-button-none::-webkit-outer-spin-button,
                                .spin-button-none::-webkit-inner-spin-button {
                                    -webkit-appearance: none;
                                    margin: 0;
                                }
                                .spin-button-none {
                                    -moz-appearance: textfield;
                                }`
                        }
                    </style>
                </div> */}
                <div className="flex items-center justify-center w-full mt-7">
                    <div className="flex flex-col w-full  lg:w-[390px] justify-between items-center mt-4">
                        <Button
                            text="Submit"
                            width="100%"
                            height="40px"
                            bgColor="#1849A9"
                            textColor={"#fff"}
                            onClick={(e) => {
                                handleButtonClick(
                                    emailOtp,
                                    setEmailOtp,
                                    setIsEmailOtpValid,
                                    e,
                                    "email"
                                );
                                handleButtonClick(
                                    phoneOtp,
                                    setPhoneOtp,
                                    setIsPhoneOtpValid,
                                    e,
                                    "phone"
                                );
                            }}
                            type="button"
                        />
                        <div className="flex items-center justify-center w-full pt-4">
                            <div className=" text-[#344054]  text-sm lg:text-lg font-medium">Didn&apos;t receive OTP?</div>
                            {!showResentButton ? (
                                <div className="text-[#1849A9] text-sm lg:text-lg  pl-2 font-bold">
                                    Send again in {timerMinutes.toString().padStart(2, '0')}:{timerSeconds.toString().padStart(2, '0')}
                                </div>
                            ) : (
                                <div
                                    onClick={handleResendClick}
                                    className=" text-[#1849A9] text-sm lg:text-lg  pl-2 cursor-pointer font-bold"
                                >
                                    Send again
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </form>
        </LoginLayout>
    );
}