import { useContext, useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Modal, Typography, message } from "antd";
import { Card, Popover } from "antd";
import { Breadcrumbs } from "@mui/material";
import PageLayout from "../../components/layouts/PageLayout";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Input from "../../components/reusable/Input";
import DropDown from "../../components/reusable/DropDown";
import DateInput from "../../components/reusable/DateInput";
import TimeInput from "../../components/reusable/TimeInput";
import MobileNumberInput from "../../components/reusable/MobileNumberInput";
import Upload from "../../components/reusable/Upload";
import axios from "axios";
import CommonContext from "../../context-storage/CommonServicesHandler";
import {
  getDistricts,
  getDriverTrips,
  getStates,
} from "../../services/DriverManagementServices";
import Button from "../../components/reusable/Button";
import driverImage from "../../assets/profileImage.png";
import vehicleImage from "../../assets/vehicleImage.png";
import { Edit } from "@mui/icons-material";
import addTrip from "../trip-management/assets/add-trip.png";
import DateUtils from "../utils/DateUtils";
import AddressDetailsComponent from "../../components/tables/driver-management/DetailsPage/AddressDetails";
import PersonalDetailsComponent from "../../components/tables/driver-management/DetailsPage/PersonalDetails";
import DocumentDetailsComponent from "../../components/tables/driver-management/DetailsPage/DocumentDetails";
import AddDriverModal from "../../components/tables/driver-management/Modals/PersonalDetailsModal";
import AddAddressModal from "../../components/tables/driver-management/Modals/AddreessDetailsModal";
import TripDetailsTable from "../../components/tables/driver-management/DetailsPage/TripDetailsTable";

export default function DriverDetailsPage() {

  const [userType, setUserType] = useState("");

  useEffect(() => {
    setUserType(localStorage.getItem("CC_ADMIN_USERTYPE"));
  }, []);

  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(window.location.search);
  const id = searchParams.get("id");
  const [driverIdForFetch, setDriverIdForFetch] = useState(id);
  useEffect(() => {
    if (id) {
      setDriverIdForFetch(id);
    }
  }, [id]);

  const param = useParams();
  const { allVehicle } = useContext(CommonContext);

  const driveStatusData = [
    {
      id: "Part Time",
      status: "Part Time",
    },
    {
      id: "Dedicated",
      status: "Dedicated",
    },
  ];
  const [driverVerifyStatus, setDriverVerifyStatus] = useState(null);
  const [createdAt, setCreatedAt] = useState("");
  const [createdAtDate, setCreatedAtDate] = useState("");
  const [createdAtTime, setCreatedAtTime] = useState("");
  const [driverId, setDriverId] = useState("");
  const [isEditable1, setIsEditable1] = useState(false);
  const [isEditable2, setIsEditable2] = useState(false);
  const [isEditable3, setIsEditable3] = useState(false);
  const [routeId, setRouteId] = useState("");
  const [routeValidation, setRouteValidation] = useState("");
  const [driverName, setDriverName] = useState("");
  const [driverNameValidation, setDriverNameValidation] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [mobileNumberValidation, setMobileNumberValidation] = useState("");
  const [vehicleName, setVehicleName] = useState("");
  const [vehicleNameValidation, setVehicleNameValidation] = useState("");
  const [vehicleNumber, setVehicleNumber] = useState("");
  const [vehicleNumberValidation, setVehicleNumberValidation] = useState("");
  const [vehicleType, setVehicleType] = useState("");
  useEffect(() => {
    const vehicleTypeData = allVehicle.find(
      (item) => item.id === vehicleType.toString()
    );
    if (vehicleTypeData) {
      setVehicleType(vehicleTypeData);
    }
  }, [vehicleType, allVehicle]);

  const [vehicleTypeValidation, setVehicleTypeValidation] = useState("");
  const [bloodGroup, setBloodGroup] = useState("");
  const [bloodGroupValidation, setBloodGroupValidation] = useState("");
  const [driverStatus, setDriverStatus] = useState([]);
  const [driverStatusValidation, setDriverStatusValidation] = useState("");
  const [company, setCompany] = useState("");
  const [addDriverModal, setAddDriverModal] = useState(false);
  const [addDriverModal2, setAddDriverModal2] = useState(false);
  const [addDriverModal3, setAddDriverModal3] = useState(false);
  const [isPermanentAddressSame, setIsPermanentAddressSame] = useState(false);
  const [profileImage, setProfileImage] = useState(undefined);
  const [shiftTime, setShiftTime] = useState("");
  const profileImageRef = useRef();
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [tempStreetAddress, setTempStreetAddress] = useState("");
  const [tempStreetAddressValidation, setTempStreetAddressValidation] =
    useState("");
  const [tempHouseNumber, setTempHouseNumber] = useState("");
  const [tempHouseNumberValidation, setTempHouseNumberValidation] =
    useState("");
  const [tempState, setTempState] = useState("");
  const [tempStateValidation, setTempStateValidation] = useState("");
  const [tempDistrict, setTempDistrict] = useState("");
  const [tempDistrictValidation, setTempDistrictValidation] = useState("");
  const [tempPincode, setTempPincode] = useState("");
  const [tempPincodeValidation, setTempPincodeValidation] = useState("");
  const [permStreetAddress, setPermStreetAddress] = useState("");
  const [permStreetAddressValidation, setPermStreetAddressValidation] =
    useState("");
  const [permHouseNumber, setPermHouseNumber] = useState("");
  const [permHouseNumberValidation, setPermHouseNumberValidation] =
    useState("");
  const [permState, setPermState] = useState("");
  const [permStateValidation, setPermStateValidation] = useState("");
  const [permDistrict, setPermDistrict] = useState("");
  const [permDistrictValidation, setPermDistrictValidation] = useState("");
  const [permPincode, setPermPincode] = useState("");
  const [permPincodeValidation, setPermPincodeValidation] = useState("");
  const [upiId, setUpiId] = useState();
  const [upiIdValidation, setUpiIdValidation] = useState();
  const [aadharNumber, setAadharNumber] = useState();
  const [aadharNumberValidation, setAadharNumberValidation] = useState();
  const [drivingLicense, setDrivingLicense] = useState();
  const [drivingLicenseValidation, setDrivingLicenseValidation] = useState();
  const [licenseBatchExp, setLicenseBatchExp] = useState();
  const [permitExpDate, setPermitExpDate] = useState();
  const [insuranceExpDate, setInsuranceExpDate] = useState();
  const [fcExpiryDate, setFcExpiryDate] = useState();
  const [policeVerificationExpDate, setPoliceVerificationExpDate] = useState();
  const [pollutionVerificationExpDate, setPollutionVerificationExpDate] =
    useState();
  const [taxExpDate, setTaxExpDate] = useState();
  const [aadharCardError, setAadharCardError] = useState("");
  const [drivingLicenseError, setDrivingLicenseError] = useState("");
  const [pollutionCertificateError, setPollutionCertificateError] =
    useState("");
  const [covidVaccineCertificateError, setCovidVaccineCertificateError] =
    useState("");
  const [policeCertificateError, setPoliceCertificateError] = useState("");
  const [fcCopyError, setFcCopyError] = useState("");
  const [rcCopyError, setRcCopyError] = useState("");
  const [aadharCardFile, setAadharCardFile] = useState(null);
  const [licenseBatchExpError, setLicenseBatchExpError] = useState("");
  const [permitExpDateError, setPermitExpDateError] = useState("");
  const [insuranceExpDateError, setInsuranceExpDateError] = useState("");
  const [taxExpDateError, setTaxExpDateError] = useState("");
  const [aadharCardFileName, setAadharCardFileName] = useState("");
  const [drivingLicenseFile, setDrivingLicenseFile] = useState(null);
  const [drivingLicenseFileName, setDrivingLicenseFileName] = useState("");
  const [pollutionCertificateFile, setPollutionCertificateFile] =
    useState(null);
  const [pollutionCertificateFileName, setPollutionCertificateFileName] =
    useState("");
  const [covidVaccineCertificateFile, setCovidVaccineCertificateFile] =
    useState(null);
  const [covidVaccineCertificateFileName, setCovidVaccineCertificateFileName] =
    useState("");
  const [documentStatus, setDocumentStatus] = useState({});
  const [validApprovalButton, setValidApprovalButton] = useState(false);
  const [validRejectButton, setValidRejectButton] = useState(false);
  const [policeCertificateFile, setPoliceCertificateFile] = useState(null);
  const [policeCertificateFileName, setPoliceCertificateFileName] =
    useState("");
  const [fcCopyFile, setFcCopyFile] = useState(null);
  const [fcCopyFileName, setFcCopyFileName] = useState("");
  const [rcCopyFile, setRcCopyFile] = useState(null);
  const [rcCopyFileName, setRcCopyFileName] = useState("");
  const [PermitAndTaxFile, setPermitAndTaxFile] = useState(null);
  const [PermitAndTaxFileUrl, setPermitAndTaxFileUrl] = useState(null);
  const [PermitAndTaxFileName, setPermitAndTaxFileName] = useState("");
  const [insuranceFile, setInsuranceFile] = useState(null);
  const [insuranceFileUrl, setInsuranceFileUrl] = useState(null);
  const [insuranceFileName, setInsuranceFileName] = useState("");

  const [drivingLicenseFrontFile, setDrivingLicenseFrontFile] = useState(null);
  const [drivingLicenseBackFile, setDrivingLicenseBackFile] = useState(null);
  const [rcCopyFrontFile, setRcCopyFrontFile] = useState(null);
  const [rcCopyBackFile, setRcCopyBackFile] = useState(null);
  const [aadharCardFrontFile, setAadharCardFrontFile] = useState(null);
  const [aadharCardBackFile, setAadharCardBackFile] = useState(null);

  const [drivingLicenseFrontFileUrls, setDrivingLicenseFrontFileUrls] =
    useState("");
  const [drivingLicenseBackFileUrls, setDrivingLicenseBackFileUrls] =
    useState("");
  const [rcCopyFrontFileUrls, setRcCopyFrontFileUrls] = useState("");
  const [rcCopyBackFileUrls, setRcCopyBackFileUrls] = useState("");
  const [aadharCardFrontFileUrls, setAadharCardFrontFileUrls] = useState("");
  const [aadharCardBackFileUrls, setAadharCardBackFileUrls] = useState("");

  const [drivingLicenseFrontError, setDrivingLicenseFrontError] = useState("");
  const [drivingLicenseBackError, setDrivingLicenseBackError] = useState("");
  const [drivingLicenseFrontFileName, setDrivingLicenseFrontFileName] =
    useState("");
  const [drivingLicenseBackFileName, setDrivingLicenseBackFileName] =
    useState("");
  const [rcCopyFrontError, setRcCopyFrontError] = useState("");
  const [rcCopyBackError, setRcCopyBackError] = useState("");
  const [rcCopyFrontFileName, setRcCopyFrontFileName] = useState("");
  const [rcCopyBackFileName, setRcCopyBackFileName] = useState("");
  const [aadharCardFrontError, setAadharCardFrontError] = useState("");
  const [aadharCardBackError, setAadharCardBackError] = useState("");
  const [aadharCardFrontFileName, setAadharCardFrontFileName] = useState("");
  const [aadharCardBackFileName, setAadharCardBackFileName] = useState("");

  const [aadharCardFileUrls, setAadharCardFileUrls] = useState("");
  const [drivingLicenseFileUrls, setDrivingLicenseFileUrls] = useState("");
  const [pollutionCertificateFileUrls, setPollutionCertificateFileUrls] =
    useState("");
  const [covidVaccineCertificateFileUrls, setCovidVaccineCertificateFileUrls] =
    useState("");
  const [policeCertificateFileUrls, setPoliceCertificateFileUrls] =
    useState("");
  const [fcCopyFileUrls, setFcCopyFileUrls] = useState("");
  const [rcCopyFileUrls, setRcCopyFileUrls] = useState("");
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);

  const [walletAvailableBalance, setWalletAvailableBalance] = useState(0);
  const [walletTotalCredit, setWalletTotalCredit] = useState(0);
  const [walletTotalDebit, setWalletTotalDebit] = useState(0);

  const [licenseNumberRemark, setLicenseNumberRemark] = useState("");
  const [aadharNumberRemark, setAadharNumberRemark] = useState("");
  const [upiIdRemark, setUpiIdRemark] = useState("");
  const [drivingLicenseRemark, setDrivingLicenseRemark] = useState("");
  const [aadharCardRemark, setAadharCardRemark] = useState("");
  const [pollutionCertificateRemark, setPollutionCertificateRemark] =
    useState("");
  const [covidVaccineCertificateRemark, setCovidVaccineCertificateRemark] =
    useState("");
  const [policeCertificateRemark, setPoliceCertificateRemark] = useState("");
  const [fcCopyRemark, setFcCopyRemark] = useState("");
  const [rcCopyRemark, setRcCopyRemark] = useState("");
  const [permitAndTaxRemark, setPermitAndTaxRemark] = useState("");
  const [insuranceRemark, setInsuranceRemark] = useState("");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalAction, setModalAction] = useState("");
  const [rejectReason, setRejectReason] = useState("");
  const [selectedRow, setSelectedRow] = useState({});
  const [latestReason, setLatestReason] = useState("");
  const handleCancel = () => {
    setIsModalOpen(false);
    setRejectReason("");
  };

  // new document
  const [vehicleImageRemark, setVehicleImageRemark] = useState("");
  const [vehicleFrontViewWithDriverError, setVehicleFrontViewWithDriverError] = useState("");
  const [vehicleFrontImageWithNumberPlateError, setVehicleFrontImageWithNumberPlateError] = useState("");
  const [vehicleBackImageWithNumberPlateError, setVehicleBackImageWithNumberPlateError] = useState("");

  const [vehicleFrontViewWithDriverFileName, setVehicleFrontViewWithDriverFileName] = useState("");
  const [vehicleFrontImageWithNumberPlateFileName, setVehicleFrontImageWithNumberPlateFileName] = useState("");
  const [vehicleBackImageWithNumberPlateFileName, setVehicleBackImageWithNumberPlateFileName] = useState("");

  const [vehicleFrontViewWithDriverFile, setVehicleFrontViewWithDriverFile] = useState(null);
  const [vehicleFrontImageWithNumberPlateFile, setVehicleFrontImageWithNumberPlateFile] = useState(null);
  const [vehicleBackImageWithNumberPlateFile, setVehicleBackImageWithNumberPlateFile] = useState(null);

  const [vehicleFrontViewWithDriverUrl, setVehicleFrontViewWithDriverUrl] = useState(null);
  const [vehicleFrontImageWithNumberPlateUrl, setVehicleFrontImageWithNumberPlateUrl] = useState(null);
  const [vehicleBackImageWithNumberPlateUrl, setVehicleBackImageWithNumberPlateUrl] = useState(null);

  //Pagination details
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const [driverTrips, setDriverTrips] = useState([]);
  const [driverTripCount, setDriverTripCount] = useState(0);

  const excludedKeys = [
    "fcCertificateVerify",
    "permitCertificateVerify",
    "policeVerificationCertificateVerify",
    "pollutionCertificateVerify"
  ];

  useEffect(() => {
    async function getAllDriverTrips() {
      try {
        const response = await getDriverTrips(id, page, rowsPerPage);
        if (response.data) {
          setDriverTrips(response.data);
          setDriverTripCount(response.totalCount);
        }
      } catch (err) {
        message.error(err);
      }
    }
    getAllDriverTrips();
  }, [page, rowsPerPage]);

  useEffect(() => {
    const filteredDocumentStatus = Object.entries(documentStatus)
      .filter(([key]) => !excludedKeys.includes(key))
      .map(([, value]) => value);

    setValidApprovalButton(
      filteredDocumentStatus.every(value => value !== 0 && value !== 2 && value !== 3)
    );
  }, [documentStatus]);

  const acceptDriver = async (id) => {
    if (!manualDocumentSelected) {
      message.error("Please select a document.");
      return;
    }
    if (!manualDocumentDate) {
      message.error("Please select a document with date.");
      return;
    }
    try {
      await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/driver/updateVerifyDriver/${id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("CC_ADMIN_TOKEN"),
            "email": localStorage.getItem("CC_ADMIN_EMAIL"),
            "usertype": localStorage.getItem("CC_ADMIN_USERTYPE")
          },
          body: JSON.stringify({
            driverVerify: 1,
            manualExpDoc: manualDocumentSelected.name,
            manualExpDate: manualDocumentDate,
          }),
        }
      );
      message.success("Driver approved.");
      setIsModalOpen(false);
      navigate(-1);
    } catch (error) {
      if (error.response && error.response.status === 401 || error.response.status === 403) {
        localStorage.clear();
        window.location.href = "/login";
      } else {
        console.error("Error:", error);
      }
      return error.response && error.response.data;
    }
  };

  const rejectDriver = async () => {
    // handleSubmit(setIsEditable3);
    try {
      await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/driver/updateVerifyDriver/${driverIdForFetch}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("CC_ADMIN_TOKEN"),
            "email": localStorage.getItem("CC_ADMIN_EMAIL"),
            "usertype": localStorage.getItem("CC_ADMIN_USERTYPE")
          },
          body: JSON.stringify({
            driverVerify: 2,
            rejectReason: latestReason,
          }),
        }
      );
      // Handle success if needed
      // message.success("Driver rejected.");
      setIsModalOpen(false);
    } catch (error) {
      if (error.response && error.response.status === 401 || error.response.status === 403) {
        localStorage.clear();
        window.location.href = "/login";
      } else {
        console.error("Error:", error);
      }
      return error.response && error.response.data;
    }
  };
  const deleteDriver = async () => {
    try {
      await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/driver/delete/${driverIdForFetch}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("CC_ADMIN_TOKEN"),
            "email": localStorage.getItem("CC_ADMIN_EMAIL"),
            "usertype": localStorage.getItem("CC_ADMIN_USERTYPE")
          },
        }
      );
      message.success("Driver deleted successfully");
      navigate(-1);
    } catch (error) {
      if (error.response && error.response.status === 401 || error.response.status === 403) {
        localStorage.clear();
        window.location.href = "/login";
      } else {
        console.error("Error:", error);
      }
      return error.response && error.response.data;
    }
  };

  const handleOk = async () => {
    if (rejectReason === "" && modalAction === "reject") {
      message.error("Please enter the rejection reason");
      return;
    }
    if (modalAction === "accept") {
      await acceptDriver(selectedRow);
    } else if (modalAction === "reject") {
      await rejectDriver(selectedRow, rejectReason);
    } else if (modalAction === "delete") {
      await deleteDriver(selectedRow);
    }
    setRejectReason("");
  };

  const openModal = (action, row) => {
    setModalAction(action);
    setSelectedRow(row);
    setIsModalOpen(true);
  };

  useEffect(() => {
    if (driverStatus) {
      const driverStatusData = driveStatusData.find(
        (item) => item.id === driverStatus.toString()
      );
      if (driverStatusData) {
        setDriverStatus(driverStatusData);
      }
    }
  }, [driverStatus]);

  useEffect(() => {
    if (tempState) {
      const tempStateData = states.find(
        (item) => item.stateName === tempState.toString()
      );
      if (tempStateData) {
        setTempState(tempStateData);
      }
    }
  }, [tempState, states]);

  useEffect(() => {
    if (permState) {
      const permStateData = states.find(
        (item) => item.stateName === permState.toString()
      );
      if (permStateData) {
        setPermState(permStateData);
      }
    }
  }, [permState, states]);

  useEffect(() => {
    if (tempDistrict) {
      const tempDistrictData = districts.find(
        (item) => item.districtName === tempDistrict.toString()
      );
      if (tempDistrictData) {
        setTempDistrict(tempDistrictData);
      }
    }
  }, [tempDistrict, districts]);

  useEffect(() => {
    if (permDistrict) {
      const permDistrictData = districts.find(
        (item) => item.districtName === permDistrict.toString()
      );
      if (permDistrictData) {
        setPermDistrict(permDistrictData);
      }
    }
  }, [permDistrict, districts]);

  async function getState() {
    try {
      const response = await getStates();
      if (response.data) {
        setStates(response.data);
      }
    } catch (err) {
      message.error(
        err.response.data.message || err.response.data.error || err.message
      );
    }
  }

  async function getAllDistrict(item) {
    try {
      const response = await getDistricts(item);
      if (response.data) {
        setDistricts(response.data);
      }
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    getState();
  }, []);

  useEffect(() => {
    if (tempState) {
      getAllDistrict(tempState.id);
    }
  }, [tempState]);

  useEffect(() => {
    if (permState) {
      getAllDistrict(permState.id);
    }
  }, [permState]);

  useEffect(() => {
    const date = new Date();
    const dateStr = date.toISOString().split("T")[0];
    const timeStr = date.toTimeString().split(" ")[0];
    setCreatedAt(dateStr);
    setCreatedAtDate(dateStr);
    setCreatedAtTime(timeStr);
  }, [createdAt]);

  function handleClick(event) {
    event.preventDefault();
    console.info("You clicked a breadcrumb.");
  }

  const BreadcrumbsUrl = () => (
    <>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        <Link color="inherit" href="/" onClick={handleClick}>
          Trip Management
        </Link>
        <Link
          color="inherit"
          href="/getting-started/installation/"
          onClick={handleClick}
        >
          Not Allotted
        </Link>
        <Typography color="text.primary">LC007</Typography>
      </Breadcrumbs>
    </>
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const content = (setIsEditable, isEditable) => {
    return (
      <div className=" flex flex-col overflow-y-auto  ">
        <div
          onClick={() => setIsEditable(!isEditable)}
          className="   hover:bg-gray-200  p-1    flex justify-center items-center  rounded-md cursor-pointer     "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 14 16"
            fill="none"
          >
            <path
              d="M6.87014 2.33314H2.69147C2.37482 2.33314 2.07115 2.45606 1.84725 2.67485C1.62334 2.89364 1.49756 3.19039 1.49756 3.49981V11.6665C1.49756 11.9759 1.62334 12.2726 1.84725 12.4914C2.07115 12.7102 2.37482 12.8331 2.69147 12.8331H11.0488C11.3655 12.8331 11.6691 12.7102 11.893 12.4914C12.1169 12.2726 12.2427 11.9759 12.2427 11.6665V7.58314M11.3473 1.45814C11.5848 1.22608 11.9069 1.0957 12.2427 1.0957C12.5786 1.0957 12.9007 1.22608 13.1382 1.45814C13.3756 1.6902 13.5091 2.00495 13.5091 2.33314C13.5091 2.66133 13.3756 2.97608 13.1382 3.20814L7.4671 8.74981L5.07928 9.33314L5.67624 6.99981L11.3473 1.45814Z"
              stroke="#344054"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          {/* <div>Edit</div> */}
        </div>
      </div>
    );
  };

  const rejectFunction = async (key) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/driver/updateVerifyDriverDocument/${driverIdForFetch}`,
        {
          [key]: 2,
        }, {
        headers: {
          "x-access-token": localStorage.getItem("CC_ADMIN_TOKEN"),
          "email": localStorage.getItem("CC_ADMIN_EMAIL"),
          "usertype": localStorage.getItem("CC_ADMIN_USERTYPE")
        }
      }
      );
      if (response.data.status) {
        // Handle successful response
        // message.success(response.data.message);
        // fetchDriverData();
        return true;
      } else {
        // Handle error response
        message.error(response.data.message);
        console.error(response.data.message);
      }
    } catch (error) {
      if (error.response && error.response.status === 401 || error.response.status === 403) {
        localStorage.clear();
        window.location.href = "/login";
      } else {
        console.error("Error:", error);
      }
      return error.response && error.response.data;
    }
  };
  const pendingFunction = async (key) => {
    setDocumentStatus({
      ...documentStatus,
      [key]: 3,
    });
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/driver/updateVerifyDriverDocument/${driverIdForFetch}`,
        {
          [key]: 3,
        }, {
        headers: {
          "x-access-token": localStorage.getItem("CC_ADMIN_TOKEN"),
          "email": localStorage.getItem("CC_ADMIN_EMAIL"),
          "usertype": localStorage.getItem("CC_ADMIN_USERTYPE")
        }
      }
      );
      if (response.data.status) {
        // Handle successful response
        // message.success(response.data.message);
        fetchDriverData();
        return true;
      } else {
        // Handle error response
        message.error(response.data.message);
        console.error(response.data.message);
      }
    } catch (error) {
      if (error.response && error.response.status === 401 || error.response.status === 403) {
        localStorage.clear();
        window.location.href = "/login";
      } else {
        console.error("Error:", error);
      }
      return error.response && error.response.data;
    }
  };
  const acceptFunction = async (key, confirmationRemarksKey) => {
    try {
      const response = await axios.put(
        ` ${process.env.REACT_APP_API_BASE_URL}/driver/updateVerifyDriverDocument/${driverIdForFetch}`,
        {
          [key]: 1,
        }, {
        headers: {
          "x-access-token": localStorage.getItem("CC_ADMIN_TOKEN"),
          "email": localStorage.getItem("CC_ADMIN_EMAIL"),
          "usertype": localStorage.getItem("CC_ADMIN_USERTYPE")
        }
      }
      );
      // for resetting remarks after accepting
      const data = {
        id: driverIdForFetch,
        [confirmationRemarksKey]: "-"
      };
      const response2 = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/driver/update/${driverIdForFetch}`,
        data, {
        headers: {
          "x-access-token": localStorage.getItem("CC_ADMIN_TOKEN"),
          "email": localStorage.getItem("CC_ADMIN_EMAIL"),
          "usertype": localStorage.getItem("CC_ADMIN_USERTYPE")
        }
      }
      );
      // end of "for resetting remarks after accepting"
      if (response.data.status) {
        // Handle successful response
        message.success(response.data.message);
        fetchDriverData();
        return true;
      } else {
        // Handle error response
        message.error(response.data.message);
        console.error(response.data.message);
      }
    } catch (error) {
      if (error.response && error.response.status === 401 || error.response.status === 403) {
        localStorage.clear();
        window.location.href = "/login";
      } else {
        console.error("Error:", error);
      }
      return error.response && error.response.data;
    }
  };

  const [DocumentExpiryDetails, setDocumentExpiryDetails] = useState([]);
  const [manualDocumentSelected, setManualDocumentSelected] = useState(null);
  const [manualDocumentDate, setManualDocumentDate] = useState("");

  useEffect(() => {
    fetchDocumentDetails();
    if (manualDocumentSelected) {
      const expiryDate = DocumentExpiryDetails.find(
        (item) => item.name === manualDocumentSelected.name
      );
      console.log(expiryDate, "exp date");
      if (expiryDate) {
        setManualDocumentDate(expiryDate.expiryDate);
      }
    }
  }, [manualDocumentSelected]);

  const fetchDocumentDetails = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/driver/getLatestExpiryDocDetails/?id=${driverIdForFetch}`, {
        headers: {
          "x-access-token": localStorage.getItem("CC_ADMIN_TOKEN"),
          "email": localStorage.getItem("CC_ADMIN_EMAIL"),
          "usertype": localStorage.getItem("CC_ADMIN_USERTYPE")
        }
      }
      );
      if (response.data.status) {
        // Handle successful response
        console.log(response.data);
        const array = [
          {
            name: "Driving License",
            expiryDate: response.data.data.drivingLicenseExpiryDate,
          },
          {
            name: "Insurance",
            expiryDate: response.data.data.insuranceExpiryDate,
          },
          {
            name: "FC",
            expiryDate: response.data.data.fcExpiryDate,
          },
          {
            name: "Permit & Tax",
            expiryDate: response.data.data.permitTaxExpiryDate,
          },
          {
            name: "Police Verification",
            expiryDate: response.data.data.policeVerificationExpiryDate,
          },
          {
            name: "Pollution",
            expiryDate: response.data.data.pollutionExpiryDate,
          },
        ];
        setDocumentExpiryDetails(array);
      }
    } catch (error) {
      if (error.response && error.response.status === 401 || error.response.status === 403) {
        localStorage.clear();
        window.location.href = "/login";
      } else {
        console.error("Error:", error);
      }
      return error.response && error.response.data;
    }
  };

  useEffect(() => {
    fetchDocumentDetails();
  }, []);

  const handleSubmit = async () => {
    const updateMobileNumberWithoutSpaces = mobileNumber.replace(/\s/g, "");
    const data = {
      driverName: driverName,
      mobileNo: updateMobileNumberWithoutSpaces,
      vehicleName: vehicleName,
      vehicleNo: vehicleNumber,
      vehicleTypeID: vehicleType.id,

      tempStreetAddress: tempStreetAddress,
      tempHouseNo: tempHouseNumber,
      selectedTemState: tempState?.stateName,
      selectedTemDistrict: tempDistrict?.districtName,
      tempPincode: tempPincode,
      perStreetAddress: isPermanentAddressSame
        ? tempStreetAddress
        : permStreetAddress,
      perHouseNo: isPermanentAddressSame ? tempHouseNumber : permHouseNumber,
      selectedPerState: isPermanentAddressSame
        ? tempState?.stateName
        : permState?.stateName,
      selectedPerDistrict: isPermanentAddressSame
        ? tempDistrict?.districtName
        : permDistrict?.districtName,
      perPincode: isPermanentAddressSame ? tempPincode : permPincode,
      upiId: upiId,
      aadharNumber: aadharNumber,
      adhaarUrls: aadharCardFileUrls,
      drivingLicenseNumber: drivingLicense,
      pollutionExpiryDate: pollutionVerificationExpDate,

      permitTaxExpiryDate: permitExpDate,
      fcExpiryDate: fcExpiryDate,
      insuranceExpiryDate: insuranceExpDate,
      taxExpDate: taxExpDate,
      policeVerificationExpiryDate: policeVerificationExpDate,
      pollutionCertificate: pollutionCertificateFileUrls,
      cvUrls: covidVaccineCertificateFileUrls,
      policeVerificationCertificate: policeCertificateFileUrls,
      fcCopy: fcCopyFileUrls,
      rcUrls: rcCopyFileUrls,
      selectedImage: profileImage,
      isAddressPermanent: isPermanentAddressSame,
      rcCopyFront: rcCopyFrontFileUrls,
      rcCopyBack: rcCopyBackFileUrls,
      aadharCardFront: aadharCardFrontFileUrls,
      aadharCardBack: aadharCardBackFileUrls,
      permitTaxCertificate: PermitAndTaxFileUrl,
      insuranceCertificate: insuranceFileUrl,
      registrationCertificateBackUrl: rcCopyBackFileUrls,
      registrationCertificateFrontUrl: rcCopyFrontFileUrls,
      drivingLicenseBack: drivingLicenseBackFileUrls,
      drivingLicenseFront: drivingLicenseFrontFileUrls,
      drivingLicenseExpiryDate: licenseBatchExp,
      createdBy: 1,
      drivingLicenseNumberRemarks: licenseNumberRemark,
      drivingLicenseRemarks: drivingLicenseRemark,
      rcRemarks: rcCopyRemark,
      insuranceCertificateRemarks: insuranceRemark,
      fcCopyRemarks: fcCopyRemark,
      permitTaxCertificateRemarks: permitAndTaxRemark,
      pollutionCertificateRemarks: pollutionCertificateRemark,
      policeVerificationCertificateRemarks: policeCertificateRemark,
      aadharRemarks: aadharCardRemark,
      manualExpDoc: manualDocumentSelected?.name,
      manualExpDate: manualDocumentDate,
      vehicleImageRemarks: vehicleImageRemark,
      vehicleFrontViewWithDriver: vehicleFrontViewWithDriverUrl,
      vehicleFrontImageWithNumberPlate: vehicleFrontImageWithNumberPlateUrl,
      vehicleBackImageWithNumberPlate: vehicleBackImageWithNumberPlateUrl
    };
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/driver/update/${driverIdForFetch}`,
        data, {
        headers: {
          "x-access-token": localStorage.getItem("CC_ADMIN_TOKEN"),
          "email": localStorage.getItem("CC_ADMIN_EMAIL"),
          "usertype": localStorage.getItem("CC_ADMIN_USERTYPE")
        }
      }
      );
      if (response.data.status) {
        message.success("Driver details updated.");
        // Handle successful response
        setAddDriverModal3(false);
        // Reset state variables if needed
        setAddDriverModal(false);
        // setIsEditable(false);
        // fetchDriverData();

        return true;
      } else {
        // Handle error response
        message.error(response.data.message);
        console.error(response.data.message);

        return false;
      }
    } catch (error) {
      if (error.response && error.response.status === 401 || error.response.status === 403) {
        localStorage.clear();
        window.location.href = "/login";
      } else {
        console.error("Error:", error);
      }
      return error.response && error.response.data;
    }
  };
  const WalletDetails = () => {
    return (
      <div className=" w-full px-1 flex  my-7 justify-center items-center ">
        <Card className=" w-full flex flex-col gap-6 pb-2  shadow-sm rounded-md">
          <div className=" w-full text-start text-lg mt-1 font- text-gray-800 ">
            Wallet Details
          </div>
          <>
            <div className=" w-full flex-wrap gap-5 h-fit py-6 flex justify-between items-center   ">
              <Input
                label={"Available balance"}
                placeholder={"Enter created by"}
                value={
                  "₹ " + walletAvailableBalance?.toLocaleString("hi") + "/-"
                }
                setValue={""}
                disabled={true}
              />
              <Input
                label={"Total credited"}
                placeholder={"Enter created by"}
                value={"₹ " + walletTotalCredit?.toLocaleString("hi") + "/-"}
                setValue={""}
                disabled={true}
              />
              <Input
                label={"Total debited"}
                placeholder={"Enter created by"}
                value={"₹ " + walletTotalDebit?.toLocaleString("hi") + "/-"}
                setValue={""}
                disabled={true}
              />
            </div>
          </>
        </Card>
      </div>
    );
  };
  const TripDetails = () => {
    return (
      <div className=" w-full flex flex-col gap-6 px-1  my-7 justify-center items-center ">
        {/* <Card className="  shadow-sm rounded-md"> */}
        <div className=" w-full text-start text-lg mt-1 font- text-gray-800 ">
          Trip Details
        </div>
        <div className="w-full">
          <TripDetailsTable
            rows={driverTrips}
            rowsPerPage={rowsPerPage}
            page={page}
            rowCount={driverTripCount}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </div>
        {/* </Card> */}
      </div>
    );
  };
  const CreateDetails = () => {
    return (
      <div className=" w-full px-1 flex  my-7 justify-center items-center ">
        <Card className=" w-full flex flex-col gap-6 pb-4  shadow-sm rounded-md">
          <div className=" w-full text-start text-lg mt-1 font- text-gray-800 ">
            Creater Details
          </div>
          <>
            <div className=" w-full flex-wrap gap-5 h-fit py-10 flex justify-between items-center   ">
              <DateInput
                label={"Created date"}
                placeholder={"Select created date"}
                value={DateUtils.convertDate(createdAtDate)}
                setValue={""}
                disabled={true}
              />
              <TimeInput
                label={"Created time"}
                placeholder={"Select created time"}
                value={DateUtils.convertTime(createdAtTime)}
                setValue={""}
                disabled={true}
              />
              <Input
                label={"Created by"}
                placeholder={"Enter created by"}
                value={"Super Admin"}
                setValue={""}
                disabled={true}
              />
            </div>
          </>
        </Card>
      </div>
    );
  };

  useEffect(() => {
    fetchDriverData();
  }, [driverIdForFetch]);

  const fetchDriverData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/driver/getDriverOne/?id=${driverIdForFetch}`, {
        headers: {
          "x-access-token": localStorage.getItem("CC_ADMIN_TOKEN"),
          "email": localStorage.getItem("CC_ADMIN_EMAIL"),
          "usertype": localStorage.getItem("CC_ADMIN_USERTYPE")
        }
      }
      );
      const data = await response.json();

      if (data.status) {
        const driverData = data.data;
        setDriverVerifyStatus(location?.pathname?.includes("pending") || location?.pathname?.includes("drivers") ? driverData.driverVerify : 3);
        setDriverId(driverData.driverID);
        setDriverName(driverData.driverName);
        setMobileNumber(driverData.mobileNo);
        setVehicleName(driverData.vehicleName);

        setVehicleNumber(driverData.vehicleNo);

        setVehicleType(driverData.vehicleTypeID);
        setBloodGroup(driverData.bloodGroup);
        setDriverStatus(driverData.driverStatus);
        setTempStreetAddress(driverData.tempStreetAddress);
        setTempHouseNumber(driverData.tempHouseNo);
        setTempState(driverData.selectedTemState);
        setTempDistrict(driverData.selectedTemDistrict);
        setTempPincode(driverData.tempPincode);
        setPermStreetAddress(driverData.perStreetAddress);
        setPermHouseNumber(driverData.perHouseNo);
        setPermState(driverData.selectedPerState);
        setPermDistrict(driverData.selectedPerDistrict);
        setPermPincode(driverData.perPincode);
        setUpiId(driverData.upiId);
        setAadharNumber(driverData.aadharNumber);
        setDrivingLicense(driverData.drivingLicenseNumber);
        setLicenseBatchExp(driverData.drivingLicenseExpiryDate);
        setPermitExpDate(driverData.permitTaxExpiryDate);
        setPollutionVerificationExpDate(driverData.pollutionExpiryDate);
        setPoliceVerificationExpDate(driverData.policeVerificationExpiryDate);
        setInsuranceExpDate(driverData.insuranceExpiryDate);
        setTaxExpDate(driverData.taxExpDate);
        setIsPermanentAddressSame(driverData.isPermanentAddressSame);
        setAadharCardFrontFileUrls(driverData.aadharCardFront);
        setAadharCardFrontFile(driverData.aadharCardFront);
        setAadharCardFrontFileName(
          driverData.aadharCardFront && "Aadhar Card Front"
        );
        setAadharCardBackFileUrls(driverData.aadharCardBack);
        setAadharCardBackFile(driverData.aadharCardBack);
        setAadharCardBackFileName(
          driverData.aadharCardBack && "Aadhar Card Back"
        );
        setDrivingLicenseFrontFileUrls(driverData.drivingLicenseFront);
        setDrivingLicenseFrontFile(driverData.drivingLicenseFront);
        setDrivingLicenseFrontFileName(
          driverData.drivingLicenseFront && "Driving License Front"
        );
        setDrivingLicenseBackFileUrls(driverData.drivingLicenseBack);
        setDrivingLicenseBackFile(driverData.drivingLicenseBack);
        setDrivingLicenseBackFileName(
          driverData.drivingLicenseBack && "Driving License Back"
        );
        setRcCopyFrontFileUrls(driverData.rcCopyFront);
        setRcCopyFrontFile(driverData.rcCopyFront);
        setRcCopyFrontFileName(driverData.rcCopyFront && "RC Copy Front");
        setRcCopyBackFileUrls(driverData.rcCopyBack);
        setRcCopyBackFile(driverData.rcCopyBack);
        setRcCopyBackFileName(driverData.rcCopyBack && "RC Copy Back");
        setInsuranceFileUrl(driverData.insuranceCertificate);
        setInsuranceFile(driverData.insuranceCertificate);
        setInsuranceFileName(
          driverData.insuranceCertificate && "Insurance Certificate"
        );
        setFcCopyFileUrls(driverData.fcCopy);
        setFcCopyFile(driverData.fcCopy);
        setFcCopyFileName(driverData.fcCopy && "FC Copy");
        setPermitAndTaxFileUrl(driverData.permitTaxCertificate);
        setPermitAndTaxFile(driverData.permitTaxCertificate);
        setPermitAndTaxFileName(
          driverData.permitTaxCertificate && "Permit & Tax Certificate"
        );
        setPollutionCertificateFileUrls(driverData.pollutionCertificate);
        setPollutionCertificateFile(driverData.pollutionCertificate);
        setPollutionCertificateFileName(
          driverData.pollutionCertificate && "Pollution Certificate"
        );
        setPoliceCertificateFileUrls(driverData.policeVerificationCertificate);
        setPoliceCertificateFile(driverData.policeVerificationCertificate);
        setPoliceCertificateFileName(
          driverData.policeVerificationCertificate && "Police Certificate"
        );
        setCovidVaccineCertificateFileUrls(driverData.cvUrls);

        setProfileImage(driverData.selectedImage);

        setCreatedAt(driverData.createdAt);
        setFcExpiryDate(driverData.fcExpiryDate);

        setWalletAvailableBalance(driverData.walletAvailableBalace);
        setWalletTotalCredit(driverData.walletTotalCredited);
        setWalletTotalDebit(driverData.walletTotalDebedited);
        setAadharCardRemark(driverData.aadharRemarks);
        setDrivingLicenseRemark(driverData.drivingLicenseRemarks);
        setLicenseNumberRemark(driverData.drivingLicenseNumberRemarks);
        setRcCopyRemark(driverData.rcRemarks);
        setInsuranceRemark(driverData.insuranceCertificateRemarks);
        setFcCopyRemark(driverData.fcCopyRemarks);
        setPermitAndTaxRemark(driverData.permitTaxCertificateRemarks);
        setPollutionCertificateRemark(driverData.pollutionCertificateRemarks);
        setPoliceCertificateRemark(
          driverData.policeVerificationCertificateRemarks
        );
        setVehicleFrontViewWithDriverUrl(driverData.vehicleFrontViewWithDriver)
        setVehicleFrontImageWithNumberPlateUrl(driverData.vehicleFrontImageWithNumberPlate)
        setVehicleBackImageWithNumberPlateUrl(driverData.vehicleBackImageWithNumberPlate)

        setVehicleFrontViewWithDriverFile(driverData.vehicleFrontViewWithDriver)
        setVehicleFrontImageWithNumberPlateFile(driverData.vehicleFrontImageWithNumberPlate)
        setVehicleBackImageWithNumberPlateFile(driverData.vehicleBackImageWithNumberPlate)

        setVehicleFrontViewWithDriverFileName(driverData.vehicleFrontViewWithDriver && "Front Image with Driver")
        setVehicleFrontImageWithNumberPlateFileName(driverData.vehicleFrontImageWithNumberPlate && "Front Image with Number Plate")
        setVehicleBackImageWithNumberPlateFileName(driverData.vehicleBackImageWithNumberPlate && "Back Image with Number Plate")
        setVehicleImageRemark(driverData.vehicleImageRemarks)

        setDocumentStatus({
          rcCertificateVerify: driverData.rcCertificateVerify,
          insuranceCertificateVerify: driverData.insuranceCertificateVerify,
          drivingLicenseVerify: driverData.drivingLicenseVerify,
          fcCertificateVerify: driverData.fcCertificateVerify,
          permitCertificateVerify: driverData.permitCertificateVerify,
          policeVerificationCertificateVerify:
            driverData.policeVerificationCertificateVerify,
          adhaarCardVerify: driverData.adhaarCardVerify,
          pollutionCertificateVerify: driverData.pollutionCertificateVerify,
          vehicleImageVerify: driverData.vehicleImageVerify
        });
        const documentStatus = {
          rcCertificateVerify: driverData.rcCertificateVerify,
          insuranceCertificateVerify: driverData.insuranceCertificateVerify,
          drivingLicenseVerify: driverData.drivingLicenseVerify,
          fcCertificateVerify: driverData.fcCertificateVerify,
          permitCertificateVerify: driverData.permitCertificateVerify,
          policeVerificationCertificateVerify:
            driverData.policeVerificationCertificateVerify,
          adhaarCardVerify: driverData.adhaarCardVerify,
          pollutionCertificateVerify: driverData.pollutionCertificateVerify,
          vehicleImageVerify: driverData.vehicleImageVerify
        };
        setValidApprovalButton(
          Object.values(documentStatus).every(
            (value) => value !== 0 && value !== 2 && value !== 3
          )
        );
        setValidRejectButton(
          Object.values(documentStatus).some((value) => value === 0)
        );


      } else {
        console.error("Error fetching driver data:", data.message);
      }
    } catch (error) {
      if (error.response && error.response.status === 401 || error.response.status === 403) {
        localStorage.clear();
        window.location.href = "/login";
      } else {
        console.error("Error:", error);
      }
      return error.response && error.response.data;
    }
  };
  const handleProfileImage = async (e) => {
    const formData = new FormData();
    const files = e.target.files;
    formData.append("images", files[0]);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/driver/uploadDocument`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "x-access-token": localStorage.getItem("CC_ADMIN_TOKEN"),
            "email": localStorage.getItem("CC_ADMIN_EMAIL"),
            "usertype": localStorage.getItem("CC_ADMIN_USERTYPE")
          },
        }
      );

      if (response.data.status && response.data.data) {
        setProfileImage(response.data.data[0]);
      }
    } catch (error) {
      if (error.response && error.response.status === 401 || error.response.status === 403) {
        localStorage.clear();
        window.location.href = "/login";
      } else {
        console.error("Error:", error);
      }
      return error.response && error.response.data;
    }
  };

  return (
    <>
      <PageLayout
        title={driverName}
        description={<></>}
        buttonWidth={"120px"}
        BreadcrumbsUrl={BreadcrumbsUrl}
      >
        <div
          className="md:pr-4"
          style={{ height: "calc(100vh - 150px)", overflowY: "scroll" }}
        >
          <div className="md:absolute top-8 right-8 pl-3 md:pl-0 w-full flex md:justify-end  items-center">
            <div className="   flex items-center gap-3">
              {(userType === "superAdminPlus" || userType === "superAdmin") &&
                <Button
                  text={"Delete Driver"}
                  textColor={"#fff"}
                  width={"120px"}
                  height={"40px"}
                  bgColor={"#FF0000"}
                  borderColor={"#fff"}
                  fontSize={"13px"}
                  onClick={() => {
                    openModal("delete", driverIdForFetch);
                  }}
                />
              }
              {driverVerifyStatus === 0 && (
                <button
                  onClick={() => {

                    openModal("accept", driverIdForFetch);

                  }}
                  className={`bg-[#175CD3] text-white rounded-md px-6 py-2 
              ${!validApprovalButton && "cursor-not-allowed opacity-50"}`}
                  disabled={!validApprovalButton}
                >
                  Approval
                </button>
              )}
            </div>
          </div>

          <PersonalDetailsComponent
            driverImage={driverImage}
            profileImage={profileImage}
            driverId={driverId}
            driverName={driverName}
            setDriverName={setDriverName}
            mobileNumber={mobileNumber}
            setMobileNumber={setMobileNumber}
            vehicleImage={vehicleImage}
            vehicleNumber={vehicleNumber}
            setVehicleNumber={setVehicleNumber}
            vehicleName={vehicleName}
            setVehicleName={setVehicleName}
            vehicleType={vehicleType}
            setVehicleType={setVehicleType}
            allVehicle={allVehicle}
            isEditable1={isEditable1}
            setAddDriverModal={setAddDriverModal}
            addDriverModal={addDriverModal}
            setIsEditable1={setIsEditable1}
            fetchDriverData={fetchDriverData}
            handleSubmit={handleSubmit}
            driverNameValidation={driverNameValidation}
            mobileNumberValidation={mobileNumberValidation}
            vehicleNameValidation={vehicleNameValidation}
            vehicleNumberValidation={vehicleNumberValidation}
            vehicleTypeValidation={vehicleTypeValidation}
            content={content}
          />

          <AddressDetailsComponent
            isEditable2={isEditable2}
            setIsEditable2={setIsEditable2}
            tempStreetAddress={tempStreetAddress}
            setTempStreetAddress={setTempStreetAddress}
            tempStreetAddressValidation={tempStreetAddressValidation}
            tempHouseNumber={tempHouseNumber}
            setTempHouseNumber={setTempHouseNumber}
            tempHouseNumberValidation={tempHouseNumberValidation}
            tempState={tempState}
            setTempState={setTempState}
            tempStateValidation={tempStateValidation}
            states={states}
            tempDistrict={tempDistrict}
            setTempDistrict={setTempDistrict}
            tempDistrictValidation={tempDistrictValidation}
            districts={districts}
            tempPincode={tempPincode}
            setTempPincode={setTempPincode}
            tempPincodeValidation={tempPincodeValidation}
            permStreetAddress={permStreetAddress}
            setPermStreetAddress={setPermStreetAddress}
            permStreetAddressValidation={permStreetAddressValidation}
            permHouseNumber={permHouseNumber}
            setPermHouseNumber={setPermHouseNumber}
            permHouseNumberValidation={permHouseNumberValidation}
            permState={permState}
            setPermState={setPermState}
            permStateValidation={permStateValidation}
            permDistrict={permDistrict}
            setPermDistrict={setPermDistrict}
            permDistrictValidation={permDistrictValidation}
            permPincode={permPincode}
            setPermPincode={setPermPincode}
            permPincodeValidation={permPincodeValidation}
            fetchDriverData={fetchDriverData}
            handleSubmit={handleSubmit}
            setAddDriverModal2={setAddDriverModal2}
            addDriverModal2={addDriverModal2}
            content={content}
          />
          <DocumentDetailsComponent
            driverIdForFetch={driverIdForFetch}
            setAddDriverModal3={setAddDriverModal3}
            addDriverModal3={addDriverModal3}
            rcCopyFrontFile={rcCopyFrontFile}
            setRcCopyFrontFile={setRcCopyFrontFile}
            rcCopyFrontFileName={rcCopyFrontFileName}
            setRcCopyFrontFileName={setRcCopyFrontFileName}
            setRcCopyFrontFileUrls={setRcCopyFrontFileUrls}
            rcCopyFrontError={rcCopyFrontError}
            isEditable3={isEditable3}
            rcCopyBackFile={rcCopyBackFile}
            setRcCopyBackFile={setRcCopyBackFile}
            rcCopyBackFileName={rcCopyBackFileName}
            setRcCopyBackFileName={setRcCopyBackFileName}
            setRcCopyBackFileUrls={setRcCopyBackFileUrls}
            rcCopyBackError={rcCopyBackError}
            driverVerifyStatus={driverVerifyStatus}
            rcCopyRemark={rcCopyRemark}
            setLatestReason={setLatestReason}
            setRcCopyRemark={setRcCopyRemark}
            rejectDriver={rejectDriver}
            rejectFunction={rejectFunction}
            pendingFunction={pendingFunction}
            validRejectButton={validRejectButton}
            acceptFunction={acceptFunction}
            insuranceFile={insuranceFile}
            setInsuranceFile={setInsuranceFile}
            setInsuranceFileUrl={setInsuranceFileUrl}
            insuranceFileName={insuranceFileName}
            setInsuranceFileName={setInsuranceFileName}
            insuranceExpDate={insuranceExpDate}
            setInsuranceExpDate={setInsuranceExpDate}
            insuranceExpDateError={insuranceExpDateError}
            insuranceRemark={insuranceRemark}
            setInsuranceRemark={setInsuranceRemark}
            drivingLicenseFrontFile={drivingLicenseFrontFile}
            setDrivingLicenseFrontFile={setDrivingLicenseFrontFile}
            drivingLicenseFrontFileName={drivingLicenseFrontFileName}
            setDrivingLicenseFrontFileName={setDrivingLicenseFrontFileName}
            setDrivingLicenseFrontFileUrls={setDrivingLicenseFrontFileUrls}
            drivingLicenseFrontError={drivingLicenseFrontError}
            licenseBatchExp={licenseBatchExp}
            setLicenseBatchExp={setLicenseBatchExp}
            licenseBatchExpError={licenseBatchExpError}
            drivingLicenseRemark={drivingLicenseRemark}
            setLicenseNumberRemark={setLicenseNumberRemark}
            drivingLicenseBackFile={drivingLicenseBackFile}
            setDrivingLicenseBackFile={setDrivingLicenseBackFile}
            drivingLicenseBackFileName={drivingLicenseBackFileName}
            setDrivingLicenseBackFileName={setDrivingLicenseBackFileName}
            setDrivingLicenseBackFileUrls={setDrivingLicenseBackFileUrls}
            drivingLicenseBackError={drivingLicenseBackError}
            drivingLicense={drivingLicense}
            setDrivingLicense={setDrivingLicense}
            drivingLicenseValidation={drivingLicenseValidation}
            fcCopyFile={fcCopyFile}
            setFcCopyFileUrls={setFcCopyFileUrls}
            setFcCopyFile={setFcCopyFile}
            fcCopyFileName={fcCopyFileName}
            setFcCopyFileName={setFcCopyFileName}
            fcCopyError={fcCopyError}
            fcExpiryDate={fcExpiryDate}
            setFcExpiryDate={setFcExpiryDate}
            fcCopyRemark={fcCopyRemark}
            setFcCopyRemark={setFcCopyRemark}
            PermitAndTaxFile={PermitAndTaxFile}
            setPermitAndTaxFileUrl={setPermitAndTaxFileUrl}
            setPermitAndTaxFile={setPermitAndTaxFile}
            PermitAndTaxFileName={PermitAndTaxFileName}
            setPermitAndTaxFileName={setPermitAndTaxFileName}
            permitExpDate={permitExpDate}
            setPermitExpDate={setPermitExpDate}
            permitExpDateError={permitExpDateError}
            permitAndTaxRemark={permitAndTaxRemark}
            setPermitAndTaxRemark={setPermitAndTaxRemark}
            pollutionCertificateFile={pollutionCertificateFile}
            setPollutionCertificateFile={setPollutionCertificateFile}
            setPollutionCertificateFileUrls={setPollutionCertificateFileUrls}
            pollutionCertificateFileName={pollutionCertificateFileName}
            setPollutionCertificateFileName={setPollutionCertificateFileName}
            pollutionCertificateError={pollutionCertificateError}
            pollutionVerificationExpDate={pollutionVerificationExpDate}
            setPollutionVerificationExpDate={setPollutionVerificationExpDate}
            pollutionCertificateRemark={pollutionCertificateRemark}
            setPollutionCertificateRemark={setPollutionCertificateRemark}
            policeCertificateFile={policeCertificateFile}
            setPoliceCertificateFile={setPoliceCertificateFile}
            policeCertificateFileName={policeCertificateFileName}
            setPoliceCertificateFileName={setPoliceCertificateFileName}
            setPoliceCertificateFileUrls={setPoliceCertificateFileUrls}
            policeCertificateError={policeCertificateError}
            policeVerificationExpDate={policeVerificationExpDate}
            setPoliceVerificationExpDate={setPoliceVerificationExpDate}
            policeCertificateRemark={policeCertificateRemark}
            setPoliceCertificateRemark={setPoliceCertificateRemark}
            aadharCardFrontFile={aadharCardFrontFile}
            setAadharCardFrontFile={setAadharCardFrontFile}
            aadharCardFrontFileName={aadharCardFrontFileName}
            setAadharCardFrontFileName={setAadharCardFrontFileName}
            setAadharCardFrontFileUrls={setAadharCardFrontFileUrls}
            aadharCardFrontError={aadharCardFrontError}
            aadharNumber={aadharNumber}
            setAadharNumber={setAadharNumber}
            aadharNumberValidation={aadharNumberValidation}
            aadharCardRemark={aadharCardRemark}
            setAadharCardRemark={setAadharCardRemark}
            aadharCardBackFile={aadharCardBackFile}
            setAadharCardBackFile={setAadharCardBackFile}
            aadharCardBackFileName={aadharCardBackFileName}
            setAadharCardBackFileName={setAadharCardBackFileName}
            setAadharCardBackFileUrls={setAadharCardBackFileUrls}
            aadharCardBackError={aadharCardBackError}
            setDrivingLicenseRemark={setDrivingLicenseRemark}
            content={content}
            setRcCopyFrontError={setRcCopyFrontError}
            setRcCopyBackError={setRcCopyBackError}
            setInsuranceExpDateError={setInsuranceExpDateError}
            setFcCopyError={setFcCopyError}
            setPermitExpDateError={setPermitExpDateError}
            setPollutionCertificateError={setPollutionCertificateError}
            setPoliceCertificateError={setPoliceCertificateError}
            setAadharCardFrontError={setAadharCardFrontError}
            setAadharCardBackError={setAadharCardBackError}
            setDrivingLicenseFrontError={setDrivingLicenseFrontError}
            setDrivingLicenseBackError={setDrivingLicenseBackError}
            setDrivingLicenseValidation={setDrivingLicenseValidation}
            setLicenseBatchExpError={setLicenseBatchExpError}
            handleSubmit={handleSubmit}
            setAadharNumberValidation={setAadharNumberValidation}
            documentStatus={documentStatus}
            setDocumentStatus={setDocumentStatus}
            fetchDriverData={fetchDriverData}
            vehicleFrontViewWithDriverError={vehicleFrontViewWithDriverError}
            vehicleFrontImageWithNumberPlateError={vehicleFrontImageWithNumberPlateError}
            vehicleBackImageWithNumberPlateError={vehicleBackImageWithNumberPlateError}
            vehicleFrontViewWithDriverFileName={vehicleFrontViewWithDriverFileName}
            vehicleFrontImageWithNumberPlateFileName={vehicleFrontImageWithNumberPlateFileName}
            vehicleBackImageWithNumberPlateFileName={vehicleBackImageWithNumberPlateFileName}
            vehicleFrontViewWithDriverFile={vehicleFrontViewWithDriverFile}
            vehicleFrontImageWithNumberPlateFile={vehicleFrontImageWithNumberPlateFile}
            vehicleBackImageWithNumberPlateFile={vehicleBackImageWithNumberPlateFile}
            setVehicleFrontViewWithDriverError={setVehicleFrontViewWithDriverError}
            setVehicleFrontImageWithNumberPlateError={setVehicleFrontImageWithNumberPlateError}
            setVehicleBackImageWithNumberPlateError={setVehicleBackImageWithNumberPlateError}
            setVehicleFrontViewWithDriverFileName={setVehicleFrontViewWithDriverFileName}
            setVehicleFrontImageWithNumberPlateFileName={setVehicleFrontImageWithNumberPlateFileName}
            setVehicleBackImageWithNumberPlateFileName={setVehicleBackImageWithNumberPlateFileName}
            setVehicleFrontViewWithDriverFile={setVehicleFrontViewWithDriverFile}
            setVehicleFrontImageWithNumberPlateFile={setVehicleFrontImageWithNumberPlateFile}
            setVehicleBackImageWithNumberPlateFile={setVehicleBackImageWithNumberPlateFile}
            vehicleImageRemark={vehicleImageRemark}
            setVehicleImageRemark={setVehicleImageRemark}

            vehicleFrontViewWithDriverUrl={vehicleFrontViewWithDriverUrl}
            vehicleFrontImageWithNumberPlateUrl={vehicleFrontImageWithNumberPlateUrl}
            vehicleBackImageWithNumberPlateUrl={vehicleBackImageWithNumberPlateUrl}
            setVehicleFrontViewWithDriverUrl={setVehicleFrontViewWithDriverUrl}
            setVehicleFrontImageWithNumberPlateUrl={setVehicleFrontImageWithNumberPlateUrl}
            setVehicleBackImageWithNumberPlateUrl={setVehicleBackImageWithNumberPlateUrl}
          />

          {WalletDetails()}
          {TripDetails()}
          {CreateDetails()}
        </div>
      </PageLayout>
      <Modal
        title={
          <>
            <div className="flex gap-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                viewBox="0 -960 960 960"
                width="24"
                fill="orange"
              >
                <path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" />
              </svg>
              <h1 className="text-lg">Confirmation</h1>
            </div>
          </>
        }
        open={isModalOpen}
        onCancel={handleCancel}
        okText={modalAction === "accept" ? "Accept" : "Reject"}
        cancelText="Cancel"
        footer={() => (
          <>
            <div className="flex gap-3 justify-end pt-3">
              <Button
                text={"Cancel"}
                textColor={"#1849A9"}
                width={"80px"}
                height={"30px"}
                bgColor={"#fff"}
                borderColor={"#1849A9"}
                fontSize={"13px"}
                onClick={() => handleCancel()}
              />
              <Button
                text={"Ok"}
                textColor={"#fff"}
                width={"80px"}
                height={"30px"}
                fontSize={"13px"}
                onClick={() => {
                  handleOk();
                }}
              />
            </div>
          </>
        )}
      >
        <div className="pr-5 pt-5  ">
          <h1 className="text-sm md:text-base">
            Are you sure want to{" "}
            <em>
              <b>
                {modalAction === "accept" && "Accept Driver"}
                {modalAction === "reject" && "Reject Driver"}
                {modalAction === "delete" && "Delete Driver"}
              </b>
            </em>{" "}
            ?
          </h1>
        </div>
        {modalAction === "reject" && (
          <div className=" w-full flex  flex-col py-3">
            <Input
              placeholder="Enter rejection reason"
              label={"Reject Reason"}
              value={rejectReason}
              onChange={(e) => setRejectReason(e.target.value)}
            />
          </div>
        )}
        {modalAction === "accept" && (
          <div className=" w-full flex justify-center py-2 items-center pt-4 md:pt-0">
            <div className="   flex   flex-col gap-2 md:py-3">
              <div className="flex flex-wrap md:gap-8 gap-5 items-center ">
                <DropDown
                  label={"Document Type"}
                  placeholder={"Select document type"}
                  displayValue={"name"}
                  data={DocumentExpiryDetails}
                  value={manualDocumentSelected}
                  setValue={setManualDocumentSelected}
                  mandate={true}
                />
                <DateInput
                  label={"Expiry Date"}
                  placeholder={"Expiry Date of Document"}
                  value={manualDocumentDate ? DateUtils.convertDate(manualDocumentDate) : manualDocumentDate}
                  mandate={true}
                  disabled={true}
                />
              </div>
            </div>
          </div>
        )}
      </Modal>

      <Modal
        title={
          <>
            <img src={addTrip} width={40} alt="" />
          </>
        }
        visible={addDriverModal}
        width={"1000px"}
        closeIcon={null}
        onCancel={() => {
          setAddDriverModal(false);
          fetchDriverData();
        }}
        centered
        footer={null}
      >
        <AddDriverModal
          addDriverModal={addDriverModal}
          setAddDriverModal={setAddDriverModal}
          routeId={routeId}
          setRouteId={setRouteId}
          routeValidation={routeValidation}
          setRouteValidation={setRouteValidation}
          driverName={driverName}
          setDriverName={setDriverName}
          driverId={driverId}
          driverNameValidation={driverNameValidation}
          setDriverNameValidation={setDriverNameValidation}
          mobileNumber={mobileNumber}
          setMobileNumber={setMobileNumber}
          mobileNumberValidation={mobileNumberValidation}
          setMobileNumberValidation={setMobileNumberValidation}
          vehicleName={vehicleName}
          setVehicleName={setVehicleName}
          vehicleNumber={vehicleNumber}
          setVehicleNumber={setVehicleNumber}
          vehicleType={vehicleType}
          setVehicleType={setVehicleType}
          allVehicle={allVehicle}
          vehicleNameValidation={vehicleNameValidation}
          setVehicleNameValidation={setVehicleNameValidation}
          vehicleNumberValidation={vehicleNumberValidation}
          setVehicleNumberValidation={setVehicleNumberValidation}
          vehicleTypeValidation={vehicleTypeValidation}
          setVehicleTypeValidation={setVehicleTypeValidation}
          profileImage={profileImage}
          profileImageRef={profileImageRef}
          handleProfileImage={handleProfileImage}
          handleSubmit={handleSubmit}
        />
      </Modal>
      <Modal
        title={
          <>
            <img src={addTrip} width={40} alt="" />
          </>
        }
        visible={addDriverModal2}
        width={"1000px"}
        onCancel={() => {
          setAddDriverModal2(false);
          fetchDriverData();
        }}
        footer={null}
      >
        <AddAddressModal
          tempStreetAddress={tempStreetAddress}
          setTempStreetAddress={setTempStreetAddress}
          tempHouseNumber={tempHouseNumber}
          setTempHouseNumber={setTempHouseNumber}
          tempStreetAddressValidation={tempStreetAddressValidation}
          setTempStreetAddressValidation={setTempStreetAddressValidation}
          tempHouseNumberValidation={tempHouseNumberValidation}
          setTempHouseNumberValidation={setTempHouseNumberValidation}
          tempState={tempState}
          setTempState={setTempState}
          tempStateValidation={tempStateValidation}
          setTempStateValidation={setTempStateValidation}
          tempDistrict={tempDistrict}
          setTempDistrict={setTempDistrict}
          tempDistrictValidation={tempDistrictValidation}
          setTempDistrictValidation={setTempDistrictValidation}
          tempPincode={tempPincode}
          setTempPincode={setTempPincode}
          tempPincodeValidation={tempPincodeValidation}
          setTempPincodeValidation={setTempPincodeValidation}
          isPermanentAddressSame={isPermanentAddressSame}
          setIsPermanentAddressSame={setIsPermanentAddressSame}
          permStreetAddress={permStreetAddress}
          setPermStreetAddress={setPermStreetAddress}
          permStreetAddressValidation={permStreetAddressValidation}
          setPermStreetAddressValidation={setPermStreetAddressValidation}
          permHouseNumber={permHouseNumber}
          setPermHouseNumber={setPermHouseNumber}
          permHouseNumberValidation={permHouseNumberValidation}
          setPermHouseNumberValidation={setPermHouseNumberValidation}
          permState={permState}
          setPermState={setPermState}
          permStateValidation={permStateValidation}
          setPermStateValidation={setPermStateValidation}
          permDistrict={permDistrict}
          setPermDistrict={setPermDistrict}
          permDistrictValidation={permDistrictValidation}
          setPermDistrictValidation={setPermDistrictValidation}
          permPincode={permPincode}
          setPermPincode={setPermPincode}
          permPincodeValidation={permPincodeValidation}
          setPermPincodeValidation={setPermPincodeValidation}
          states={states}
          districts={districts}
          handleSubmit={handleSubmit}
          setAddDriverModal2={setAddDriverModal2}
          fetchDriverData={fetchDriverData}
        />
      </Modal>


    </>
  );
}
