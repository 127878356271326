import { useState } from "react";
import { Link } from "react-router-dom";
import Table from "../../reusable/Table/Table";
import TableHead from "../../reusable/Table/TableHead";
import TableCell from "../../reusable/Table/TableCell";
import DropDown from "../../reusable/DropDown";
import Input from "../../reusable/Input";
import DateUtils from "../../../pages/utils/DateUtils";

export default function SignupReferralEarningsTable({ rows, rowsPerPage, page }) {

    const columns = [
        {
            id: "friendId",
            name: <TableHead>Friend ID</TableHead>,
            cell: (row) => (
                <TableCell>
                    <Link
                        className=" text-primary-blue"
                        to={`/customer-management/${row?.customerUser?.userID}`}
                        state={{ mobileNumber: row?.customerUser?.mobileNumber }}
                    >
                        {row?.customerUser?.userID}
                    </Link>
                </TableCell>
            ),
        },
        {
            id: "friendName",
            name: <TableHead>Friend Name</TableHead>,
            cell: (row) => <TableCell>{row?.customerUser?.userName}</TableCell>,
        },
        {
            id: "friendsMobileNumber",
            name: <TableHead width="180px">Friend Mobile Number</TableHead>,
            cell: (row) => <TableCell width="180px">{row?.customerUser?.mobileNumber}</TableCell>,
        },

        {
            id: "signupDate",
            name: <TableHead>Signup Date</TableHead>,
            cell: (row) => <TableCell>{DateUtils.convertDate(row?.date)}</TableCell>,
        },
        {
            id: "pointsEarned",
            name: <TableHead>Points Earned</TableHead>,
            cell: (row) => <TableCell>₹ {row?.amount?.toLocaleString('hi')}</TableCell>,
        },
    ];

    return (
        <div className="w-full removeScrollBar pb-5">
            <Table columns={columns} rows={rows} rowsPerPage={rowsPerPage} page={page} rowCount={10} isRowsPerPage={true} />
        </div>
    );
}
