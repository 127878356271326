import { PieChart, pieArcLabelClasses } from "@mui/x-charts/PieChart";
import filter from "../../../assets/filter.svg";
import { useState } from "react";
import FilterDates from "../../../components/reusable/FilterDates";
import MultiSelectFilter from "../../../components/reusable/MultiSelectFilterForCheckbox";
import { message } from "antd";
import FilterLayout from "../../../components/layouts/FilterLayout";

export default function DriverManagementSummary({
  data,
  filterDate,
  setFilterDate
}) {
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  // Function to update a specific filter
  const updateFilter = (filterName, value) => {
    setFilterDate((prevFilters) => ({
      ...prevFilters,
      [filterName]: value,
    }));
  };

  const handleClearFilter = () => {
    message.success("cleared filters of Trip Summary");
    // setFilters({
    //   vehicleType: [],
    //   tripType: [],
    // })
    setFilterDate({
      firstDate: "",
      secondDate: "",
    });
  };

  const filterContent = [
    {
      title: "Date",
      body: (
        <div>
          {" "}
          <FilterDates
            firstDateLabel={"Start Date"}
            secondDateLabel={"End Date"}
            date={filterDate}
            setDate={setFilterDate}
            isToDate={true}
            mandate
          />{" "}
        </div>
      ),
    },
  ];

  const sizing = {
    margin: { right: 1 },
    width: 250,
    height: 100,
    legend: { hidden: true },
  };

  const sizing2 = {
    margin: { right: 1 },
    width: 250,
    height: 100,
    legend: { hidden: true },
  };

  // const TOTAL = data.map((item) => item.value).reduce((a, b) => a + b, 0);

  const getArcLabel = (params) => {
    return params.value
  };

  return (
    <>
      <div className="w-1/4 shadow-sm pt-4 pl-6  hidden lg:flex  flex-auto border rounded-md relative">
        <div className="absolute m-4 border border-border-gray rounded-lg top-0 right-0">
          <FilterLayout
            isFilterOpen={isFilterOpen}
            setIsFilterOpen={setIsFilterOpen}
            content={filterContent}
            handleClearFilter={handleClearFilter}
          />
        </div>
        <div className="flex flex-col">
          <p className="text-lg font-semibold pb-2 w-fit">Driver Summary</p>
          <PieChart
            series={[
              {
                data,
                arcLabel: getArcLabel,
                innerRadius: 42,
                outerRadius: 110,
              },
            ]}
            sx={{
              [`& .${pieArcLabelClasses.root}`]: {
                fill: "white",
                fontSize: 12,
              },
            }}
            {...sizing}
          />
        </div>
        <div className="flex flex-col gap-3 justify-center items-start mx-auto font-semibold">
          {data?.map((item, index) => (
            <div key={index} className="flex gap-2 justify-center items-center">
              <div className="w-3 h-3 rounded-full" style={{ backgroundColor: item.color }} />
              <p>{item.label}</p>
            </div>
          ))}
        </div>
      </div>
      <div className="w-1/4 shadow-lg pt-4 pl-6  flex lg:hidden  flex-auto border rounded-md relative">
        <div className="absolute m-4 border border-border-gray rounded-lg top-0 right-0">
          <FilterLayout
            isFilterOpen={isFilterOpen}
            setIsFilterOpen={setIsFilterOpen}
            content={filterContent}
            handleClearFilter={handleClearFilter}
          />
        </div>
        <div className="flex flex-col">
          <p className="text-lg font-semibold pb-2 w-fit">Driver Summary</p>

          <PieChart
            series={[
              {
                data,
                arcLabel: getArcLabel,
                innerRadius: 42,
                outerRadius: 110,
              },
            ]}
            sx={{
              [`& .${pieArcLabelClasses.root}`]: {
                fill: "white",
                fontSize: 12,
              },
            }}
            {...sizing2}
          />
          <div className="flex flex-col pb-10 gap-3 justify-center items-start mx-auto font-semibold">
            {data?.map((item, index) => (
              <div key={index} className="flex gap-2 justify-center items-center">
                <div className="w-3 h-3 rounded-full" style={{ backgroundColor: item.color }} />
                <p>{item.label}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
