import { useContext, useEffect, useRef, useState } from "react";
import PageLayout from "../../components/layouts/PageLayout";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import MobileNumberInput from "../../components/reusable/MobileNumberInput";
import Input from "../../components/reusable/Input";
import DropDown from "../../components/reusable/DropDown";
import DateInput from "../../components/reusable/DateInput";
import TimeInput from "../../components/reusable/TimeInput";
import { Card, Modal, Popover, message } from "antd";
import Button from "../../components/reusable/Button";
import { getCustomerByMobileNumber, updateCustomer } from "../../services/CustomerManagementServices";
import DateUtils from "../utils/DateUtils";
import { getDistricts, getStates } from "../../services/DriverManagementServices";
import { validateFields } from "./validations/validate";
import SuccessModal from "../../components/reusable/mdoal/SuccessModal";
import ConfirmModal from "../../components/reusable/mdoal/ConfirmModal";
import TripReferralEarningsTable from "../../components/tables/customer-management/TripReferralEarningTable";
import SignupReferralEarningsTable from "../../components/tables/customer-management/SignupReferralEarningsTable";
import TripCashbaskEarningsTable from "../../components/tables/customer-management/TripCashbaskEarningsTable";
import EarningsRedemptionTable from "../../components/tables/customer-management/EarningsRedemptionTable";
import driverImage from "../../assets/profileImage.png";
import Edit from '../../assets/edit_pencil.svg';
import axios from "axios";
import CustomerDetailsCard from "./CustomerDetailsCard"

export default function CustomerDetails() {

    const [userType, setUserType] = useState("");

    useEffect(() => {
        setUserType(localStorage.getItem("CC_ADMIN_USERTYPE"));
    }, []);

    const { id, tripTypeParams } = useParams();
    const navigate = useNavigate();
    const [customerDetails, setCustomerDetails] = useState();
    const [states, setStates] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [customer, setCustomer] = useState({
        customerId: '',
        profileImageUrl: "",
        customerName: "",
        customerPhoneNumber: "",
        customerEmail: "",
        customerState: {},
        customerDistrict: {},
        customerStreet: "",
        customerHouseNumber: "",
        sum: {},
        tripRefral: [],
        signUpRefral: [],
        cashBackRefral: [],
        earningsRedemption: []
    })

    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);

    const [isEdit, setIsEdit] = useState(false);
    const [isEditAddress, setIsEditAddress] = useState(false);
    let { state } = useLocation();

    const [confirmModal, setConfirmModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);

    const [profileImage, setProfileImage] = useState(undefined);
    const profileImageRef = useRef();

    const [blockModal, setBlockModal] = useState(false)
    const [blockComment, setBlockComment] = useState("")

    async function handleBlockCustomer(row) {
        try {
            const response = await updateCustomer({ id: id, userStatus: 0, blockComment: blockComment });
            message.success('Customer Blocked!');
            navigate("/customers")
        }
        catch (err) {
            message.error(err.response.data.message || err.message);
        }
    }

    const handleProfileImage = async (e) => {
        const formData = new FormData();
        const files = e.target.files;
        formData.append("images", files[0]);
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_BASE_URL}/driver/uploadDocument`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        "x-access-token": localStorage.getItem("CC_ADMIN_TOKEN"),
                        "email": localStorage.getItem("CC_ADMIN_EMAIL"),
                        "usertype": localStorage.getItem("CC_ADMIN_USERTYPE")
                    },
                }
            );

            if (response.data.status && response.data.data) {
                setProfileImage(response.data.data[0]);
            }
        } catch (error) {
            if (error.response && error.response.status === 401 || error.response.status === 403) {
                localStorage.clear();
                window.location.href = "/login";
            } else {
                console.error("Error:", error);
            }
            return error.response && error.response.data;
        }
    };

    async function getCustomer(mobileNumber) {
        try {
            const response = await getCustomerByMobileNumber(mobileNumber);
            if (response.data) {
                setCustomerDetails(response.data);
                console.log({ districtName: response?.data?.district })
                setCustomer({
                    ...customer,
                    customerId: response.data.customer.id,
                    customerName: response.data.customer.userName || "",
                    customerPhoneNumber: response.data.customer.mobileNumber || "",
                    customerEmail: response.data.customer.email || "",
                    customerState: states?.find(s => s.stateName === response.data.customer.state) || {},
                    customerDistrict: { districtName: response?.data?.customer?.district } || {},
                    customerStreet: response.data.customer.street || "",
                    customerHouseNumber: response.data.customer.houseNumber || "",
                    sum: response.data.sum,
                    tripRefral: response.data.tripRefral,
                    signUpRefral: response.data.signUpRefral,
                    cashBackRefral: response.data.cashBackRefral,
                    earningsRedemption: response.data.earningsRedemption
                });
                setProfileImage(response?.data?.customer?.imageUrl);
            }
        }
        catch (err) {
            message.error(err.response.data.message || err.message);
        }
    }

    async function getState() {
        try {
            const response = await getStates();
            if (response.data) {
                setStates(response.data);
            }
        } catch (err) {
            message.error(
                err.response.data.message || err.response.data.error || err.message
            );
        }
    }

    async function getAllDistrict(item) {
        try {
            const response = await getDistricts(item);
            if (response.data) {
                setDistricts(response.data);
            }
        } catch (err) {
            message.error(
                err.response.data.message || err.response.data.error || err.message
            );
        }
    }

    async function updateCustomerDetails() {

        const fields = {
            customerName: customer.customerName,
            customerPhoneNumber: customer.customerPhoneNumber,
            customerEmail: customer.customerEmail
        }

        const errors = validateFields(fields);
        setErrors(errors)

        if (Object.keys(errors).length === 0) {
            setLoading(true)
            try {
                const payload = {
                    id: +customer.customerId,
                    mobileNumber: customer?.customerPhoneNumber,
                    userName: customer?.customerName || "",
                    email: customer?.customerEmail || "",
                    state: customer?.customerState.stateName || "",
                    district: customer?.customerDistrict.districtName || "",
                    street: customer?.customerStreet || "",
                    houseNumber: customer?.customerHouseNumber || "",
                    imageUrl: profileImage || customerDetails?.imageUrl,
                    userStatus: customerDetails.userStatus,
                }
                const response = await updateCustomer(payload);
                if (response.data) {
                    setLoading(false)
                    message.success('Customer updated!');
                    setIsEdit(false)
                    setIsEditAddress(false)
                }
            } catch (err) {
                setLoading(false)
                message.error(
                    err.response.data.message || err.response.data.error || err.message
                );
            }
        }

    }

    useEffect(() => {
        getState();

    }, [])

    useEffect(() => {
        if (states) {
            getCustomer(state?.mobileNumber);
        }
    }, [states, id]);

    useEffect(() => {
        if (customerDetails?.state) {
            const id = states?.find(s => s.stateName === customerDetails?.state)?.id;
            if (id) {
                getAllDistrict(id)

            }
        }
    }, [customerDetails])

    const handleDelete = async () => {
        setLoading(true)
        try {
            let payload;
            if (customerDetails?.customer?.userStatus === 2) {
                payload = {
                    id: customerDetails.customer.id,
                    status: 0
                }
            } else {
                payload = {
                    id: customerDetails.customer.id,
                    userStatus: 2
                }
            }
            const response = await updateCustomer(payload)
            if (response.data) {
                setLoading(false)
                setConfirmModal(false)
                message.success('Customer Deleted!')
                setIsEdit(false)
                setIsEditAddress(false)
                navigate(-1)
                // setTimeout(() => {
                //     navigate("/customer-management")
                // }, 2000)
            }
        } catch (err) {
            setLoading(false)
            message.error(
                err.response.data.message || err.response.data.error || err.message
            );
        }
    };

    async function handleRevertCustomer() {
        try {
            const response = await updateCustomer({ id: customerDetails.id, userStatus: 1, blockComment: null });
            message.success('Customer Reverted!');
            getCustomer(customerDetails.mobileNumber);
        }
        catch (err) {
            message.error(err.resonse.data.message || err.message);
        }
    }

    const actionButton = customerDetails?.customer.id !== 1 ? (
        <div className="flex flex-col">
            {customerDetails?.customer?.userStatus === 0 && (
                <button
                    className="flex items-center hover:bg-gray-200 pl-2 rounded-md pr-3 gap-3 py-1"
                    onClick={() => handleRevertCustomer()}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 14 14" fill="none">
                        <path
                            d="M4.75 8.5L1 4.75M1 4.75L4.75 1M1 4.75H10C10.7956 4.75 11.5587 5.06607 12.1213 5.62868C12.6839 6.19129 13 6.95435 13 7.75V13"
                            stroke="#0365CF"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                    <p>Revert</p>
                </button>
            )}
            {/* {customerDetails?.customer?.userStatus === 1 && (
                <button
                    className="flex items-center hover:bg-gray-200 pl-2 rounded-md pr-3 gap-3 py-1"
                    onClick={() => handleBlockCustomer()}
                >
                    <svg fill="#ff0000" width="14" height="13" version="1.1" id="Layer_1" x="&amp;ns_extend;" i="&amp;ns_ai;" graph="&amp;ns_graphs;" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" viewBox="-2.4 -2.4 28.80 28.80" enable-background="new 0 0 24 24" space="preserve" stroke="#ff0000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <metadata> <sfw xmlns="&amp;ns_sfw;"> <slices> </slices> <slicesourcebounds width="505" height="984" bottomleftorigin="true" x="0" y="-984"> </slicesourcebounds> </sfw> </metadata> <g> <g> <g> <path d="M12,24C5.4,24,0,18.6,0,12S5.4,0,12,0s12,5.4,12,12S18.6,24,12,24z M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10 S17.5,2,12,2z"></path> </g> </g> <g> <g> <path d="M19.8,20.8c-0.3,0-0.5-0.1-0.7-0.3L3.5,4.9c-0.4-0.4-0.4-1,0-1.4s1-0.4,1.4,0l15.6,15.6c0.4,0.4,0.4,1,0,1.4 C20.3,20.7,20,20.8,19.8,20.8z"></path> </g> </g> </g> </g>
                    </svg>
                    <p>Block</p>
                </button>
            )} */}
            {
                (userType === "superAdminPlus" || userType === "superAdmin") &&
                <button
                    className="flex items-center hover:bg-gray-200 pl-2 rounded-md pr-3 gap-3 py-1"
                    onClick={() => setConfirmModal(!confirmModal)}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="13"
                        viewBox="0 0 15 14"
                        fill="none"
                    >
                        <path
                            d="M2.09473 3.49984H3.28863M3.28863 3.49984H12.8399M3.28863 3.49984V11.6665C3.28863 11.9759 3.41442 12.2727 3.63832 12.4915C3.86222 12.7103 4.1659 12.8332 4.48254 12.8332H10.4521C10.7687 12.8332 11.0724 12.7103 11.2963 12.4915C11.5202 12.2727 11.646 11.9759 11.646 11.6665V3.49984H3.28863ZM5.0795 3.49984V2.33317C5.0795 2.02375 5.20528 1.72701 5.42918 1.50821C5.65308 1.28942 5.95676 1.1665 6.2734 1.1665H8.66122C8.97786 1.1665 9.28154 1.28942 9.50544 1.50821C9.72934 1.72701 9.85513 2.02375 9.85513 2.33317V3.49984M6.2734 6.4165V9.9165M8.66122 6.4165V9.9165"
                            stroke="#ff2222"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                    <p>Delete</p>
                </button>
            }
        </div >
    ) : null;


    const summary = [
        {
            label: 'Total Reward Points',
            amount: `₹ ${customerDetails?.sum?.totalSumOfAmount?.toFixed(2)?.toLocaleString('hi') || 0}`
        },
        {
            label: 'Total Signup Referral',
            amount: `₹ ${customerDetails?.sum?.totalSumOfSignupAmount?.toFixed(2)?.toLocaleString('hi') || 0}`
        },
        {
            label: 'Total Trip Referral',
            amount: `₹ ${customerDetails?.sum?.totalSumOfTripAmount?.toFixed(2)?.toLocaleString('hi') || 0}`
        },
        {
            label: 'Total Trip Cashbask',
            amount: `₹ ${customerDetails?.sum?.totalSumOfCashBackAmount?.toFixed(2)?.toLocaleString('hi') || 0}`
        },
        {
            label: 'Total Redeemed Points',
            amount: `₹ ${customerDetails?.sum?.totalSumOfReedemedAmount?.toFixed(2)?.toLocaleString('hi') || 0}`
        },
        {
            label: 'Available Reward Points',
            amount: `₹ ${customerDetails?.sum?.totalAvailableRewardPoints?.toFixed(2)?.toLocaleString('hi') || 0}`
        }
    ]

    return (
        <>
            <PageLayout
                title={"Customer Management"}
                description={"View the customer details here."}
                buttonWidth={"120px"}
            // BreadcrumbsUrl={BreadcrumbsUrl}
            >
                <div className="absolute right-12 top-12">
                    <Popover
                        content={actionButton}
                        placement="leftTop"
                        trigger="click"
                        className=" cursor-pointer"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="4" height="16" viewBox="0 0 4 16" fill="none" class="cursor-pointer"><path d="M1.83333 8.5C2.29357 8.5 2.66667 8.1269 2.66667 7.66667C2.66667 7.20643 2.29357 6.83333 1.83333 6.83333C1.3731 6.83333 1 7.20643 1 7.66667C1 8.1269 1.3731 8.5 1.83333 8.5Z" stroke="#667085" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"></path><path d="M1.83333 2.66667C2.29357 2.66667 2.66667 2.29357 2.66667 1.83333C2.66667 1.3731 2.29357 1 1.83333 1C1.3731 1 1 1.3731 1 1.83333C1 2.29357 1.3731 2.66667 1.83333 2.66667Z" stroke="#667085" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"></path><path d="M1.83333 14.3333C2.29357 14.3333 2.66667 13.9602 2.66667 13.5C2.66667 13.0398 2.29357 12.6667 1.83333 12.6667C1.3731 12.6667 1 13.0398 1 13.5C1 13.9602 1.3731 14.3333 1.83333 14.3333Z" stroke="#667085" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                    </Popover>
                </div>
                <div className="relative h-[calc(100vh-200px)] md:h-[calc(100vh-150px)] overflow-y-auto pb-3 md:pb-0">
                    {customerDetails?.customer?.userStatus === 0 && (
                        <div className=" w-full px-1 flex  my-7 justify-center items-center">
                            <Card className=" w-full flex flex-col gap-6 shadow-sm rounded-md">
                                <div className="pb-6 w-full font-semibold text-start mt-1 text-red-500" style={{ fontSize: '16px' }}>
                                    Customer Blocked
                                </div>
                                <div className=" w-full">
                                    <p className="text-[13px]">Comments</p>
                                    <textarea
                                        name="blockComment"
                                        rows="2"
                                        disabled
                                        className="w-full border-border-gray border bg-[#c6c6c64a] text-[13px] rounded-md p-2 focus:outline-none focus:border-primary-blue"
                                        // placeholder="Enter comments for blocking"
                                        value={customerDetails?.customer?.blockComment || "-"}
                                    // onChange={(e) => setBlockComment(e.target.value)}
                                    // maxLength={200}
                                    />
                                </div>
                            </Card>
                        </div>
                    )}
                    {/* Personal Details card */}
                    <CustomerDetailsCard
                        customerDetails={customerDetails}
                        isEdit={isEdit}
                        setIsEdit={setIsEdit}
                        customer={customer}
                        setCustomer={setCustomer}
                        errors={errors}
                        states={states}
                        districts={districts}
                        getAllDistrict={getAllDistrict}
                        profileImage={profileImage}
                        driverImage={driverImage}
                        handleProfileImage={handleProfileImage}
                        updateCustomerDetails={updateCustomerDetails}
                        setErrors={setErrors}
                        loading={loading}
                        getCustomer={getCustomer}
                    />
                    {/*<div className=" w-full px-1 flex  my-7 justify-center items-center ">
                        <Card className=" w-full flex flex-col gap-6 shadow-sm rounded-md">
                            <div className=" w-full text-start mt-1 text-gray-800 " style={{ fontSize: '16px' }}>
                                Address Details
                            </div>
                            <div className="w-full flex justify-end items-center">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="15"
                                    height="14"
                                    viewBox="0 0 15 14"
                                    fill="none"
                                    onClick={() => setIsEditAddress(!isEditAddress)}
                                    className="cursor-pointer"
                                >
                                    <path
                                        d="M6.87014 2.33314H2.69147C2.37482 2.33314 2.07115 2.45606 1.84725 2.67485C1.62334 2.89364 1.49756 3.19039 1.49756 3.49981V11.6665C1.49756 11.9759 1.62334 12.2726 1.84725 12.4914C2.07115 12.7102 2.37482 12.8331 2.69147 12.8331H11.0488C11.3655 12.8331 11.6691 12.7102 11.893 12.4914C12.1169 12.2726 12.2427 11.9759 12.2427 11.6665V7.58314M11.3473 1.45814C11.5848 1.22608 11.9069 1.0957 12.2427 1.0957C12.5786 1.0957 12.9007 1.22608 13.1382 1.45814C13.3756 1.6902 13.5091 2.00495 13.5091 2.33314C13.5091 2.66133 13.3756 2.97608 13.1382 3.20814L7.4671 8.74981L5.07928 9.33314L5.67624 6.99981L11.3473 1.45814Z"
                                        stroke="#344054"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </div>
                            <>
                                <div className=" w-full gap-5  h-fit py-5 flex flex-wrap justify-between lg:grid lg:grid-cols-3">
                                    <DropDown
                                        label={"State"}
                                        placeholder={"Select state"}
                                        data={states}
                                        displayValue={'stateName'}
                                        value={customer?.customerState}
                                        setValue={(item) => {
                                            setCustomer(prev => ({ ...prev, customerState: item, customerDistrict: {} }));
                                            getAllDistrict(item.id);
                                        }}
                                        disabled={!isEditAddress ? true : false}
                                    />
                                    <DropDown
                                        label={"District"}
                                        placeholder={"Select district"}
                                        data={districts}
                                        displayValue={'districtName'}
                                        value={customer.customerDistrict}
                                        setValue={(item) => setCustomer(prev => ({ ...prev, customerDistrict: item }))}
                                        disabled={!isEditAddress ? true : false}
                                    />
                                    <Input
                                        label={"Street"}
                                        placeholder={"Enter street name"}
                                        value={customer?.customerStreet ? customer?.customerStreet : null}
                                        onChange={(e) => setCustomer(prev => ({ ...prev, customerStreet: e.target.value }))}
                                        disabled={!isEditAddress ? true : false}
                                    />
                                    <Input
                                        label={"House Number"}
                                        placeholder={"Enter house number"}
                                        value={customer?.customerHouseNumber ? customer?.customerHouseNumber : null}
                                        onChange={(e) => setCustomer(prev => ({ ...prev, customerHouseNumber: e.target.value }))}
                                        disabled={!isEditAddress ? true : false}
                                    />
                                </div>
                                {
                                    isEditAddress &&
                                    <div className="flex justify-center items-center gap-3 mt-5">
                                        <Button
                                            text={"Cancel"}
                                            width={"100px"}
                                            bgColor={"#fff"}
                                            height={"35px"}
                                            onClick={() => {
                                                setIsEdit(false);
                                                setErrors({});
                                            }}
                                            textColor={"#1849A9"}
                                            borderColor={"#1849A9"}
                                            fontSize={"12px"}
                                        />
                                        <Button
                                            text={"Update"}
                                            width={"100px"}
                                            bgColor={"#1849A9"}
                                            height={"35px"}
                                            onClick={updateCustomerDetails}
                                            textColor={"#fff"}
                                            fontSize={"12px"}
                                            loading={loading}
                                        />
                                    </div>
                                }
                            </>
                        </Card>
                            </div>*/}

                    {/* Summary */}
                    <div className="flex items-center gap-5 justify-center flex-wrap my-10">
                        {
                            summary.map((s, idx) => (
                                <div key={idx} className={`flex flex-col gap-3 justify-center items-center w-[315px] h-[160px]  rounded-[8px] ${idx === 5 ? 'bg-[#00A69A]' : 'bg-[#1849A9]'}`}>
                                    <p className="text-[18px] text-[#FFFFFF] font-[600]">{s.label}</p>
                                    <h1 className="text-[24px] text-[#FFFFFF] font-[600]">{s.amount}</h1>
                                </div>
                            ))
                        }
                    </div>


                    {/* Trip Referral Earnings Table */}
                    <div className=" w-full px-1 flex flex-col gap-5 justify-center items-center ">
                        <div className=" w-full text-start text-lg mt-1 font- text-gray-800 " style={{ fontSize: '16px' }}>
                            Trip Referral Earnings
                        </div>
                        <TripReferralEarningsTable
                            rows={customer?.tripRefral}
                            // rows={tripReferralEarnings}
                            rowsPerPage={10}
                            page={0}
                        />
                    </div>
                    {/* Signup Referral Earnings Table */}
                    <div className=" w-full px-1 flex flex-col gap-5 justify-center items-center ">
                        <div className=" w-full text-start text-lg mt-1 font- text-gray-800 " style={{ fontSize: '16px' }}>
                            Signup Referral Earnings
                        </div>
                        <SignupReferralEarningsTable
                            rows={customer?.signUpRefral}
                            // rows={SignupReferralEarnings}
                            rowsPerPage={10}
                            page={0}
                        />
                    </div>

                    {/* Trip Cashbask Earnings Table */}
                    <div className=" w-full px-1 flex flex-col gap-5 justify-center items-center ">
                        <div className=" w-full text-start text-lg mt-1 font- text-gray-800 " style={{ fontSize: '16px' }}>
                            Trip Cashback Earnings
                        </div>
                        <TripCashbaskEarningsTable
                            rows={customer?.cashBackRefral}
                            // rows={tripCashbackEarnings}
                            rowsPerPage={10}
                            page={0}
                        />
                    </div>

                    {/* Earnings Redemption Table */}
                    <div className=" w-full px-1 flex flex-col gap-5 justify-center items-center ">
                        <div className=" w-full text-start text-lg mt-1 font- text-gray-800 " style={{ fontSize: '16px' }}>
                            Earnings Redemption
                        </div>
                        <EarningsRedemptionTable
                            rows={customer?.earningsRedemption}
                            // rows={earningsRedemption}
                            rowsPerPage={10}
                            page={0}
                        />
                    </div>

                    {/* Creator Details card */}
                    <div className=" w-full px-1 flex  my-7 justify-center items-center ">
                        <Card className=" w-full flex flex-col gap-6 pb-4  shadow-sm rounded-md">
                            <div className=" w-full text-start text-lg mt-1 font- text-gray-800 " style={{ fontSize: '16px' }}>
                                Creater Details
                            </div>
                            <>
                                <div className=" w-full flex-wrap gap-5 h-fit py-4 flex justify-between items-center mt-3   ">
                                    <DateInput
                                        label={"Created date"}
                                        placeholder={"Select created date"}
                                        value={DateUtils.convertDate(customerDetails?.createdAtUnix?.split('T')[1]?.split('.')[0])}
                                        disabled={true}
                                    />
                                    <TimeInput
                                        label={"Created time"}
                                        placeholder={"Select created time"}
                                        value={DateUtils.getDateAndTimeFromUnixTimestamp(customerDetails?.customer?.createdAtUnix).split(' ')[1]}
                                        setValue={""}
                                        disabled={true}
                                    />
                                    <Input
                                        label={"Created by"}
                                        placeholder={"Enter created by"}
                                        value={"Super Admin"}
                                        disabled={true}
                                    />
                                </div>
                            </>
                        </Card>
                    </div>
                </div>
            </PageLayout>
            <ConfirmModal
                isOpen={confirmModal}
                setIsOpen={setConfirmModal}
                title={"Delete ?"}
                description={"Do you want to delete this customer?"}
                button1={"No"}
                button2={"Yes"}
                onClickButton1={() => setConfirmModal(!confirmModal)}
                onClickButton2={() => {
                    // setDeleteModal(!deleteModal);
                    handleDelete();
                }}
            />
            <SuccessModal
                isOpen={deleteModal}
                setIsOpen={setDeleteModal}
                animation="delete"
                title={"Customer deleted successfully."}
                onClose={() => navigate("/customer-management")}
            />
            <Modal
                title={
                    <>
                        <div className="flex gap-2 items-center">
                            <svg fill="#ff0000" version="1.1" id="Layer_1" x="&amp;ns_extend;" i="&amp;ns_ai;" graph="&amp;ns_graphs;" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="25px" height="25px" viewBox="-2.4 -2.4 28.80 28.80" enable-background="new 0 0 24 24" space="preserve" stroke="#ff0000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <metadata> <sfw xmlns="&amp;ns_sfw;"> <slices> </slices> <slicesourcebounds width="505" height="984" bottomleftorigin="true" x="0" y="-984"> </slicesourcebounds> </sfw> </metadata> <g> <g> <g> <path d="M12,24C5.4,24,0,18.6,0,12S5.4,0,12,0s12,5.4,12,12S18.6,24,12,24z M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10 S17.5,2,12,2z"></path> </g> </g> <g> <g> <path d="M19.8,20.8c-0.3,0-0.5-0.1-0.7-0.3L3.5,4.9c-0.4-0.4-0.4-1,0-1.4s1-0.4,1.4,0l15.6,15.6c0.4,0.4,0.4,1,0,1.4 C20.3,20.7,20,20.8,19.8,20.8z"></path> </g> </g> </g> </g></svg>
                            <h1 className="text-lg" style={{ fontSize: '16px', fontWeight: 'bold' }}>Block Customer</h1>
                        </div>
                    </>
                }
                visible={blockModal}
                width={"500px"}
                footer={null}
                onCancel={() => setBlockModal(false)}
            >
                <div className="my-6 flex flex-col gap-1">
                    <p>Comments</p>
                    <textarea
                        name="blockComment"
                        rows="4"
                        className="w-full border-border-gray border rounded-md p-2 focus:outline-none focus:border-primary-blue"
                        placeholder="Enter comments for blocking"
                        value={blockComment}
                        onChange={(e) => setBlockComment(e.target.value)}
                        maxLength={200}
                    />
                </div>
                <div className="flex justify-center">
                    <Button
                        text={"Block"}
                        textColor={"#fff"}
                        width={"100px"}
                        height={"32px"}
                        fontSize={"12px"}
                        onClick={() => {
                            handleBlockCustomer()
                            setBlockModal(false)
                        }}
                    />
                </div>
            </Modal>
        </>
    );
}
