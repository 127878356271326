import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Table from "../../reusable/Table/Table";
import TableHead from "../../reusable/Table/TableHead";
import TableCell from "../../reusable/Table/TableCell";
import DropDown from "../../reusable/DropDown";
import Input from "../../reusable/Input";
import CommonContext from "../../../context-storage/CommonServicesHandler";
import { Modal } from "antd";
import Button from "../../reusable/Button";

export default function Leads({ activeKey,
  page,
  rowCount,
  handleChangePage,
  handleChangeRowsPerPage,
  rowsPerPage,
}) {
  const [search, setSearch] = useState("");
  const [vehicleType, setVehicleType] = useState("");
  const [driverStatus, setDriverStatus] = useState("");
  const [employees, setEmployees] = useState([]);
  const [vehicleTypeDropDown, setVehicleTypeDropDown] = useState([]);
  const { tripTypeData, setTripMode, vehicleTypeData, allVehicle } = useContext(CommonContext);
  const [isRevertModalOpen, setIsRevertModalOpen] = useState(false);
  const [selectedRowForRevert, setSelectedRowForRevert] = useState(null);
  useEffect(() => {
    setVehicleTypeDropDown(allVehicle);
  }, [vehicleTypeData]);

  const driveStatusData =
    [
      {
        "id": "Part Time",
        "status": "Part Time"
      },
      {
        "id": "Dedicated",
        "status": "Dedicated"
      }
    ]

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/driver/getDrivers?pageSize=${rowsPerPage}&currentPage=${page + 1}&driverVerify=3&${search ? `&searchQuery=${search}` : ""}${vehicleType ? `&vehicleTypeID=${vehicleType.id}` : ""}`, {
          headers: {
            "x-access-token": localStorage.getItem("CC_ADMIN_TOKEN"),
            "email": localStorage.getItem("CC_ADMIN_EMAIL"),
            "usertype": localStorage.getItem("CC_ADMIN_USERTYPE")
          }
        }
        );
        const data = await response.json();
        if (data.data) {

          setEmployees(data.data);

        }
        else {
          setEmployees([]);
        }
      } catch (error) {
        if (error.response && error.response.status === 401 || error.response.status === 403) {
          localStorage.clear();
          window.location.href = "/login";
        } else {
          console.error("Error:", error);
        }
        return error.response && error.response.data;
      }
    };
    if (activeKey === 'leads') {
      fetchData();
    }

  }, [activeKey, vehicleType, search, driverStatus,
    page, rowsPerPage]);



  const columns = [
    {
      id: "driverId",
      name: <TableHead>Driver ID</TableHead>,
      cell: (row) => (
        <TableCell>
          <Link
            className=" text-primary-blue"
            to={`/driver-management/${activeKey}/${row.driverID}?id=${row.id}`}
          >
            {row.driverID}
          </Link>
        </TableCell>
      ),
    },
    {
      id: "driverName",
      name: <TableHead>Driver Name</TableHead>,
      cell: (row) => <TableCell>{row.driverName}</TableCell>,
    },
    {
      id: "vehicleNumber",
      name: <TableHead>Vehicle Number</TableHead>,
      cell: (row) => <TableCell>{row.vehicleNo}</TableCell>,
    },
    {
      id: "vehicleName",
      name: <TableHead>Vehicle Name</TableHead>,
      cell: (row) => <TableCell>{row.vehicleName}</TableCell>,
    },

    {
      id: "vehicleType",
      name: <TableHead>Vehicle Type</TableHead>,
      cell: (row) => <TableCell>{row.vehicleType.name}</TableCell>,
    },
    {
      id: "mobileNumber",
      name: <TableHead>Mobile Number</TableHead>,
      cell: (row) => <TableCell>{row.mobileNo}</TableCell>,
    },
    {
      id: "driverType",
      name: <TableHead>Wallet</TableHead>,
      cell: (row) => <TableCell>₹ {row.walletAvailableBalace.toLocaleString('hi')} /-</TableCell>,
    },
  ];



  return (
    <div className="w-full removeScrollBar pb-5">
      <div className="flex  flex-wrap  lg:gap-8 gap-2 w-full justify-center lg:flex-nowrap  lg:pl-2  lg:pr-10  lg:justify-start items-center mb-5  lg:mb-10  mt-2 lg:mt-6">
        <Input
          label={"Search"}
          type={"text"}
          placeholder={"name/contact number/vehicle number"}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <DropDown
          label={"Vehicle Type"}
          placeholder={"Select vehicle type"}
          displayValue={"name"}
          data={vehicleTypeDropDown}
          value={vehicleType}
          setValue={setVehicleType}
          selectAll
        />
        {/* <DropDown
          label={"Driver Status"}
          placeholder={"Select driver status"}
          displayValue={"id"}
          data={driveStatusData}
          value={driverStatus}
          setValue={setDriverStatus}
          mandate
        /> */}
      </div>
      <Table columns={columns} rows={employees} rowCount={rowCount || 0} page={page} handleChangePage={handleChangePage} handleChangeRowsPerPage={handleChangeRowsPerPage} rowsPerPage={rowsPerPage} />

    </div>
  );
}
