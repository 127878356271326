import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Table from "../../reusable/Table/Table";
import TableHead from "../../reusable/Table/TableHead";
import TableCell from "../../reusable/Table/TableCell";
import StatusCard from "../../reusable/Table/TripStatus";
import DropDown from "../../reusable/DropDown";
import DateInput from "../../reusable/DateInput";
import { Modal, Popover, message } from "antd";
import Input from "../../reusable/Input";
import Button from "../../reusable/Button";
import CommonContext from "../../../context-storage/CommonServicesHandler";
import { updateRide } from "../../../services/TripManagementServices";
import Filters from "./components/Filters";
import AssignDriverModal from "./components/manualAllotModal";
import { copyToClipboard } from "../../../pages/utils/utils";
import DateUtils from "../../../pages/utils/DateUtils";


export default function NewBookingTable({
  tripData,
  getRides,
  tripStatus,
  rowsPerPage,
  page,
  handleChangePage,
  handleChangeRowsPerPage,
  handleRideIdSearch,
  handleTripTypeFilter,
  handleCabTypeFilter,
  handleFromDateFilter,
  handleToDateFilter,
  rideId,
  vehicles,
  tripTypeData,
  tripTypeFilter,
  cabType,
  fromDate,
  toDate,
  rowCount,
}) {


  const [selectedRow, setSelectedRow] = useState(null);
  const [confirmationModal, setConfirmationModal] = useState({ state: false, action: "" })
  const [open, setOpen] = useState(false);

  const [openPopoverId, setOpenPopoverId] = useState(null);


  // Function to identify the particular action
  const [isManualAllotedModal, setIsManualAllotedModal] = useState(false);
  async function makeActions(row, action) {
    let actionDetails = { actionId: 0, message: '' };

    if (action === "copy") {
      copyToClipboard(row);
    } else if (action === 'accept') {
      actionDetails.actionId = 1;
      actionDetails.message = 'Ride moved to accepted';
    } else if (action === 'reject') {
      actionDetails.actionId = 6;
      actionDetails.message = 'Ride cancelled successfully!';
    } else if (action === 'fake') {
      actionDetails.actionId = 7;
      actionDetails.message = 'Ride moved to faked';
    } else {
      setIsManualAllotedModal(true);
    }

    if (action !== 'manualAllot' && action !== 'copy') {
      try {
        const response = await updateRide(row.id, { tripStatus: actionDetails.actionId });
        message.success(actionDetails.message);
        getRides(tripStatus, rowsPerPage, page + 1);
      } catch (error) {
        message.error(error.message || error.response.data.message || error.response.data.error);
      } finally {
        setOpenPopoverId(null);
      }
    } else {
      setOpenPopoverId(null);
    }
  }

  // Function to get the selected row
  function handlePopoverClick(row) {
    if (openPopoverId === row.rideId) {
      setOpenPopoverId(null);
    } else {
      setSelectedRow(row);
      setOpenPopoverId(row.rideId);
    }
  }

  // Function to make call to customer
  function makeCall(phoneNumber) {
    window.location.href = `tel:${phoneNumber}`;
  }


  const actionsContent = (
    <div className=" flex flex-col" style={{ fontFamily: "Poppins" }}>
      <div
        className=" flex items-center hover:bg-gray-200 pl-2 rounded-md cursor-pointer  pr-3 gap-2  py-2 "
        onClick={() => makeActions(selectedRow, "copy")}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M5 9.16663C5 6.8096 5 5.63109 5.73223 4.89886C6.46447 4.16663 7.64297 4.16663 10 4.16663H12.5C14.857 4.16663 16.0355 4.16663 16.7677 4.89886C17.5 5.63109 17.5 6.8096 17.5 9.16663V13.3333C17.5 15.6903 17.5 16.8688 16.7677 17.601C16.0355 18.3333 14.857 18.3333 12.5 18.3333H10C7.64297 18.3333 6.46447 18.3333 5.73223 17.601C5 16.8688 5 15.6903 5 13.3333V9.16663Z"
            stroke="#667085"
            stroke-width="2"
          />
          <path
            d="M5 15.8333C3.61929 15.8333 2.5 14.714 2.5 13.3333V8.33329C2.5 5.19059 2.5 3.61925 3.47631 2.64293C4.45262 1.66663 6.02397 1.66663 9.16667 1.66663H12.5C13.8807 1.66663 15 2.78592 15 4.16663"
            stroke="#667085"
            stroke-width="2"
          />
        </svg>
        <div style={{ fontSize: "12px" }}>Copy</div>
      </div>
      <div className=" flex items-center hover:bg-gray-200 pl-2 rounded-md cursor-pointer  pr-3 gap-3  py-2 " onClick={() => { setConfirmationModal({ state: true, action: "accept" }); setOpenPopoverId(null) }}>
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 18 13" fill="none">
          <path d="M17 1L6 12L1 7" stroke="#00904D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        <div style={{ fontSize: "12px" }}>Accept</div>
      </div>

      <div className=" flex items-center hover:bg-gray-200 pl-2 rounded-md cursor-pointer  pr-3 gap-3  py-2 " onClick={() => { setConfirmationModal({ state: true, action: "reject" }); setOpenPopoverId(null) }}>
        <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 14 14" fill="#ff0000">
          <path d="M13 1L1 13M1 1L13 13" stroke="#ff0000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        <div style={{ fontSize: "12px" }}>Reject</div>
      </div>
      <div className=" flex items-center hover:bg-gray-200 pl-1 rounded-md cursor-pointer  pr-3 gap-2  py-2 " onClick={() => { setConfirmationModal({ state: true, action: "fake" }); setOpenPopoverId(null) }}>
        <svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 -960 960 960" width="18" fill="orange"><path d="m40-120 440-760 440 760H40Zm138-80h604L480-720 178-200Zm302-40q17 0 28.5-11.5T520-280q0-17-11.5-28.5T480-320q-17 0-28.5 11.5T440-280q0 17 11.5 28.5T480-240Zm-40-120h80v-200h-80v200Zm40-100Z" /></svg>
        <div style={{ fontSize: "12px" }}>Faked</div>
      </div>
      <div className=" flex items-center hover:bg-gray-200 pl-2 rounded-md cursor-pointer  pr-3 gap-3  py-2 " onClick={() => { makeCall(selectedRow.customerNumber); setOpenPopoverId(null) }}>
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 18 18" fill="none">
          <g clip-path="url(#clip0_174_3859)">
            <path d="M16.5 12.69V14.94C16.5008 15.1488 16.4581 15.3556 16.3744 15.547C16.2907 15.7383 16.168 15.9101 16.0141 16.0513C15.8601 16.1926 15.6784 16.3001 15.4806 16.367C15.2827 16.4339 15.073 16.4588 14.865 16.44C12.5571 16.1892 10.3402 15.4006 8.3925 14.1375C6.58037 12.9859 5.044 11.4496 3.8925 9.63745C2.62498 7.68085 1.83618 5.4532 1.59 3.13495C1.57125 2.92755 1.5959 2.71852 1.66237 2.52117C1.72884 2.32382 1.83567 2.14247 1.97607 1.98867C2.11647 1.83487 2.28735 1.71198 2.47784 1.62784C2.66833 1.5437 2.87425 1.50015 3.0825 1.49995H5.3325C5.69648 1.49637 6.04934 1.62526 6.32532 1.8626C6.60129 2.09994 6.78155 2.42954 6.8325 2.78995C6.92746 3.51 7.10358 4.217 7.3575 4.89745C7.4584 5.16589 7.48024 5.45764 7.42043 5.73811C7.36061 6.01858 7.22164 6.27603 7.02 6.47995L6.0675 7.43245C7.13516 9.31011 8.68984 10.8648 10.5675 11.9325L11.52 10.98C11.7239 10.7783 11.9814 10.6393 12.2618 10.5795C12.5423 10.5197 12.8341 10.5415 13.1025 10.6425C13.783 10.8964 14.4899 11.0725 15.21 11.1675C15.5743 11.2188 15.907 11.4024 16.1449 11.6831C16.3827 11.9638 16.5091 12.3221 16.5 12.69Z" stroke="#0365CF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </g>
          <defs>
            <clipPath id="clip0_174_3859">
              <rect width="18" height="18" fill="none" />
            </clipPath>
          </defs>
        </svg>
        <div style={{ fontSize: "12px" }}>Make Call</div>
      </div>
      <div
        className=" flex items-center hover:bg-gray-200 pl-2 rounded-md cursor-pointer  pr-3 gap-2  py-2 "
        onClick={() => { makeActions(selectedRow, "manualAllot"); setOpenPopoverId(null) }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="#00904D"
          height="18"
          viewBox="0 -960 960 960"
          width="18"
        >
          <path d="m424-296 282-282-56-56-226 226-114-114-56 56 170 170Zm56 216q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" />
        </svg>
        <div style={{ fontSize: "12px" }}>Manual Allot</div>
      </div>
    </div>
  )

  const menuIcon = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="4"
        height="16"
        viewBox="0 0 4 16"
        fill="none"
        className="bg-[#EAECF0] w-7 h-7 p-2 flex items-center justify-center rounded-full"
      >
        <path
          d="M1.83333 8.5C2.29357 8.5 2.66667 8.1269 2.66667 7.66667C2.66667 7.20643 2.29357 6.83333 1.83333 6.83333C1.3731 6.83333 1 7.20643 1 7.66667C1 8.1269 1.3731 8.5 1.83333 8.5Z"
          stroke="#667085"
          stroke-width="1.66667"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M1.83333 2.66667C2.29357 2.66667 2.66667 2.29357 2.66667 1.83333C2.66667 1.3731 2.29357 1 1.83333 1C1.3731 1 1 1.3731 1 1.83333C1 2.29357 1.3731 2.66667 1.83333 2.66667Z"
          stroke="#667085"
          stroke-width="1.66667"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M1.83333 14.3333C2.29357 14.3333 2.66667 13.9602 2.66667 13.5C2.66667 13.0398 2.29357 12.6667 1.83333 12.6667C1.3731 12.6667 1 13.0398 1 13.5C1 13.9602 1.3731 14.3333 1.83333 14.3333Z"
          stroke="#667085"
          stroke-width="1.66667"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    );
  };

  const columns = [
    {
      id: "rideId",
      name: <TableHead>Trip ID</TableHead>,
      cell: (row) => (
        <TableCell>
          <Link
            className=" text-primary-blue"
            to={{ pathname: `/trip-management/${row.rideId}/${row.tripMode.tripName}` }}
            state={row}
          >
            {row.rideId}
          </Link>
        </TableCell>
      ),
    },
    {
      id: "tripDate",
      name: <TableHead>Trip Date</TableHead>,
      cell: (row) => <TableCell>{DateUtils.convertDate(row.dateOfRide)}</TableCell>,
    },
    {
      id: "pickupTime",
      name: <TableHead>Pickup Time</TableHead>,
      cell: (row) => <TableCell>{DateUtils.convertTime(row.pickUpTime)}</TableCell>,
    },
    {
      id: "tripType",
      name: <TableHead>Trip Type</TableHead>,
      cell: (row) => <TableCell>{row.tripMode.tripName}</TableCell>,
    },
    {
      id: "vehicleType",
      name: <TableHead>Vehical Type</TableHead>,
      cell: (row) => <TableCell>{row.carMode.carType}</TableCell>,
    },

    {
      id: "fromLocation",
      name: <TableHead>From Location</TableHead>,
      cell: (row) => <TableCell>{row.pickUpLocation}</TableCell>,
    },
    {
      id: "toLocation",
      name: <TableHead>To Location</TableHead>,
      cell: (row) => <TableCell>{ row.tripMode.id == 3 ? "-" : row.dropLocation}</TableCell>
      ,
    },
    {
      id: "mode",
      name: <TableHead>Booking Mode</TableHead>,
      cell: (row) => (
        <TableCell>
          <StatusCard status={row.bookingMode.bookingMode} />
        </TableCell>
      ),
    },
    {
      id: "actions",
      name: <TableHead>Actions</TableHead>,
      cell: (row) => (
        <TableCell>

          <Popover
            content={actionsContent}
            placement="leftTop"
            trigger="click"
            className=" cursor-pointer"
            onOpenChange={() => handlePopoverClick(row)}
            style={{ zIndex: 500, fontFamily: "Poppins" }}
            open={openPopoverId === row.rideId}
          >
            {menuIcon()}
          </Popover>
        </TableCell>
      ),
    },
  ];


  return (
    <div className="w-full removeScrollBar pb-5">
      <div className="flex  flex-wrap  lg:gap-8 gap-2 w-full justify-center lg:flex-nowrap  lg:pl-2  lg:pr-10  lg:justify-between items-center mb-5  lg:mb-10  mt-2 lg:mt-6">
        <Filters
          rideId={rideId}
          handleRideIdSearch={handleRideIdSearch}
          tripTypeData={tripTypeData}
          tripTypeFilter={tripTypeFilter}
          handleTripTypeFilter={handleTripTypeFilter}
          vehicles={vehicles}
          cabType={cabType}
          handleCabTypeFilter={handleCabTypeFilter}
          fromDate={fromDate}
          handleFromDateFilter={handleFromDateFilter}
          toDate={toDate}
          handleToDateFilter={handleToDateFilter}
        />
      </div>
      <Table columns={columns} rows={tripData.newBookingTable.newBookingTableData} rowCount={rowCount} rowsPerPage={rowsPerPage} page={page} handleChangePage={handleChangePage} handleChangeRowsPerPage={handleChangeRowsPerPage} />

      <Modal
        title={
          <>
            <div className="flex gap-2">
              <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" fill="orange"><path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" /></svg>
              <h1 className="text-lg" style={{ fontSize: '16px', fontWeight: 'normal' }}>Confirmation</h1>
            </div>

          </>
        }
        visible={confirmationModal.state}
        width={"500px"}
        footer={() => (
          <>
            <div className="flex gap-3 justify-end">
              <Button
                text={"Cancel"}
                textColor={"#1849A9"}
                width={"80px"}
                height={"30px"}
                bgColor={"#fff"}
                borderColor={"#1849A9"}
                fontSize={"12px"}
                onClick={() => setConfirmationModal({ state: false, action: "" })}
              />
              <Button
                text={"Ok"}
                textColor={"#fff"}
                width={"80px"}
                height={"30px"}
                fontSize={"12px"}
                onClick={() => {
                  makeActions(selectedRow, confirmationModal.action)
                  setConfirmationModal({ state: false, action: "" })
                }}
              />
            </div>
          </>
        )}
      >
        <div className="pr-5 pt-5 pb-5">
          <h1 className="text-base" style={{ fontSize: '13px', fontWeight: 'normal' }}>Are you sure want to <em><b>{confirmationModal.action}</b></em> the ride ?</h1>
        </div>
      </Modal>
      <AssignDriverModal
        isManualAllotedModal={isManualAllotedModal}
        setIsManualAllotedModal={setIsManualAllotedModal}
        selectedRow={selectedRow}
        tripStatus={tripStatus}
        getRides={getRides}
        rowsPerPage={rowsPerPage}
        page={page}
      />
    </div>
  );
}
