import { Modal } from "antd";
import success from "../../../assets/success_animated.gif";
import deleted from "../../../assets/delete_animated.gif";

export default function SuccessModal({ isOpen, animation = "success", undo, setIsOpen, title, description, onClose }) {

    function animationForModal() {
        if (animation === "delete") {
            return deleted;
        }
        return success;
    }
    return (
        <Modal
            open={isOpen}
            width={"350px"}
            onCancel={onClose ? () => { onClose(); setIsOpen(!isOpen) } : () => setIsOpen(!isOpen)}
            footer={null}
            centered
        >
            <div className="flex flex-col items-center justify-center gap-1 -mt-1 pb-4">
                <img
                    src={animationForModal()}
                    alt={animation}
                    className={` ${animation === "delete" ? "h-20 mt-6" : "h-32"}`}
                />
                <div className={`flex flex-col items-center justify-center gap-1 ${undo ? "mt-6" : "mt-4"}`}>
                    <div className="text-base font-semibold">{title}</div>
                    <p className="text-base font-semibold">{description}</p>
                </div>
                {undo && (
                    <div className="flex justify-end w-full mt-1 pb-0">
                        <button className="px-4 py-1 border-[1px] border-primary-blue text-primary-blue bg-blue-100 font-semibold rounded-lg">
                            UNDO
                        </button>
                    </div>
                )}
            </div>
        </Modal>
    )
};