import axios from 'axios';

export const getTripSummary = async (data) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/home/getSummary`, data, {
            headers: {
                "x-access-token": localStorage.getItem("CC_ADMIN_TOKEN"),
                "email": localStorage.getItem("CC_ADMIN_EMAIL"),
                "usertype": localStorage.getItem("CC_ADMIN_USERTYPE")
            }
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response?.status === 401 || error.response?.status === 403) {
            localStorage.clear();
            window.location.href = "/login";
        } else {
            console.error("Error:", error);
        }
        throw error;
    }
};

export const getTripSummaryTotal = async (data) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/home/getSummaryTotal`, data, {
            headers: {
                "x-access-token": localStorage.getItem("CC_ADMIN_TOKEN"),
                "email": localStorage.getItem("CC_ADMIN_EMAIL"),
                "usertype": localStorage.getItem("CC_ADMIN_USERTYPE")
            }
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response?.status === 401 || error.response?.status === 403) {
            localStorage.clear();
            window.location.href = "/login";
        } else {
            console.error("Error:", error);
        }
        throw error;
    }
};


export const getVehicleTypeSummary = async (data) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/home/getVehicleTypeSummary`, data, {
            headers: {
                "x-access-token": localStorage.getItem("CC_ADMIN_TOKEN"),
                "email": localStorage.getItem("CC_ADMIN_EMAIL"),
                "usertype": localStorage.getItem("CC_ADMIN_USERTYPE")
            }
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response?.status === 401 || error.response?.status === 403) {
            localStorage.clear();
            window.location.href = "/login";
        } else {
            console.error("Error:", error);
        }
        throw error;
    }
}

export const getDriverSummary = async (data) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/home/getDriverSummary`, data, {
            headers: {
                "x-access-token": localStorage.getItem("CC_ADMIN_TOKEN"),
                "email": localStorage.getItem("CC_ADMIN_EMAIL"),
                "usertype": localStorage.getItem("CC_ADMIN_USERTYPE")
            }
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response?.status === 401 || error.response?.status === 403) {
            localStorage.clear();
            window.location.href = "/login";
        } else {
            console.error("Error:", error);
        }
        throw error;
    }
}

export const getTripTypeSummary = async (data) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/home/getTripTypeSummary`, data, {
            headers: {
                "x-access-token": localStorage.getItem("CC_ADMIN_TOKEN"),
                "email": localStorage.getItem("CC_ADMIN_EMAIL"),
                "usertype": localStorage.getItem("CC_ADMIN_USERTYPE")
            }
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response?.status === 401 || error.response?.status === 403) {
            localStorage.clear();
            window.location.href = "/login";
        } else {
            console.error("Error:", error);
        }
        throw error;
    }
}