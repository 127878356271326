import { useState } from "react";
import { Link } from "react-router-dom";
import Table from "../../reusable/Table/Table";
import TableHead from "../../reusable/Table/TableHead";
import TableCell from "../../reusable/Table/TableCell";
import StatusCard from "../../reusable/Table/TripStatus";
import DropDown from "../../reusable/DropDown";
import DateInput from "../../reusable/DateInput";
import Input from "../../reusable/Input";
import Filters from "./components/Filters";
import DateUtils from "../../../pages/utils/DateUtils";

export default function CompletedTable({
  tripData,
  tripStatus,
  rowsPerPage,
  page,
  handleChangePage,
  handleChangeRowsPerPage,
  handleRideIdSearch,
  handleTripTypeFilter,
  handleCabTypeFilter,
  handleFromDateFilter,
  handleToDateFilter,
  rideId,
  vehicles,
  tripTypeData,
  tripTypeFilter,
  cabType,
  fromDate,
  toDate,
  rowCount,
}) {

  const columns = [
    {
      id: "rideId",
      name: <TableHead>Trip ID</TableHead>,
      cell: (row) => (
        <TableCell>
          <Link
            className=" text-primary-blue"
            to={{ pathname: `/trip-management/${row.rideId}/${row.tripMode.tripName}` }}
            state={row}
          >
            {row.rideId}
          </Link>
        </TableCell>
      ),
    },
    {
      id: "tripDate",
      name: <TableHead>Trip Date</TableHead>,
      cell: (row) => <TableCell>{DateUtils.convertDate(row.dateOfRide)}</TableCell>,
    },
    {
      id: "pickupTime",
      name: <TableHead>Pickup Time</TableHead>,
      cell: (row) => <TableCell>{DateUtils.convertTime(row.pickUpTime)}</TableCell>,
    },
    {
      id: "tripType",
      name: <TableHead>Trip Type</TableHead>,
      cell: (row) => <TableCell>{row.tripMode.tripName}</TableCell>,
    },
    {
      id: "vehicleType",
      name: <TableHead>Vehical Type</TableHead>,
      cell: (row) => <TableCell>{row.carMode.carType}</TableCell>,
    },
    {
      id: "completionMode",
      name: <TableHead>Completion Mode</TableHead>,
      cell: (row) => <TableCell>
        {
          row.endFrom !== null ? (
            <StatusCard
              status={
                row?.endFrom === 1
                  ? 'manualCompleted'
                  : row?.endFrom === 2
                    ? 'appCompleted'
                    : '-'
              }
            />
          ) : (
            '-'
          )
        }
      </TableCell>,
    },

    {
      id: "estTotalFare",
      name: <TableHead>Final Fare</TableHead>,
      cell: (row) => <TableCell>₹ {row.totalFare}</TableCell>,
    },
    {
      id: "status",
      name: <TableHead>Status</TableHead>,
      cell: (row) => (
        <TableCell>
          <StatusCard status={"Completed"} isDot />
        </TableCell>
      ),
    },
    {
      id: "mode",
      name: <TableHead>Booking Mode</TableHead>,
      cell: (row) => (
        <TableCell>
          <StatusCard status={row.bookingMode.bookingMode} />
        </TableCell>
      ),
    }
  ];

  return (
    <div className="w-full removeScrollBar pb-5">
      <div className="flex  flex-wrap  lg:gap-8 gap-2 w-full justify-center lg:flex-nowrap  lg:pl-2  lg:pr-10  lg:justify-between items-center mb-5  lg:mb-10  mt-2 lg:mt-6">
        <Filters
          rideId={rideId}
          handleRideIdSearch={handleRideIdSearch}
          tripTypeData={tripTypeData}
          tripTypeFilter={tripTypeFilter}
          handleTripTypeFilter={handleTripTypeFilter}
          vehicles={vehicles}
          cabType={cabType}
          handleCabTypeFilter={handleCabTypeFilter}
          fromDate={fromDate}
          handleFromDateFilter={handleFromDateFilter}
          toDate={toDate}
          handleToDateFilter={handleToDateFilter}
        />
      </div>
      <Table columns={columns} rows={tripData.completedTable.completedTableData} rowCount={rowCount} tripStatus={tripStatus} rowsPerPage={rowsPerPage} page={page} handleChangePage={handleChangePage} handleChangeRowsPerPage={handleChangeRowsPerPage} />
    </div>
  );
}
