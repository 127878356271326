import React, { useEffect, useState } from 'react'
import PageLayout from '../../components/layouts/PageLayout'
import { LabelForTabs } from '../../components/reusable/TabNavigation'
import CustomerReferralTable from '../../components/tables/redemption-management/CustomerReferralTable';
import { useLocation, useNavigate } from 'react-router-dom';
import shareIcon from '../../assets/share.svg';

import { Tabs, message } from "antd";
import { getRedeemCount, getRedeemManagementList } from '../../services/RedeemManagementServices';
import InitiateRedemptionModal from './components/InitiateRedemptionModal';
const { TabPane } = Tabs;

export default function RedemptionManagement() {
    const location = useLocation().pathname;
    const activeTab = location.split("/")[2];
    const navigate = useNavigate();

    const [activeKey, setActiveKey] = useState(activeTab);
    const [allRedeemData, setAllRedeemData] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [count, setCount] = useState({
        getApprovedRedeemCount: 0,
        getPendingRedeemCount: 0,
        getRejectedRedeemCount: 0
    })
    const [initiateRedemptionModal, setInitiateRedemptionModal] = useState(false)

    //Pagination details
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);


    const getAllRedeemData = async (redeemStatus, page, rowsPerPage, searchQuery) => {
        try {
            const response = await getRedeemManagementList(redeemStatus, page + 1, rowsPerPage, searchQuery);
            if (response) {
                setAllRedeemData(response.data.referalData);
                setTotalCount(response.data.referalCount);
            }
        }
        catch (err) {
            message.error(err.response.data.message || err.response.data.error || err.message);
        }

    }

    useEffect(() => {
        let redeemStatus;
        if (activeKey === 'pending-redemption') {
            redeemStatus = 0;
        }
        else if (activeKey === 'completed-redemption') {
            redeemStatus = 1;
        }
        else if (activeKey === 'rejected-redemption') {
            redeemStatus = 2;
        }

        getAllRedeemData(redeemStatus, page, rowsPerPage);
    }, [activeKey, page, rowsPerPage])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleCount = async () => {
        try {
            const response = await getRedeemCount();
            if (response) {
                setCount(response.data);
            }
        }
        catch (err) {
            message.error(err.response.data.message || err.response.data.error || err.message);
        }
    }

    useEffect(() => {
        handleCount()
    }, [allRedeemData])


    const tabContents = [
        {
            key: "pending-redemption",
            label: (
                <LabelForTabs isActive={activeKey === "pending-redemption"}>Pending Redemption Request ({count?.getPendingRedeemCount})</LabelForTabs>
            ),
            children: (
                <CustomerReferralTable
                    allRedeemData={allRedeemData}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    activeKey={activeKey}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    getAllRedeemData={getAllRedeemData}
                    totalCount={totalCount}
                    rowCount={count?.getPendingRedeemCount}
                />
            ),
        },
        {
            key: "completed-redemption",
            label: (
                <LabelForTabs isActive={activeKey === "completed-redemption"}>Completed Redemption Request ({count?.getApprovedRedeemCount})</LabelForTabs>
            ),
            children: (
                <CustomerReferralTable
                    allRedeemData={allRedeemData}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    activeKey={activeKey}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    getAllRedeemData={getAllRedeemData}
                    totalCount={totalCount}
                    rowCount={count?.getApprovedRedeemCount}
                />
            ),
        },
        {
            key: "rejected-redemption",
            label: (
                <LabelForTabs isActive={activeKey === "rejected-redemption"}>Rejected Redemption Request ({count?.getRejectedRedeemCount})</LabelForTabs>
            ),
            children: (
                <CustomerReferralTable
                    allRedeemData={allRedeemData}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    activeKey={activeKey}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    getAllRedeemData={getAllRedeemData}
                    totalCount={totalCount}
                    rowCount={count?.getRejectedRedeemCount}
                />
            ),
        },
    ];

    const onChange = (key) => {
        setActiveKey(key)
        navigate(`/redemption-management/${key}`);
        setPage(0);
        setRowsPerPage(25);
    }

    return (
        <>
            <PageLayout
                title={"Redemption Management"}
                description={"Manage the customer redemption here"}
                buttonTitle={"Initiate Redemption"}
                buttonWidth={"160px"}
                // buttonIcon={shareIcon}
                // BreadcrumbsUrl={BreadcrumbsUrl}
                setAddTripModal={setInitiateRedemptionModal}
            >
                <div className=" w-full flex md:mt-7">
                    {/* <TabNavigation
                        tab={"referralManagement"}
                        tabContents={tabContents}
                        activeKey={activeKey}
                        setActiveKey={setActiveKey}
                    /> */}
                    <Tabs
                        style={{
                            width: "100%",
                            height: "100%",
                            overflowY: "scroll",
                        }}
                        className=""
                        activeKey={activeKey}
                        defaultActiveKey="customer-referral"
                        onChange={onChange}
                    >
                        {tabContents.map((tab) => (
                            <TabPane
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    overflowY: "scroll",
                                }}
                                tab={tab.label}
                                key={tab.key}
                            >
                                {tab.children}
                            </TabPane>
                        ))}
                    </Tabs>
                </div>
            </PageLayout>
            <InitiateRedemptionModal
                title={'Initiate Redemption'}
                showModal={initiateRedemptionModal}
                setShowModal={setInitiateRedemptionModal}
                getAllRedeemData={getAllRedeemData}
            />
        </>
    )
}
