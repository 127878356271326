import DateInput from "../../../reusable/DateInput";
import DropDown from "../../../reusable/DropDown";
import Input from "../../../reusable/Input";



export default function CustomerFilters({
    customerId,
    customerTypeData,
    customerType,
    fromDate,
    toDate,
    setFilters
}) {

    return (
        <>
            <Input
                label={"Search"}
                placeholder={"id/name/mobile number/email"}
                value={customerId}
                onChange={(e) => setFilters(prev => ({ ...prev, customerId: e.target.value }))}
            />
            <DropDown
                label={"Customer Type"}
                placeholder={"Select Customer type"}
                displayValue={"customerType"}
                data={customerTypeData}
                value={customerType}
                setValue={(item) => setFilters(prev => ({ ...prev, customerType: item }))}
                selectAll
            />
            <DateInput
                label={"From date"}
                placeholder={"Select from date"}
                value={fromDate}
                setValue={item => setFilters(prev => ({ ...prev, fromDate: item }))}
                isFilter
            />
            <DateInput
                label={"To date"}
                placeholder={"Select to date"}
                value={toDate}
                setValue={item => setFilters(prev => ({ ...prev, toDate: item }))}
                isFilter

            />
        </>
    )
}