import { isEmpty, isValidEmail } from "./validations";


export const validateFields = (data) => {
    const errors = {};

    if (isEmpty(data.email)) {
        errors.email = 'Please enter the email';
    } else if (!isValidEmail(data.email)) { 
        errors.email = 'Please enter a valid email';
    }

    if (isEmpty(data.password)) {
        errors.password = 'Please enter the password';
    }

    return errors;

}
