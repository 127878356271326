import LeftSide from "./LeftSide";
import RightSide from "./RightSide";

export default function LoginLayout({ title, text, description, children }) {
    return (
        <div className="flex h-screen ">
            {/* left-side layout */}
            <div className="w-1/2 lg:block hidden h-full bg-primary-blue">
                <LeftSide />
            </div>
            {/* right-side layout */}
            <div className=" w-full   lg:w-1/2 h-full  p-3  bg-gray-100/60  lg:bg-white">
                <RightSide
                    title={title}
                    text={text}
                    description={description}
                >
                    {children}
                </RightSide>
            </div>
        </div>
    )
};
