import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/reusable/Button";
import Input from "../../components/reusable/Input";
import LoginLayout from "../../components/layouts/login/LoginLayout";
import MobileNumberInput from "../../components/reusable/MobileNumberInput";

export default function ForgotPassword() {
    const navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [emailError, setEmailError] = useState("");
    const [mobileNumberError, setMobileNumberError] = useState("");

    const Valid = () => {
        let isValid = true;

        if (!email) {
            setEmailError("Please enter email");
            isValid = false;
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            setEmailError("Please enter a valid email");
            isValid = false;
        } else {
            setEmailError("");
        }

        if (!mobileNumber) {
            setMobileNumberError("Please enter mobile number");
            isValid = false;
        } else if (mobileNumber.length < 10) {
            setMobileNumberError("Please enter a valid mobile number");
            isValid = false;
        } else {
            setMobileNumberError("");
        }

        return isValid;
    };

    const handleSendOTP = () => {
        if (Valid()) {
            navigate("/verify-otp");
        }
    };

    return (
        <LoginLayout
            title={"Hello Again !"}
            text={"Forgot your password?"}
            description={"Upon your request, a verification code will be sent to the provided address to reset the password."}
        >
            <form className="flex flex-col px-6  lg:px-0  gap-6 justify-center items-center     w-full lg:w-[400px] ">
                {/* email input */}
                <Input
                    label={"Email"}
                    placeholder={"Enter your registered email"}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    error={emailError}
                />
                {/* password input */}
                <MobileNumberInput
                    label={"Mobile number"}
                    placeholder={"00000 00000"}
                    value={mobileNumber}
                    setValue={setMobileNumber}
                    error={mobileNumberError}
                />
                {/* sign-in button */}
                <div />
                <Button
                    text="Send OTP"
                    onClick={handleSendOTP}
                />
            </form>
        </LoginLayout>
    )
}
