import { Modal } from "antd";
import warning from "../../../assets/warning.svg";

export default function ConfirmModal({ isOpen, setIsOpen, title, description, button1, onClickButton1, button2, onClickButton2 }) {

    return (
        <Modal
            title={<img src={warning} alt="warning" />}
            open={isOpen}
            width={"400px"}
            onCancel={() => setIsOpen(!isOpen)}
            footer={null}
            centered
        >
            <div className={`flex flex-col justify-between`}>
                <div className="flex flex-col gap-3 mt-3 pl-3">
                    <div className="font-semibold text-base">{title}</div>
                    <p className="text-placeholder-gray font-medium text-sm">{description}</p>
                </div>
                <div className="flex gap-5 justify-center items-center mt-10 font-medium pb-3">
                    {button1 && (
                        <button
                            className="px-10 py-1.5 border border-border-gray rounded-lg"
                            onClick={onClickButton1}
                        >
                            {button1}
                        </button>
                    )}
                    {button2 && (
                        <button
                            className="px-10 py-1.5 border border-primary-blue rounded-lg bg-primary-blue text-white"
                            onClick={onClickButton2}
                        >
                            {button2}
                        </button>
                    )}
                </div>
            </div>
        </Modal>
    )
};